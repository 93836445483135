.header-wrapper {
	padding: 20px 20px;

	.header-container {
		display: grid;
		grid-template-columns: 1fr 2fr 1fr;
		align-items: center;

		.login-btn {
			text-align: right;

			a {
				background: #2d2d2d;
				color: #ffffff;
				font-weight: 600;
				border: none;
				border-radius: 10px;
				padding: 10px 20px;
				text-decoration: none;
			}
		}
		.main-menu {
			justify-self: end;
			.nav-link {
				color: #2d2d2d;
				font-weight: 600;
				font-size: 17.5px;
				padding: 10px 10px;
				display: flex;
				justify-content: flex-end;
				width: 135px;
				text-align: center;
			}
			.nav-link:hover {
				@media (max-width: 767px) {
					background-color: lightgray;
				}
			}
		}
	}
}

.navbar-collapse {
	@media (max-width: 767px) {
		position: absolute;
		background-color: white;
		border-radius: 10px;
		right: 0px;
		z-index: 1000;
		top: 50px;
		overflow: hidden;
	}
}
