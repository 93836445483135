.img-fluid-icon {
	position: relative;
    top: 8px;
    width: 22px;
}

.login-select {
	width: 100%;
	height: 49px;
	background-color: transparent;
	border: none;
	font-size: 20px;
	color: white;
}

.login-option {
	color: black;
}

.login-main-wrapper {
	display: grid; 
	grid-template-columns: 0.5fr 1fr;
	// position: absolute;
	// left: 0;
	height: calc(100vh + 700px);
	width: 100vw;
	margin-top: -5px;
	@media (max-width: 767px) {
		grid-template-columns: 1fr;
	}

	&__left-content {
		background: url('../../assets/login-image-left.png');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		@media (max-width: 767px) {
			display: none;
		}
	}

	&__login-form {
		background: url('../../assets/login-image-right.png');
		background-size: cover;
		padding: 50px 100px;

		@media (max-width: 767px) {
			padding: 50px 20px !important;
		}
	}



	&__login-content {
		max-width: 1000px;
		h1 {
			color: #ffffff;
			font-size: 50px;
			font-weight: 600;
		}

		p {
			color: #ffffff;
			font-weight: 500;
			font-size: 20px;
		}
	}

	&__input-main-wrapper {
		margin-top: 100px;
		// margin-bottom: 100px;
	}

	&_input-wrapper {
		border-bottom: 1px solid #b5b5b5;
		padding-bottom: 20px;
		margin-bottom: 50px;
		display: flex;
		align-items: baseline;

		input {
			background: none;
			border: none;
			font-size: 20px;
			outline: none;
			color: #ffffff;
			width: 100%;

			&::placeholder {
				color: #b5b5b5;
				font-weight: 600;
			}
		}

		img {
			padding-right: 30px;
		}
	}

	&__eye-icon {
		text-align: right;
		display: block;
		// width: 100%;

		img {
			padding: 0;
		}
	}

	&__login-btn {
		text-align: center;

		span {
			text-decoration: none;
			color: #000000;
			// font-size: 18px;
			background: #ffffff;
			padding: 15px 25px;
			font-weight: 600;
			border-radius: 10px;
		}
	}

	&__cotnent-or {
		display: grid;
		grid-template-columns: 2fr 1fr 2fr;
		align-items: center;
		margin-top: 100px;
	}

	&_divider {
		background-color: #595959;
		height: 1px;
		display: flex;
		align-items: center;
	}

	&__or {
		color: #ffffff;
		font-size: 20px;
		font-weight: 600;
		text-align: center;
	}

	&__get-started {
		color: #ffffff;
		font-size: 20px;
		font-weight: 600;
		text-align: center;
		padding-top: 50px;
	}

	&__social-login {
		text-align: center;

		a {
			padding: 0px 20px;
		}
	}

	&__forgot-password {
		text-align: right;

		a {
			color: #ffffff;
			text-decoration: none;
			font-size: 20px;
			font-weight: 600;
		}
	}
}
