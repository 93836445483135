body {
	background-color: rgb(246, 246, 246);
}

/* main {
	margin-top: 95px;
} */

.header__background {
	background-image: url(https://images.unsplash.com/photo-1610294517329-d4aac71cd302?auto=format&fit=crop&ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&q=80&w=801);
	background-repeat: no-repeat;
	width: 100%;
	height: 100vh;
	background-position-y: center;
	background-size: cover;
	overflow: hidden;
	overflow-y: hidden;
}

.panel {
	background: #fff;
	border-radius: 4px;
	box-sizing: border-box;
	border: 1px solid #e2e2e2;
	border: none;
	color: #5b5b5b;
	color: #393939;
	width: 100%;
	min-height: 30px;
	position: relative;
	-webkit-box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	padding-bottom: 20px;
	padding: 20px;
}

.video-react {
	padding-top: 0px !important;
	/* width: 100%; */
	/* margin-left: -55%; */
}

.video-react-video {
	height: 100vh;
}

/* @media screen and (max-width: 900px) {
	.video-react-video {
		margin-left: -80%;
	}
}

@media screen and (max-width: 2000px) {
	.video-react-video {
		margin-left: -55%;
	}
} */

@media screen and (min-width: 1300px) {
	.video-react-video {
		width: 100%;
		height: auto;
	}
}

/* PANEL */

.panel {
	background: #fff;
	border-radius: 4px;
	border: 1px solid #e2e2e2;
	border: none;
	color: #5b5b5b;
	color: #393939;
	margin-bottom: 20px;
	min-height: 30px;
	position: relative;
	-webkit-box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	-moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.08);
	box-shadow: 0 0 10px rgb(0 0 0 / 8%);
	padding: 30px;
	margin-top: 20px;
	margin-bottom: 20px;
	padding-bottom: 20px;
}

.chrome-picker {
	width: 100% !important;
}

@media (prefers-reduced-motion: no-preference) {
	.App-logo {
		animation: App-logo-spin infinite 20s linear;
	}
}

.App-header {
	background-color: white;
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	font-size: calc(10px + 2vmin);
	color: white;
}

.App-link {
	color: #61dafb;
}

/* TABLE */

.table__link {
	cursor: pointer !important;
	flex: 1;
	width: 100%;
	height: 100%;
}

/* DROPZONE */

.dropzone {
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
	width: 100%;
}

.MuiGrid-container.MuiDropzonePreviewList-root {
	display: flex;
	justify-content: center;
}

.tap {
	overflow: hidden;
	/* background: linear-gradient(rgba(250, 0, 0, 1), orange); */
	/* background-color: orange; this your primary color */
}
