.flag-icon-background {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
}
.flag-icon {
  background-size: contain;
  background-position: 50%;
  background-repeat: no-repeat;
  position: relative;
  display: inline-block;
  width: 1.33333333em;
  line-height: 1em;
}
.flag-icon:before {
  content: "\00a0";
}
.flag-icon.flag-icon-squared {
  width: 1em;
}

.ln li a:hover {
  cursor: pointer;
}

.flag-icon-is {
  background-image: url(../images/flags/4x3/is.svg);
}
.flag-icon-is.flag-icon-squared {
  background-image: url(../images/flags/1x1/is.svg);
}
.flag-icon-us {
  background-image: url(../images/flags/4x3/us.svg);
}
.flag-icon-us.flag-icon-squared {
  background-image: url(../images/flags/1x1/us.svg);
}

.ln-list{
	padding: 0;
	list-style: none;
}

.ln-list a{
	font-size: 16px;
}

