.container {
	position: relative;
	height: 100%;
}
.container .qrReader {
	width: 100%;
	height: 100%;
}
.container .qrReader > section {
	height: 100%;
	padding-top: 0 !important;
}

#viewFinder {
	width: 100%;
	height: 100%;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	justify-content: center;
	align-items: center;
	display: flex;
}
#viewFinder div {
	height: 70vw;
	width: 70vw;
	max-width: 300px;
	max-height: 300px;
	box-shadow: 0 0 0 99999px rgba(0, 0, 0, 0.35);
	position: relative;
}
#viewFinder div #line {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 2px;
	background: red;
	animation: moveLine 3s infinite alternate;
}

@keyframes moveLine {
	0% {
		top: 20%;
	}
	100% {
		top: 80%;
	}
}

#viewFinder div #title {
	position: absolute;
	top: -54px;
	text-align: center;
	height: 30px;
	background-color: rgba(0, 0, 0, 0.35);
	color: white;
}