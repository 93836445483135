/*
  URL: https://www.fiverr.com/asmi2022/
  Description: Create responsive mobile app landing page website for IOS and Android
  Author: Asmi
  Author URL: https://www.fiverr.com/asmi2022/create-responsive-mobile-app-landing-page-website
  Website: https://www.fiverr.com/asmi2022/
  Tags: Responsive, website, HTML5 template, quickflipbook, Mobile, Application, One Page, Landing, Product, Mobile App, IOS, Android
*/

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/* 1 - global area - start
==================================================================================================== */
html {
	scroll-behavior: smooth;
}

.front-page-body {
	background-image: url(../images/bg.jpg);
	background-repeat: no-repeat;
	background-size: cover;
	margin: 0px;
	padding: 0px;
	font-size: 14px;
	line-height: 26px;
	font-style: normal;
	color: #535e72;
	font-family: 'Inter', sans-serif;
	font-weight: 400;
	background-color: #ffffff;
	text-rendering: optimizelegibility;
	-moz-osx-font-smoothing: grayscale;
	-webkit-font-smoothing: antialiased;
}

.container {
	max-width: 1200px;
}

.container-fluid {
	padding-left: 30px;
	padding-right: 30px;
}

::-moz-selection {
	color: #ffffff;
	background: #6b45f3;
}

::selection {
	color: #ffffff;
	background: #6b45f3;
}

a,
button {
	cursor: pointer;
	line-height: normal;
	display: inline-block;
	text-decoration: none;
	font-weight: 500;
	font-family: 'Inter', sans-serif;
}
a:hover,
button:hover {
	text-decoration: none;
}

button {
	margin: 0px;
	padding: 0px;
	border: none;
	background: none;
}
button:focus {
	outline: none;
}

img {
	height: auto;
	max-width: 100%;
}

.image-container {
	overflow: hidden;
	position: relative;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	line-height: normal;
	font-weight: 700;
	color: #272d3a;
	font-family: 'Inter', sans-serif;
}

strong,
span,
small,
label,
i {
	margin: 0px;
	padding: 0px;
	line-height: normal;
	display: inline-block;
	font-family: 'Inter', sans-serif;
}

strong {
	font-weight: 700;
}

.form-item {
	position: relative;
	margin-bottom: 30px;
}
.form-item input {
	width: 100%;
	height: 50px;
	padding: 0px 20px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #f7f9fe;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.form-item input:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #ffffff;
}

.form-textarea {
	position: relative;
	margin-bottom: 30px;
}
.form-textarea textarea {
	width: 100%;
	padding: 20px;
	min-height: 120px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #f7f9fe;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.form-textarea textarea:focus {
	outline: none;
	-webkit-box-shadow: none;
	box-shadow: none;
	background-color: #ffffff;
}

:-moz-placeholder {
	text-transform: capitalize;
}

::-moz-placeholder {
	text-transform: capitalize;
}

:-ms-input-placeholder {
	text-transform: capitalize;
}

::-webkit-input-placeholder {
	text-transform: capitalize;
}

/* owl settings - start
================================================== */
.owl-theme .owl-dots {
	margin-top: 60px !important;
}
.owl-theme .owl-dots .owl-dot {
	width: 12px;
	height: 12px;
	padding: 0px;
	margin: 0px 5px;
	position: relative;
	border-radius: 100%;
	background: #d8e7fc;
}
.owl-theme .owl-dots .owl-dot span {
	top: 50%;
	left: 50%;
	opacity: 0;
	width: 4px;
	height: 4px;
	margin: 0px;
	padding: 0px;
	position: absolute;
	border-radius: 100%;
	background: #138afd;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.owl-theme .owl-dots .owl-dot:hover span {
	opacity: 1;
	background: #138afd;
}
.owl-theme .owl-dots .owl-dot.active span {
	opacity: 1;
	background: #138afd;
}

.owl-theme .owl-nav .owl-next,
.owl-theme .owl-nav .owl-prev {
	margin: 0px;
	width: 45px;
	height: 45px;
	background: none;
	position: relative;
}
.owl-theme .owl-nav .owl-next > span,
.owl-theme .owl-nav .owl-prev > span {
	color: transparent;
	background: transparent;
}
.owl-theme .owl-nav .owl-next:before,
.owl-theme .owl-nav .owl-prev:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 1;
	font-size: 16px;
	font-weight: 900;
	line-height: 45px;
	text-align: center;
	position: absolute;
	border-radius: 100%;
	color: #272d3a;
	font-family: 'unicons';
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	border: 1px solid #272d3a;
}
.owl-theme .owl-nav .owl-next:hover,
.owl-theme .owl-nav .owl-prev:hover {
	background: none;
}
.owl-theme .owl-nav .owl-next:hover:before,
.owl-theme .owl-nav .owl-prev:hover:before {
	color: #ffffff;
	background: #138afd;
	border-color: #138afd;
}

.owl-theme .owl-nav .owl-prev {
	margin-right: 5px;
}
.owl-theme .owl-nav .owl-prev:before {
	content: '\e82a';
}

.owl-theme .owl-nav .owl-next:before {
	content: '\e82c';
}

/* owl settings - end
================================================== */
/* list style settings - start 
================================================== */
.ul-li > ul {
	margin: 0px;
	padding: 0px;
	display: table;
}
.ul-li > ul > li {
	float: left;
	list-style: none;
	display: inline-block;
}

.ul-li-block {
	width: 100%;
	display: table;
}
.ul-li-block > ul {
	width: 100%;
	margin: 0px;
	padding: 0px;
	display: table;
}
.ul-li-block > ul > li {
	width: 100%;
	display: table;
	list-style: none;
}

.ul-li-right > ul {
	margin: 0px;
	padding: 0px;
	float: right;
	display: table;
}
.ul-li-right > ul > li {
	float: left;
	list-style: none;
	display: inline-block;
}

.ul-li-center > ul {
	padding: 0px;
	margin: 0 auto;
	display: table;
}
.ul-li-center > ul > li {
	float: left;
	list-style: none;
	display: inline-block;
}

/* list style settings - end
================================================== */
/* social links - start
================================================== */
.social-links > ul > li {
	margin-right: 9px;
}
.social-links > ul > li:last-child {
	margin-right: 0px;
}
.social-links > ul > li > a {
	z-index: 1;
	width: 40px;
	height: 40px;
	color: #8d96a6;
	font-size: 14px;
	overflow: hidden;
	line-height: 42px;
	text-align: center;
	position: relative;
	border-radius: 100%;
	background-color: #e3e8fe;
}
.social-links > ul > li > a:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	opacity: 0;
	position: absolute;
	border-radius: 100%;
	-webkit-transform: scale(0.5);
	-ms-transform: scale(0.5);
	transform: scale(0.5);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: #6b45f3;
}
.social-links > ul > li > a:hover {
	color: #ffffff;
}
.social-links > ul > li > a:hover:before {
	opacity: 1;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
}

/* social links - end
================================================== */
/* post meta - start
================================================== */
.post-meta > ul > li {
	margin-right: 30px;
}
.post-meta > ul > li:last-child {
	margin-right: 0px;
}
.post-meta > ul > li i {
	font-size: 16px;
	color: #138afd;
}
.post-meta > ul > li > a {
	color: #535e72;
}
.post-meta > ul > li > .post-admin {
	font-weight: 700;
	color: #272d3a;
}
.post-meta > ul > li > .post-admin .admin-image {
	float: left;
	width: 40px;
	height: 40px;
	overflow: hidden;
	margin: -12px 0px;
	margin-right: 10px;
	border: 1px solid #e3e8fe;
}

/* post meta - end
================================================== */
/* rating star - start
================================================== */
.rating-star .review-text {
	font-size: 14px;
	margin-left: 10px;
	color: #272d3a;
}

.rating-star > ul {
	float: left;
}
.rating-star > ul > li {
	font-size: 14px;
	color: #e3e8fe;
	margin-right: 3px;
}
.rating-star > ul > li:last-child {
	margin-right: 0px;
}
.rating-star > ul .rated {
	color: #f2b01e;
}

/* rating star - end
================================================== */
/* pagination - start
================================================== */
.pagination-nav > .pagination {
	border-radius: 0px;
}
.pagination-nav > .pagination > .page-item {
	width: 40px;
	height: 40px;
	overflow: hidden;
	margin-right: 5px;
	border-radius: 100%;
}
.pagination-nav > .pagination > .page-item > .page-link {
	width: 100%;
	height: 100%;
	margin: 0px;
	padding: 0px;
	border: none;
	display: block;
	font-size: 14px;
	text-align: center;
	line-height: 40px;
	border-radius: 0px;
	color: #535e72;
}
.pagination-nav > .pagination > .page-item > .page-link:hover {
	color: #138afd;
	background-color: #ffffff;
}
.pagination-nav > .pagination .prev-item .page-link {
	font-size: 24px;
}
.pagination-nav > .pagination .next-item .page-link {
	font-size: 24px;
}

/* pagination - end
================================================== */
/* padding - margin setting - start 
================================================== */
.sec-ptb-160 {
	padding: 40px 0px;
}

.pb-50 {
	padding-bottom: 50px;
}
.pt-100 {
	padding-top: 100px;
}

.mb-6 {
	margin-bottom: 6px;
}

.mb-10 {
	margin-bottom: 10px;
}

.mb-15 {
	margin-bottom: 15px;
}

.mb-20 {
	margin-bottom: 20px;
}

.mb-45 {
	margin-bottom: 45px;
}

.mb-30 {
	margin-bottom: 30px;
}

.mb-60 {
	margin-bottom: 60px;
}

.mb-100 {
	margin-bottom: 100px;
}

.ml-40 {
	margin-left: 40px;
}

.ml-60 {
	margin-left: 60px;
}

.mt-30 {
	margin-top: 30px;
}

/* padding - margin setting - end
================================================== */
/* button style - start
================================================== */
.custom-btn {
	z-index: 1;
	height: 55px;
	font-size: 16px;
	overflow: hidden;
	line-height: 54px;
	padding: 0px 45px;
	text-align: center;
	color: #ffffff;
	border-radius: 3px;
	position: relative;
	font-weight: 500;
	background-color: #e255c8;
	-webkit-transition: all 0.4s ease-in-out;
	-o-transition: all 0.4s ease-in-out;
	transition: all 0.4s ease-in-out;
	-webkit-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
	-o-transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
	transition-timing-function: cubic-bezier(0.77, 0, 0.175, 1);
}
.custom-btn:after,
.custom-btn:before {
	top: 0px;
	width: 0px;
	left: 100%;
	content: '';
	z-index: -1;
	height: 100%;
	position: absolute;
	-webkit-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	-webkit-transition-timing-function: inherit;
	-o-transition-timing-function: inherit;
	transition-timing-function: inherit;
	background-color: rgba(255, 255, 255, 0.2);
}
.custom-btn:before {
	z-index: -1;
	background-color: #6b45f3;
}
.custom-btn:after {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.custom-btn:hover {
	color: #ffffff;
}
.custom-btn:hover:after,
.custom-btn:hover:before {
	left: 0;
	width: 100%;
	-webkit-transition-property: width;
	-o-transition-property: width;
	transition-property: width;
}
.custom-btn:hover:before {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.custom-btn:hover:after {
	-webkit-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;
	-webkit-transition-property: width;
	-o-transition-property: width;
	transition-property: width;
}

.custom-btn-underline {
	font-size: 16px;
	position: relative;
	color: #138afd;
}
.custom-btn-underline:after,
.custom-btn-underline:before {
	left: 0;
	bottom: -2px;
	height: 2px;
	width: 100%;
	content: '';
	position: absolute;
	background-color: currentColor;
}
.custom-btn-underline:before {
	-webkit-transform-origin: 100% 50%;
	-ms-transform-origin: 100% 50%;
	transform-origin: 100% 50%;
	-webkit-transition: -webkit-transform 0.3s ease;
	transition: -webkit-transform 0.3s ease;
	-o-transition: transform 0.3s ease;
	transition: transform 0.3s ease;
	transition: transform 0.3s ease, -webkit-transform 0.3s ease;
}
.custom-btn-underline:after {
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
	-webkit-transform-origin: 0 50%;
	-ms-transform-origin: 0 50%;
	transform-origin: 0 50%;
	-webkit-transition: -webkit-transform 0.3s ease 0.2s;
	transition: -webkit-transform 0.3s ease 0.2s;
	-o-transition: transform 0.3s ease 0.2s;
	transition: transform 0.3s ease 0.2s;
	transition: transform 0.3s ease 0.2s, -webkit-transform 0.3s ease 0.2s;
}
.custom-btn-underline:hover {
	color: #138afd;
}
.custom-btn-underline:hover:before {
	-webkit-transform: scaleX(0);
	-ms-transform: scaleX(0);
	transform: scaleX(0);
}
.custom-btn-underline:hover:after {
	-webkit-transform: scaleX(1);
	-ms-transform: scaleX(1);
	transform: scaleX(1);
}

.store-btn {
	z-index: 1;
	overflow: hidden;
	color: #ffffff;
	position: relative;
	padding: 15px 20px;
	border-radius: 3px;
}
.store-btn:after,
.store-btn:before {
	top: 0px;
	width: 0px;
	left: 100%;
	content: '';
	z-index: -1;
	height: 100%;
	position: absolute;
	-webkit-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	-webkit-transition-timing-function: inherit;
	-o-transition-timing-function: inherit;
	transition-timing-function: inherit;
	background-color: rgba(255, 255, 255, 0.1);
}
.store-btn:before {
	z-index: -1;
	background-color: rgba(0, 0, 0, 0.1);
}
.store-btn:after {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.store-btn:hover {
	color: #ffffff;
}
.store-btn:hover:after,
.store-btn:hover:before {
	left: 0;
	width: 100%;
	-webkit-transition-property: width;
	-o-transition-property: width;
	transition-property: width;
}
.store-btn:hover:before {
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.store-btn:hover:after {
	-webkit-transition-delay: 0s;
	-o-transition-delay: 0s;
	transition-delay: 0s;
	-webkit-transition-property: width;
	-o-transition-property: width;
	transition-property: width;
}
.store-btn > .icon {
	float: left;
	font-size: 36px;
	margin: -2px 0px;
	color: #ffffff;
	margin-right: 15px;
}
.store-btn .title-text {
	display: table;
	font-size: 16px;
	color: #ffffff;
}
.store-btn .title-text small {
	width: 100%;
	display: table;
	font-size: 14px;
	text-transform: lowercase;
	color: rgba(255, 255, 255, 0.8);
}

.store-btn.bg-default-blue:before {
	background-color: #e255c8;
}

.store-btn.bg-default-black:before {
	background-color: #138afd;
}

.store-btn.bg-default-pink:before {
	background-color: #6b45f3;
}

/* button style - end
================================================== */
/* title text - start
================================================== */
.section-title .sub-title {
	font-size: 16px;
	color: #e255c8;
	font-weight: 500;
	text-transform: capitalize;
}

.section-title .title-text {
	font-size: 36px;
	font-weight: 700;
	text-transform: initial;
}

.section-title .paragraph-text {
	font-size: 16px;
	line-height: 26px;
}

/* title text - end
================================================== */
/* back to top - start
================================================== */
#thetop {
	top: 0;
	left: 0;
	right: 0;
	z-index: -1;
	position: absolute;
}

#backtotop {
	right: 15px;
	width: 50px;
	height: 50px;
	z-index: 999;
	bottom: 40px;
	display: none;
	position: fixed;
	border-radius: 100%;
	background-color: #128bfd;
	-webkit-box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.3);
	box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.3);
}

#backtotop #scroll {
	z-index: 1;
	width: 100%;
	height: 100%;
	display: block;
	color: #ffffff;
	font-size: 24px;
	line-height: 52px;
	text-align: center;
	position: relative;
}

/* back to top - end
================================================== */
/* preloader - start
================================================== */
/*
#preloader {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1005;
  position: fixed;
  overflow: visible;
  background: #ffffff url('../images/preloader.gif') no-repeat center center;
}
*/
/* preloader - end
================================================== */
/* custom colors - start
================================================== */
.bg-default-pink {
	background-color: #e255c8;
}

.bg-default-blue {
	background-color: #6b45f3;
}

.bg-default-black {
	background-color: #e255c8;
}

.bg-white {
	background-color: #ffffff;
}

.bg-black {
	background-color: #000000;
}

.bg-rating-star {
	background-color: #f2b01e;
}

.bg-deep-gray {
	background-color: #e3e8fe;
}

.bg-light-gray {
	background-color: #f7f9fe;
}

.bg-twitter {
	background-color: #1da1f2;
}

.bg-youtube {
	background-color: #ff0000;
}

.bg-facebook {
	background-color: #3b5998;
}

.bg-linkedin {
	background-color: #007bb5;
}

.bg-pinterest {
	background-color: #bd081c;
}

.bg-google-plus {
	background-color: #db4437;
}

.bg-instagram-magenta {
	background-color: #c32aa3;
}

/* custom colors - end
================================================== */
/* animation - start
================================================== */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	/* animation: spin 20s infinite linear; */
}
@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
	/* animation: spin 20s infinite linear; */
}

@-webkit-keyframes zoominout {
	0% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	/* animation: zoominout 1.5s alternate infinite ease-in; */
}

@keyframes zoominout {
	0% {
		-webkit-transform: scale(0.8);
		transform: scale(0.8);
	}
	100% {
		-webkit-transform: scale(1.2);
		transform: scale(1.2);
	}
	/* animation: zoominout 1.5s alternate infinite ease-in; */
}

@-webkit-keyframes updown {
	0% {
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	/* animation: updown 1s infinite alternate; */
}

@keyframes updown {
	0% {
		-webkit-transform: translateY(10px);
		transform: translateY(10px);
	}
	100% {
		-webkit-transform: translateY(-10px);
		transform: translateY(-10px);
	}
	/* animation: updown 1s infinite alternate; */
}

@-webkit-keyframes pulse {
	0% {
		-webkit-box-shadow: 0px 0px 0px 0px rgba(246, 113, 111, 0.5);
		box-shadow: 0px 0px 0px 0px rgba(246, 113, 111, 0.5);
	}
	100% {
		-webkit-box-shadow: 0px 0px 0px 30px rgba(246, 113, 111, 0);
		box-shadow: 0px 0px 0px 30px rgba(246, 113, 111, 0);
	}
	/* animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1); */
}

@keyframes pulse {
	0% {
		-webkit-box-shadow: 0px 0px 0px 0px rgba(246, 113, 111, 0.5);
		box-shadow: 0px 0px 0px 0px rgba(246, 113, 111, 0.5);
	}
	100% {
		-webkit-box-shadow: 0px 0px 0px 30px rgba(246, 113, 111, 0);
		box-shadow: 0px 0px 0px 30px rgba(246, 113, 111, 0);
	}
	/* animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1); */
}

@-webkit-keyframes ripple {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	60% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	/* animation: ripple infinite 4s; */
}

@keyframes ripple {
	0% {
		opacity: 0;
		-webkit-transform: scale(0);
		transform: scale(0);
	}
	60% {
		opacity: 1;
	}
	100% {
		opacity: 0;
		-webkit-transform: scale(1);
		transform: scale(1);
	}
	/* animation: ripple infinite 4s; */
}

/* animation - end
================================================== */
/* transition - start
================================================== */
a,
button {
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

/* transition - end
================================================== */
/* 1 - global area - end
==================================================================================================== */
/* 2 - header section - start
==================================================================================================== */
.header-section {
	padding: 50px 0px;
	/* main-menubar - start
	================================================== */
	/* main-menubar - end
	================================================== */
	/* btns-group - start
	================================================== */
	/* btns-group - end
	================================================== */
	/* sub menu - start
	================================================== */
	/* sub menu - end
	================================================== */
	/* inner sub menu - start
	================================================== */
	/* inner sub menu - end
	================================================== */
}
.header-section .container-fluid {
	max-width: 1750px;
	padding-left: 15px;
	padding-right: 15px;
}
.header-section .brand-logo .brand-link {
	width: 100%;
	display: block;
}
.header-section .main-menubar > ul > li {
	padding: 14px 0px;
	margin-right: 40px;
}
.header-section .main-menubar > ul > li:last-child {
	margin-right: 0px;
}
.header-section .main-menubar > ul > li > a {
	font-size: 16px;
	position: relative;
	font-weight: 500;
	text-transform: capitalize;
}
.header-section .main-menubar > ul > li:hover > a {
	color: #138afd;
}
.header-section .main-menubar > ul > li:hover > a:after {
	color: #138afd;
}
.header-section .main-menubar > ul > .active > a {
	color: #138afd;
}
.header-section .main-menubar > ul > .active > a:after {
	color: #138afd;
}
.header-section .btns-group > ul {
	margin: -2px 0px;
}
.header-section .btns-group > ul > li .custom-btn {
	width: 100%;
	display: block;
	padding: 0px 38px;
}
.header-section .btns-group > ul > li .mobile-menu-btn {
	width: 55px;
	height: 55px;
	font-size: 26px;
	line-height: 60px;
	margin-left: 35px;
	color: #ffffff;
	text-align: center;
	border-radius: 100%;
	display: inline-block;
}
.header-section .main-menubar > ul > .menu-item-has-child {
	position: relative;
}
.header-section .main-menubar > ul > .menu-item-has-child > a {
	padding-right: 15px;
}
.header-section .main-menubar > ul > .menu-item-has-child > a:after {
	top: 5px;
	right: 0px;
	font-size: 10px;
	content: '\f100';
	position: absolute;
	font-weight: 900;
	font-family: 'Flaticon';
	-webkit-transform: rotate(-180deg);
	-ms-transform: rotate(-180deg);
	transform: rotate(-180deg);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.header-section .main-menubar > ul > .menu-item-has-child:hover > a:after {
	-webkit-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	transform: rotate(45deg);
}
.header-section .main-menubar > ul > .menu-item-has-child > .sub-menu {
	z-index: 2;
	top: 120%;
	opacity: 0;
	left: -30px;
	margin: 0px;
	min-width: 220px;
	padding: 20px 0px;
	visibility: hidden;
	position: absolute;
	border-radius: 5px;
	border-top-left-radius: 0px;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
	box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
}
.header-section .main-menubar > ul > .menu-item-has-child > .sub-menu > li {
	width: 100%;
	display: table;
	padding-left: 20px;
}
.header-section .main-menubar > ul > .menu-item-has-child > .sub-menu > li > a {
	width: 100%;
	height: 45px;
	padding: 0px 20px;
	line-height: 45px;
	position: relative;
	color: #535e72;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:after,
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:before {
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 2px;
	content: '';
	height: 100%;
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:before {
	background-color: #e3e8fe;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> li:hover
	> a {
	padding-left: 30px;
	color: #272d3a;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> li:hover
	> a:before {
	background-color: #138afd;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .active
	> a {
	padding-left: 30px;
	color: #272d3a;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .active
	> a:before {
	background-color: #138afd;
}
.header-section .main-menubar > ul > .menu-item-has-child:hover > .sub-menu {
	top: 100%;
	opacity: 1;
	visibility: visible;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child {
	position: relative;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu {
	z-index: 2;
	top: -20px;
	opacity: 0;
	left: 100%;
	margin: 0px;
	min-width: 220px;
	padding: 20px 0px;
	visibility: hidden;
	position: absolute;
	border-radius: 5px;
	border-top-left-radius: 0px;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	-webkit-box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.1);
	box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.1);
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li {
	width: 100%;
	display: table;
	padding-left: 20px;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li
	> a {
	width: 100%;
	height: 45px;
	padding: 0px 20px;
	line-height: 45px;
	position: relative;
	color: #535e72;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:after,
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:before {
	top: 0px;
	left: 0px;
	bottom: 0px;
	width: 2px;
	content: '';
	height: 100%;
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li
	> a:before {
	background-color: #e3e8fe;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li:hover
	> a {
	padding-left: 30px;
	color: #272d3a;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> li:hover
	> a:before {
	background-color: #138afd;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> .active
	> a {
	padding-left: 30px;
	color: #272d3a;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child
	> .sub-menu
	> .active
	> a:before {
	background-color: #138afd;
}
.header-section
	.main-menubar
	> ul
	> .menu-item-has-child
	> .sub-menu
	> .menu-item-has-child:hover
	> .sub-menu {
	opacity: 1;
	visibility: visible;
}

/* header changes in different pages - start
================================================== */
/* white-content - start */
.header-section.white-content .main-menubar > ul > li > a {
	color: #ffffff;
}
.header-section.white-content .main-menubar > ul > li > a:after {
	color: #ffffff;
}

.header-section.white-content .main-menubar > ul > li:hover > a {
	color: #ffffff;
}
.header-section.white-content .main-menubar > ul > li:hover > a:after {
	color: #ffffff;
}

.header-section.white-content .main-menubar > ul .active > a {
	color: #ffffff;
}
.header-section.white-content .main-menubar > ul .active > a:after {
	color: #ffffff;
}

.header-section.white-content .btns-group > ul > li .custom-btn {
	border: 1px solid #ffffff;
	background-color: rgba(255, 255, 255, 0.1);
}
.header-section.white-content .btns-group > ul > li .custom-btn:hover {
	border-color: #138afd;
}

/* white-content - end */
/* black-content - start */
.header-section.black-content .main-menubar > ul > li > a {
	color: #272d3a;
}

.header-section.black-content .main-menubar > ul > li:hover > a {
	color: #138afd;
}

.header-section.black-content .main-menubar > ul .active > a {
	color: #138afd;
}

.header-section.black-content .btns-group > ul > li .mobile-menu-btn {
	color: #272d3a;
}

/* black-content - end */
/* header changes in different pages - end
================================================== */
/* sticky-header - start
================================================== */
.sticky-header {
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	z-index: 999;
	position: fixed;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.sticky-header:before {
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: -1;
	content: '';
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}

.sticky-header.stuck {
	display: none;
	padding: 20px 0px;
}
/* .sticky-header.stuck:before {
	  
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    content: '';
    position: absolute;
    background-color: white; }
    -webkit-box-shadow: 0 2px 6px 1px rgba(48, 48, 48, 0.1);
    box-shadow: 0 2px 6px 1px rgba(48, 48, 48, 0.1); 
  } */
/* } */
.black-content.sticky-header.stuck:before {
	background-color: #ffffff;
}

/* sticky-header - end
================================================== */
/* sidebar menu - start
================================================== */
.sidebar-menu-wrapper {
	display: block;
}
.sidebar-menu-wrapper .sidebar-menu {
	top: 0;
	right: -700px;
	height: 100vh;
	z-index: 9999;
	position: fixed;
	min-width: 680px;
	max-width: 680px;
	padding: 40px 60px;
	overflow-y: scroll;
	background-color: #ffffff;
	-webkit-transition: 0.6s cubic-bezier(1, 0, 0, 1);
	-o-transition: 0.6s cubic-bezier(1, 0, 0, 1);
	transition: 0.6s cubic-bezier(1, 0, 0, 1);
	/* btns-group - start */
	/* btns-group - end */
	/* menu items - start */
	/* menu items - end */
	/* menu items >> sub menu - start */
	/* menu items >> sub menu - end */
	/* contact-info - start */
	/* contact-info - end */
}
.sidebar-menu-wrapper .sidebar-menu .mCustomScrollBox {
	overflow: visible !important;
}
.sidebar-menu-wrapper .sidebar-menu .dismiss .close-btn {
	width: 40px;
	height: 40px;
	font-size: 14px;
	cursor: pointer;
	line-height: 42px;
	text-align: center;
	color: #ffffff;
	border-radius: 3px;
	background-color: #138afd;
}
.sidebar-menu-wrapper .sidebar-menu .sidebar-title {
	font-size: 21px;
	text-transform: capitalize;
}
.sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li {
	margin-right: 10px;
}
.sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li:last-child {
	margin-right: 0px;
}
.sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li .store-btn {
	width: 100%;
	padding: 15px;
	display: block;
}
.sidebar-menu-wrapper .sidebar-menu .menu-list > li {
	width: 100%;
	display: table;
	margin-bottom: 20px;
}
.sidebar-menu-wrapper .sidebar-menu .menu-list > li:last-child {
	margin-bottom: 0px;
}
.sidebar-menu-wrapper .sidebar-menu .menu-list > li > a {
	font-size: 16px;
	color: #535e72;
	text-transform: capitalize;
}
.sidebar-menu-wrapper .sidebar-menu .menu-list > li:hover > a {
	color: #272d3a;
	text-decoration: underline;
}
.sidebar-menu-wrapper .sidebar-menu .menu-list > .active > a {
	color: #272d3a;
	text-decoration: underline;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> li {
	padding-left: 30px;
	position: relative;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> li:before {
	top: 50%;
	left: 0px;
	width: 4px;
	height: 4px;
	z-index: 1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #e3e8fe;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> li
	> a {
	font-size: 14px;
	padding: 10px 0px;
	color: #535e72;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> li:hover:before {
	background-color: #138afd;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> li:hover
	> a {
	color: #272d3a;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> .active:before {
	background-color: #138afd;
}
.sidebar-menu-wrapper
	.sidebar-menu
	.menu-list
	> .menu-item-has-child
	> .sub-menu
	> .active
	> a {
	color: #272d3a;
}
.sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li {
	font-size: 16px;
	margin-bottom: 15px;
}
.sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li:last-child {
	margin-bottom: 0px;
}
.sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li .icon {
	font-size: 18px;
	margin-right: 15px;
	color: #138afd;
}
.sidebar-menu-wrapper .sidebar-menu.active {
	right: 0px;
	-webkit-box-shadow: 0px 10px 55px 1px rgba(137, 173, 255, 0.35);
	box-shadow: 0px 10px 55px 1px rgba(137, 173, 255, 0.35);
}
.sidebar-menu-wrapper .overlay {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0;
	z-index: 999;
	width: 100vw;
	height: 100vh;
	display: none;
	position: fixed;
	background: rgba(0, 0, 0, 0.7);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.sidebar-menu-wrapper .overlay.active {
	opacity: 1;
	display: block;
}

.sidebar-menu::-webkit-scrollbar {
	width: 0px;
}

/* sidebar menu - end
================================================== */
/* 2 - header section - end
==================================================================================================== */
/* 3 - footer section - start
==================================================================================================== */
.footer-section {
	background-size: 100% 59%;
	background-repeat: no-repeat;
	background-position: center bottom;
}
.footer-section .item-title {
	font-size: 24px;
	margin-bottom: 40px;
	text-transform: capitalize;
}
.footer-section .newsletter-section .section-title .title-text {
	margin: 0 auto;
	font-size: 30px;
	max-width: 370px;
}
.footer-section .newsletter-section .newsletter-form {
	overflow: hidden;
	border-radius: 5px;
	padding: 45px 70px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.footer-section .newsletter-section .newsletter-form .form-item #email-input {
	width: 100%;
	height: 70px;
	display: table;
	padding: 0px 30px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.footer-section .newsletter-section .newsletter-form .form-item .form-item-btn {
	top: 50%;
	right: 10px;
	width: auto;
	height: 50px;
	line-height: 50px;
	padding: 0px 40px;
	color: #ffffff;
	text-align: center;
	border-radius: 3px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.footer-section .newsletter-section p {
	color: #687596;
	font-size: 16px;
	font-weight: 500;
}
.footer-section .btns-group > ul > li {
	margin-right: 20px;
}
.footer-section .btns-group > ul > li:last-child {
	margin-right: 0px;
}
.footer-section .footer-content .about-content > p {
	font-weight: 500;
}
.footer-section .footer-content .useful-links > ul > li {
	margin-bottom: 15px;
}
.footer-section .footer-content .useful-links > ul > li:last-child {
	margin-bottom: 0px;
}
.footer-section .footer-content .useful-links > ul > li > a {
	color: #535e72;
}
.footer-section .footer-content .useful-links > ul > li > a:hover {
	color: #138afd;
	text-decoration: underline;
}
.footer-section .footer-content .contact-info > ul > li {
	margin-bottom: 20px;
}
.footer-section .footer-content .contact-info > ul > li:last-child {
	margin-bottom: 0px;
}
.footer-section .footer-content .contact-info > ul > li > a .icon {
	float: left;
	font-size: 24px;
	margin: -5px 0px;
	margin-right: 15px;
	color: #138afd;
}
.footer-section .footer-content .contact-info > ul > li > a .info-text {
	display: table;
	line-height: 24px;
	color: #535e72;
	font-weight: 500;
}
.footer-section .footer-content .contact-info > ul > li > a .info-text:hover {
	color: #138afd;
	text-decoration: underline;
}
.footer-section .footer-bottom {
	padding: 50px 0px;
	border-top: 2px solid #eef1ff;
}
.footer-section .footer-bottom .useful-links > ul > li {
	margin-right: 35px;
}
.footer-section .footer-bottom .useful-links > ul > li:last-child {
	margin-right: 0px;
}
.footer-section .footer-bottom .useful-links > ul > li > a {
	color: #535e72;
}
.footer-section .footer-bottom .useful-links > ul > li > a:hover {
	color: #138afd;
	text-decoration: underline;
}

/* 3 - footer section - end
==================================================================================================== */
/* 4 - client section - start
==================================================================================================== */
.client-section {
	padding: 60px 0px;
}
.client-section .client-carousel .item .client-logo {
	width: 100%;
	height: 60px;
	overflow: hidden;
	position: relative;
}
.client-section .client-carousel .item .client-logo .after-image,
.client-section .client-carousel .item .client-logo .before-image {
	left: 50%;
	width: 85%;
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.client-section .client-carousel .item .client-logo .before-image {
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.client-section .client-carousel .item .client-logo .after-image {
	top: 150%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.client-section .client-carousel .item .client-logo:hover .before-image {
	top: -50%;
}
.client-section .client-carousel .item .client-logo:hover .after-image {
	top: 50%;
}

/* 4 - client section - end
==================================================================================================== */
/* 5 - sidebar section - start
==================================================================================================== */
.sidebar-section {
	/* widget_search - satrt */
	/* widget_search - end */
	/* widget_categories - start */
	/* widget_categories - end */
	/* widget_cixposttype - start */
	/* widget_cixposttype - end */
	/* widget_tag_cloud - start */
	/* widget_tag_cloud - end */
	/* widget_instagram - start */
	/* widget_instagram - end */
	/* widget_newsletter - start */
	/* widget_newsletter - end */
}
.sidebar-section .widget_title {
	font-size: 24px;
	padding: 0px 30px;
	position: relative;
	margin-left: -30px;
	margin-right: -30px;
	margin-bottom: 30px;
	padding-bottom: 30px;
	border-bottom: 1px solid #e3e8fe;
}
.sidebar-section .widget_title:before {
	top: 0px;
	left: -1px;
	width: 3px;
	content: '';
	height: 30px;
	position: absolute;
	background-color: #138afd;
}
.sidebar-section .widget {
	padding: 30px;
	border-radius: 3px;
	margin-bottom: 50px;
	border: 1px solid #e3e8fe;
}
.sidebar-section .widget:last-child {
	margin-bottom: 0px;
}
.sidebar-section .widget_search .form-item .search-btn {
	top: 0px;
	right: 0px;
	z-index: 1;
	width: auto;
	height: 50px;
	font-size: 20px;
	padding: 0px 25px;
	line-height: 50px;
	position: absolute;
	color: #ffffff;
	text-align: center;
	background-color: #138afd;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}
.sidebar-section .widget_categories > ul > li {
	margin-bottom: 25px;
}
.sidebar-section .widget_categories > ul > li:last-child {
	margin-bottom: 0px;
}
.sidebar-section .widget_categories > ul > li > a,
.sidebar-section .widget_categories > ul > li > .badge {
	font-size: 16px;
	color: #535e72;
	font-weight: 500;
}
.sidebar-section .widget_categories > ul > li > a {
	position: relative;
}
.sidebar-section .widget_categories > ul > li > a:before {
	left: 0px;
	right: 0px;
	opacity: 0;
	content: '';
	height: 2px;
	width: 100%;
	bottom: -5px;
	position: absolute;
	background-color: #138afd;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.sidebar-section .widget_categories > ul > li:hover > a {
	color: #138afd;
}
.sidebar-section .widget_categories > ul > li:hover > .badge {
	color: #138afd;
}
.sidebar-section .widget_categories > ul > li:hover > a:before {
	opacity: 1;
	bottom: -2px;
}
.sidebar-section .widget_cixposttype > ul > li {
	margin-bottom: 30px;
}
.sidebar-section .widget_cixposttype > ul > li:last-child {
	margin-bottom: 0px;
}
.sidebar-section .widget_cixposttype > ul > li .post-date {
	width: 100%;
	display: table;
	margin-bottom: 15px;
}
.sidebar-section .widget_cixposttype > ul > li .item-title {
	color: #535e72;
	font-size: 18px;
	line-height: 26px;
	font-weight: 500;
}
.sidebar-section .widget_cixposttype > ul > li:hover .item-title {
	color: #138afd;
}
.sidebar-section .widget_tag_cloud > ul {
	margin: -5px;
}
.sidebar-section .widget_tag_cloud > ul > li {
	padding: 5px;
}
.sidebar-section .widget_tag_cloud > ul > li > a {
	width: auto;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	padding: 0px 15px;
	text-align: center;
	color: #535e72;
	border: 1px solid #e3e8fe;
}
.sidebar-section .widget_tag_cloud > ul > li > a:hover {
	color: #ffffff;
	border-color: #138afd;
	background-color: #138afd;
}
.sidebar-section .widget_instagram {
	/* .widget_title {
			&:before {background-color: #e8be84;}
		} */
}
.sidebar-section .widget_instagram > ul {
	margin: -5px;
}
.sidebar-section .widget_instagram > ul > li {
	padding: 5px;
	width: 106px;
	height: 106px;
}
.sidebar-section .widget_instagram > ul > li > a {
	width: 100%;
	height: 100%;
	display: block;
	overflow: hidden;
	position: relative;
}
.sidebar-section .widget_instagram > ul > li > a:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0;
	content: '';
	position: absolute;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: rgba(0, 0, 0, 0.7);
}
.sidebar-section .widget_instagram > ul > li > a:after {
	top: 50%;
	left: 50%;
	z-index: 1;
	opacity: 0;
	font-size: 60px;
	content: '\ea56';
	color: #ffffff;
	position: absolute;
	font-family: 'unicons';
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.sidebar-section .widget_instagram > ul > li > a:hover:before {
	opacity: 1;
}
.sidebar-section .widget_instagram > ul > li > a:hover:after {
	opacity: 1;
	font-size: 36px;
}
.sidebar-section .widget_newsletter .form-item .submit-btn {
	top: 0px;
	right: 0px;
	z-index: 1;
	width: auto;
	height: 50px;
	font-size: 20px;
	padding: 0px 25px;
	line-height: 50px;
	position: absolute;
	color: #ffffff;
	text-align: center;
	background-color: #138afd;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

/* 5 - sidebar section - end
==================================================================================================== */
/* 6 - breadcrumb section - start
==================================================================================================== */
.breadcrumb-section {
	z-index: 1;
	overflow: hidden;
	padding-top: 217px;
	position: relative;
	padding-bottom: 30px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
	/* section-title - satrt */
	/* section-title - end */
	/* breadcrumb-content - start */
	/* breadcrumb-content - end */
	/* breadcrumb-nav - start */
	/* breadcrumb-nav - end */
}
.breadcrumb-section .section-title {
	margin-top: 30px;
	margin-bottom: 160px;
}
.breadcrumb-section .section-title .title-text {
	font-size: 36px;
	color: #ffffff;
	font-weight: 700;
	text-transform: initial;
}
.breadcrumb-section .section-title .paragraph-text {
	font-size: 16px;
	max-width: 430px;
	color: #ffffff;
}
.breadcrumb-section .breadcrumb-content .page-name {
	width: auto;
	height: 35px;
	font-size: 12px;
	padding: 0px 45px;
	line-height: 35px;
	color: #ffffff;
	letter-spacing: 2px;
	font-weight: 500;
	text-transform: uppercase;
	border: 1px solid rgba(255, 255, 255, 0.4);
}
.breadcrumb-section .breadcrumb-nav > .breadcrumb {
	margin: 0px;
	padding: 0px;
	border: none;
	border-radius: 0px;
	background-color: transparent;
}
.breadcrumb-section .breadcrumb-nav > .breadcrumb > .breadcrumb-item {
	font-size: 16px;
	margin-right: 16px;
	padding-right: 16px;
	color: #ffffff;
	position: relative;
	text-transform: capitalize;
}
.breadcrumb-section .breadcrumb-nav > .breadcrumb > .breadcrumb-item:before {
	top: 50%;
	right: -2px;
	margin: 0px;
	padding: 0px;
	content: '-';
	position: absolute;
	color: #ffffff;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.breadcrumb-section
	.breadcrumb-nav
	> .breadcrumb
	> .breadcrumb-item:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.breadcrumb-section
	.breadcrumb-nav
	> .breadcrumb
	> .breadcrumb-item:last-child:before {
	display: none;
}
.breadcrumb-section .breadcrumb-nav > .breadcrumb > .breadcrumb-item > a {
	font-size: 16px;
	color: #ffffff;
	font-weight: 400;
	text-transform: capitalize;
}
.breadcrumb-section .design-image-1 {
	right: 290px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .image-1 {
	width: 432px;
}
.breadcrumb-section .design-image-1 .man-image-1 {
	top: 58px;
	left: -30px;
	width: 137px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .man-image-2 {
	top: 61px;
	right: 22px;
	width: 170px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .shape-image-1 {
	top: 117px;
	left: 154px;
	width: 37px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .medal-image-1 {
	top: 34px;
	left: 135px;
	width: 75px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .shape-image-2 {
	top: 54px;
	right: 48px;
	width: 37px;
	position: absolute;
}
.breadcrumb-section .design-image-1 .medal-image-2 {
	top: 0px;
	right: 43px;
	width: 53px;
	position: absolute;
}
.breadcrumb-section .design-image-2 {
	left: -65px;
	width: 337px;
	bottom: -106px;
	position: absolute;
}
.breadcrumb-section .spin-image {
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.breadcrumb-section .cross-image-1 {
	top: 305px;
	left: 150px;
	position: absolute;
}
.breadcrumb-section .cross-image-2 {
	left: 520px;
	bottom: 120px;
	position: absolute;
}
.breadcrumb-section .box-image-1 {
	top: 170px;
	left: 745px;
	position: absolute;
}
.breadcrumb-section .box-image-2 {
	right: 170px;
	bottom: 145px;
	position: absolute;
}
.breadcrumb-section .circle-half-1 {
	top: 160px;
	left: 1045px;
	position: absolute;
}
.breadcrumb-section .circle-half-2 {
	top: 218px;
	right: 160px;
	position: absolute;
}
.breadcrumb-section .flow-image-1 {
	top: 115px;
	left: 440px;
	position: absolute;
}
.breadcrumb-section .flow-image-2 {
	top: 355px;
	left: 970px;
	position: absolute;
}
.breadcrumb-section .zoominout-image {
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.breadcrumb-section .circle-image-1 {
	top: 140px;
	right: 500px;
	position: absolute;
}

/* 6 - breadcrumb section - end
==================================================================================================== */
/* 7 - mobile app -->> home page - start
==================================================================================================== */
/* 7.1 - home page 1 - start
================================================== */
/* banner-section - start */
.banner-section .mobile-app-banner-1 {
	z-index: 1;
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 145px;
}
.banner-section .mobile-app-banner-1 .banner-content {
	z-index: 1;
	padding-top: 185px;
}
.banner-section .mobile-app-banner-1 .banner-content > h2 {
	font-size: 47px;
	line-height: 60px;
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: initial;
}
.banner-section .mobile-app-banner-1 .banner-content > p {
	font-size: 16px;
	margin-bottom: 70px;
}
.banner-section .mobile-app-banner-1 .banner-content .btns-group > ul > li {
	margin-right: 30px;
}
.banner-section
	.mobile-app-banner-1
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section .mobile-app-banner-1 .banner-image {
	padding-top: 25px;
	position: relative;
	padding-bottom: 70px;
}
.banner-section .mobile-app-banner-1 .banner-image .bg-image {
	top: unset !important;
	left: unset !important;
	position: static;
	width: 685px;
	margin-right: -210px;
}
.banner-section .mobile-app-banner-1 .banner-image .phone-image {
	z-index: 1;
	right: 40px;
	width: 275px;
	position: absolute;
	top: 155px !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-1 .banner-image .man-image-1 {
	z-index: 1;
	bottom: 0px;
	width: 255px;
	position: absolute;
	left: 0px !important;
	top: unset !important;
}
.banner-section .mobile-app-banner-1 .banner-image .man-image-2 {
	z-index: 1;
	bottom: 0px;
	width: 240px;
	right: -155px;
	position: absolute;
	top: unset !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-1 .banner-image .man-image-3 {
	z-index: 1;
	right: -35px;
	width: 135px;
	position: absolute;
	top: 0px !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-1 .banner-image .cog-image {
	width: 60px;
	position: absolute;
	top: 90px !important;
	left: 55% !important;
	-webkit-transform: translateX(-55%);
	-ms-transform: translateX(-55%);
	transform: translateX(-55%);
	-webkit-animation: spin 10s infinite linear;
	animation: spin 10s infinite linear;
}
.banner-section .mobile-app-banner-1 .banner-image .leaf-image-1 {
	z-index: 0;
	width: 165px;
	position: absolute;
	top: 105px !important;
	left: 30px !important;
}
.banner-section .mobile-app-banner-1 .banner-image .leaf-image-2 {
	z-index: 0;
	right: -76px;
	width: 175px;
	position: absolute;
	top: 245px !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-1 .shape-1 {
	top: 470px !important;
	left: 225px !important;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .mobile-app-banner-1 .shape-2 {
	top: 200px !important;
	left: 400px !important;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.banner-section .mobile-app-banner-1 .shape-3 {
	top: unset !important;
	left: 500px !important;
	bottom: 100px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .mobile-app-banner-1 .shape-4 {
	top: 245px !important;
	left: 675px !important;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .mobile-app-banner-1 .shape-5 {
	top: unset !important;
	left: 885px !important;
	bottom: 80px;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.banner-section .mobile-app-banner-1 .shape-6 {
	top: unset !important;
	left: 940px !important;
	bottom: 300px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .mobile-app-banner-1 .shape-7 {
	top: 205px !important;
	left: 1000px !important;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}

.home-page-7 .banner-section {
	position: relative;
}

.home-page-7 .banner-section:after {
	position: absolute;
	content: '';
	left: 0;
	bottom: 0;
	background: #fff;
	width: 100%;
	height: 18%;
}

/* banner-section - end */
/* client-section - start */
.home-page-1 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -30px;
}

/* client-section - end */
/* working-process-section - start */
.working-process-section .process-steps-list > ul {
	width: 100%;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}
.working-process-section .process-steps-list > ul:before {
	top: 63px;
	left: 50%;
	width: 75%;
	height: 1px;
	content: '';
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	background-color: #e3e8fe;
}
.working-process-section .process-steps-list > ul > li {
	-webkit-box-flex: 1;
	-ms-flex: 1;
	flex: 1;
	z-index: 1;
	text-align: center;
}
.working-process-section .process-steps-list > ul > li .item-icon {
	width: 130px;
	height: 130px;
	padding: 30px;
	text-align: center;
	border-radius: 100%;
	margin-bottom: 50px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.working-process-section .process-steps-list > ul > li .item-title {
	font-size: 18px;
	color: #535e72;
	font-weight: 500;
	text-transform: capitalize;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.working-process-section .process-steps-list > ul > li:hover .item-icon {
	border-color: #ffffff;
	-webkit-box-shadow: 0px 15px 50px 0px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 15px 50px 0px rgba(0, 27, 103, 0.15);
}
.working-process-section .process-steps-list > ul > li:hover .item-title {
	color: #272d3a;
}

/* working-process-section - end */
/* extra-features-section - start */
.extra-features-section {
	/* features-nav - start */
	/* features-nav - end */
}
.extra-features-section .features-nav {
	padding-bottom: 50px;
	border-bottom: 2px solid #ffffff;
}
.extra-features-section .features-nav > .nav {
	width: 100%;
}
.extra-features-section .features-nav > .nav > .nav-item {
	width: 33.333%;
	text-align: center;
}
.extra-features-section .features-nav > .nav > .nav-item > .nav-link {
	color: #272d3a;
	font-size: 21px;
	text-align: center;
	font-weight: 700;
}
.extra-features-section
	.features-nav
	> .nav
	> .nav-item
	> .nav-link
	.item-icon {
	width: 80px;
	height: 80px;
	display: table;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 20px;
}
.extra-features-section .features-nav > .nav > .nav-item > .nav-link:hover {
	color: #138afd;
}
.extra-features-section .features-nav > .nav > .nav-item > .nav-link.active {
	color: #138afd;
}
.extra-features-section .tab-content .tab-pane .feature-content {
	font-size: 16px;
}
.extra-features-section .tab-content .tab-pane .feature-content .item-title {
	font-size: 30px;
	font-weight: 400;
}
.extra-features-section
	.tab-content
	.tab-pane
	.feature-content
	.item-title
	.break-point {
	width: 100%;
	display: table;
}
.extra-features-section .tab-content .tab-pane .feature-content > p {
	font-size: 16px;
}
.extra-features-section
	.tab-content
	.tab-pane
	.feature-content
	.instructions-list
	> ul
	> li {
	display: table;
	font-size: 14px;
	margin-top: 15px;
}
.extra-features-section
	.tab-content
	.tab-pane
	.feature-content
	.instructions-list
	> ul
	> li
	> i {
	margin-right: 15px;
	color: #138afd;
}

/* extra-features-section - end */
/* features-section - start */
.features-section .feature-item {
	margin-bottom: 160px;
}
.features-section .feature-item:last-child {
	margin-bottom: 0px;
}
.features-section .feature-item .feature-image-1 {
	z-index: 1;
	padding-top: 45px;
	position: relative;
	padding-bottom: 25px;
}
.features-section .feature-item .feature-image-1 .circle-image {
	z-index: -1;
	width: 565px;
	margin-left: -100px;
	-webkit-animation: spin 20s infinite linear;
	animation: spin 20s infinite linear;
}
.features-section .feature-item .feature-image-1 .phone-image {
	top: 100px;
	left: -32px;
	z-index: 1;
	width: 438px;
	position: absolute;
}
.features-section .feature-item .feature-image-1 .comment-image-1,
.features-section .feature-item .feature-image-1 .comment-image-2 {
	z-index: 2;
	width: 400px;
	position: absolute;
}
.features-section .feature-item .feature-image-1 .comment-image-1 {
	left: 27px;
	top: 135px;
}
.features-section .feature-item .feature-image-1 .comment-image-2 {
	left: 65px;
	bottom: 10px;
}
.features-section .feature-item .feature-content {
	font-size: 16px;
	padding-top: 10px;
}
.features-section .feature-item .feature-content .section-title .sub-title {
	padding: 5px;
	padding-left: 20px;
	border-left: 3px solid #e255c8;
}
.features-section .feature-item .feature-content .section-title .title-text {
	font-size: 36px;
	font-weight: 500;
}
.features-section .feature-item .feature-content > p {
	font-size: 16px;
}
.features-section .feature-item .feature-content .btns-group > ul > li {
	margin-right: 40px;
}
.features-section
	.feature-item
	.feature-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.features-section
	.feature-item
	.feature-content
	.btns-group
	> ul
	> li
	.custom-btn-underline {
	margin: 18px 0px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 {
	padding-top: 57px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 .circle-image {
	margin-left: 0px;
	margin-right: -80px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
	top: -29px;
	left: unset;
	right: -30px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 .comment-image-1,
.features-section .feature-item:nth-child(2) .feature-image-1 .comment-image-2 {
	width: 420px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 .comment-image-1 {
	top: 30px;
	left: 0px;
}
.features-section .feature-item:nth-child(2) .feature-image-1 .comment-image-2 {
	left: unset;
	bottom: 35px;
	right: -136px;
}

/* features-section - end */
/* service-section - start */
.service-section .container {
	max-width: 1310px;
}

.service-section .section-title .paragraph-text {
	width: 707px;
	margin: 0 auto;
	font-size: 24px;
	line-height: 36px;
	color: #272d3a;
}

.service-section .service-carousel .owl-stage-outer {
	padding-top: 40px;
	padding-bottom: 50px;
}

.service-section .service-carousel .item .service-item {
	width: 100%;
	height: 100%;
	display: block;
	text-align: center;
	padding: 60px 30px;

	background-color: transparent;
}
.service-section .service-carousel .item .service-item .item-icon {
	width: 105px;
	height: 105px;
	display: table;
	font-size: 36px;
	color: #ffffff;
	position: relative;
	text-align: center;
	margin: 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center center;
}
.service-section .service-carousel .item .service-item .item-icon > i {
	top: 65%;
	left: 50%;
	position: absolute;
	-webkit-transform: translate(-50%, -65%);
	-ms-transform: translate(-50%, -65%);
	transform: translate(-50%, -65%);
}
.service-section .service-carousel .item .service-item .item-title {
	width: 100%;
	font-size: 18px;
	color: #535e72;
	font-weight: 500;
	text-transform: capitalize;
}
.service-section .service-carousel .item .service-item:hover {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}
.service-section .service-carousel .item .service-item:hover .item-title {
	color: #272d3a;
}

.service-section .service-carousel .owl-item.active.center .item .service-item {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 5px 50px 3px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 5px 50px 3px rgba(0, 27, 103, 0.1);
}
.service-section
	.service-carousel
	.owl-item.active.center
	.item
	.service-item
	.item-title {
	color: #272d3a;
}

.service-section .owl-theme .owl-dots {
	margin-top: 0px !important;
}

/* service-section - end */
/* funfact-section - start */
.funfact-section .bg-image {
	padding: 50px 0px;
	background-size: 60% 100%;
	background-repeat: no-repeat;
	background-position: top center;
}

.funfact-section .section-title .title-text {
	width: 695px;
	display: table;
	margin: 0 auto;
	line-height: 48px;
	font-weight: 300;
}

.funfact-section .counter-items-list > ul > li {
	margin-right: 130px;
}
.funfact-section .counter-items-list > ul > li:last-child {
	margin-right: 0px;
}
.funfact-section .counter-items-list > ul > li > h4 {
	font-size: 48px;
	color: #535e72;
	font-weight: 300;
}
.funfact-section .counter-items-list > ul > li .counter-title {
	font-size: 20px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}

.funfact-section .review-btn .custom-btn-underline {
	font-size: 18px;
}

/* funfact-section - end */
/* app-section - start */
.app-section .app-content {
	padding-top: 40px;
}
.app-section .app-content .section-title .title-text {
	line-height: 48px;
	font-weight: 500;
}
.app-section .app-content .btns-group > ul li {
	margin-right: 20px;
}
.app-section .app-content .btns-group > ul li:last-child {
	margin-right: 0px;
}

.app-section .app-image {
	z-index: 1;
	position: relative;
	padding-top: -40px !important;
	/* padding-top: 30px; */
	padding-bottom: 1px;
}
.app-section .app-image .phone-image {
	top: -26px;
	z-index: 1;
	right: -10px;
	width: 456px;
	position: absolute;
}
.app-section .app-image .circle-image {
	z-index: -1;
	width: 565px;
	margin-right: -47px;
	-webkit-animation: spin 20s infinite linear;
	animation: spin 20s infinite linear;
}

/* app-section - end */
/* faq-section - start */
.faq-section .queistions-container .queistions-item {
	margin-bottom: 50px;
}
.faq-section .queistions-container .queistions-item:last-child {
	margin-bottom: 0px;
}
.faq-section .queistions-container .queistions-item .item-title {
	font-size: 18px;
}

.faq-section .contact-info > p {
	width: 330px;
	margin: 0 auto;
	font-size: 16px;
	line-height: 26px;
	color: #272d3a;
	font-weight: 500;
}
.faq-section .contact-info > p > a {
	color: #138afd;
	text-transform: lowercase;
}

/* faq-section - end */
/* footer-section - start */
.home-page-1 .footer-section {
	background-size: 100% 75%;
}

/* footer-section - end */
/* 7.1 - home page 1 - end
================================================== */
/* 7.2 - home page 2 - start
================================================== */
/* header-section - start */
.home-page-2 .header-section.stuck:before {
	background-color: white;
	-webkit-box-shadow: 0 2px 6px 1px rgba(48, 48, 48, 0.1);
	box-shadow: 0 2px 6px 1px rgba(48, 48, 48, 0.1);
}

/* header-section - end */
/* banner-section - start */
.banner-section .mobile-app-banner-2 {
	width: 100%;
	height: 980px;
	position: relative;
	padding-top: 170px;
	/* margin-bottom: 156px; */
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-image: url('../images/banner/bg-img.png');
	background-position: center center;
}

@media (min-width: 2600px) {
	.banner-section .mobile-app-banner-2 {
		height: 1200px;
	}
}

@media (max-width: 1000px) {
	.banner-section .mobile-app-banner-2 {
		padding-top: 70px;
		padding-bottom: 340px;
		height: 1600px;
	}
	.mobile-app-banner-2 {
		padding-top: 70px;
		padding-bottom: 340px;
		height: 1550px !important;
	}
}

.banner-section .mobile-app-banner-2 .shape-image-1 {
	top: 0px;
	left: 0px;
	width: 472px;
	position: absolute;
}
.banner-section .mobile-app-banner-2 .shape-image-2 {
	left: 926px;
	bottom: 80px;
	position: absolute;
	-webkit-animation: zoominout 2s alternate infinite ease-in;
	animation: zoominout 2s alternate infinite ease-in;
}

@keyframes zoominout {
	0% {
		-webkit-transform: scale(0.9);
		transform: scale(0.9);
	}
	100% {
		-webkit-transform: scale(1.05);
		transform: scale(1.05);
	}
}
.banner-section .mobile-app-banner-2 .shape-image-3 {
	top: 202px;
	left: 894px;
	position: absolute;
	-webkit-animation: spin 15s infinite linear;
	animation: spin 15s infinite linear;
}
.banner-section .mobile-app-banner-2 .shape-image-4 {
	top: 268px;
	right: 209px;
	position: absolute;
	-webkit-animation: spin 15s infinite linear;
	animation: spin 15s infinite linear;
}
.banner-section .mobile-app-banner-2 .banner-content {
	padding-top: 50px;
}
.banner-section .mobile-app-banner-2 .banner-content > h2,
.banner-section .mobile-app-banner-2 .banner-content > h1 {
	font-size: 55px;
	line-height: 70px;
	color: #ffffff;
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: initial;
}
.banner-section .mobile-app-banner-2 .banner-content > p {
	font-size: 16px;
	color: #ffffff;
	margin-bottom: 70px;
}
.banner-section .mobile-app-banner-2 .banner-content .btns-group > ul > li {
	margin-right: 20px;
}
.banner-section
	.mobile-app-banner-2
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section .mobile-app-banner-2 .banner-image {
	z-index: 1;
	position: relative;
	padding-top: 130px;
}
.banner-section .mobile-app-banner-2 .banner-image .phone-image {
	transform: translate3d(0px, 0px, 0px) !important;
	transform-style: preserve-3d !important;
	backface-visibility: hidden !important;
	position: relative !important;
	display: block !important;
	left: 0px !important;
	top: -200px !important;
	left: 100px !important;

	z-index: 1;

	margin: 0 auto;
	display: table;
}
.banner-section .mobile-app-banner-2 .banner-image .phone-image img {
	width: 100%;
	max-width: 490px;
	margin-top: 30px;
	display: block;
}
.banner-section .mobile-app-banner-2 .banner-image .man-image-1 {
	z-index: 1;
	width: 155px;
	text-align: center;
	position: absolute;
	left: 0px !important;
	bottom: 0px !important;
	top: unset !important;
}
.banner-section .mobile-app-banner-2 .banner-image .man-image-2 {
	z-index: 1;
	width: 215px;
	position: absolute;
	right: 0px !important;
	bottom: 0px !important;
	top: unset !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-2 .banner-image .message-image {
	z-index: 1;
	width: 140px;
	position: absolute;
	left: 38% !important;
	top: 270px !important;
}
.banner-section .mobile-app-banner-2 .banner-image .leaf-image-1 {
	z-index: -1;
	width: 205px;
	position: absolute;
	top: 20px !important;
	left: 40px !important;
}
.banner-section .mobile-app-banner-2 .banner-image .leaf-image-2 {
	z-index: -1;
	width: 205px;
	position: absolute;
	-webkit-transform: rotate(-20deg);
	-ms-transform: rotate(-20deg);
	transform: rotate(-20deg);
	top: 80px !important;
	right: 10px !important;
	left: unset !important;
}

/* banner-section - end */
/* client-section - start */
.home-page-2 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -20px;
}

/* client-section - end */
/* features-section - start */
.home-page-2 .features-section .feature-item .feature-content {
	padding-top: 100px;
}

.features-section .feature-item .feature-image-2 {
	position: relative;
}
.features-section .feature-item .feature-image-2 .popup-video {
	z-index: 1;
	right: 50px;
	bottom: 130px;
	width: 95px;
	height: 95px;
	font-size: 24px;
	line-height: 95px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #6b45f3;
	background-color: #ffffff;
	-webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.features-section .feature-item .feature-image-2 .popup-video:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.features-section .feature-item .feature-image-2 .item-image {
	width: 570px;
	margin-left: -145px;
}

.features-section .feature-item .feature-content .feature-item-title {
	font-size: 36px;
	margin-bottom: 45px;
	font-weight: 500;
	text-transform: initial;
}

.features-section .feature-item .feature-content .service-list > ul > li {
	margin-top: 40px;
}
.features-section
	.feature-item
	.feature-content
	.service-list
	> ul
	> li
	.item-icon {
	z-index: 1;
	float: left;
	width: 60px;
	height: 60px;
	font-size: 24px;
	line-height: 60px;
	color: #ffffff;
	text-align: center;
	margin-right: 30px;
	background-repeat: no-repeat;
	background-position: center center;
}
.features-section
	.feature-item
	.feature-content
	.service-list
	> ul
	> li
	.item-content {
	display: table;
}
.features-section
	.feature-item
	.feature-content
	.service-list
	> ul
	> li
	.item-content
	.item-title {
	font-size: 21px;
}

.features-section .feature-item .feature-content .info-list > ul > li {
	margin-bottom: 20px;
}
.features-section
	.feature-item
	.feature-content
	.info-list
	> ul
	> li:last-child {
	margin-bottom: 0px;
}
.features-section .feature-item .feature-content .info-list > ul > li .icon {
	float: left;
	font-size: 18px;
	margin-right: 30px;
	color: #138afd;
}
.features-section
	.feature-item
	.feature-content
	.info-list
	> ul
	> li
	.info-text {
	display: table;
	font-size: 16px;
	line-height: 26px;
}

.features-section .feature-item .feature-image-2 .bg-image {
	z-index: -1;
	width: 570px;
	margin-top: 115px;
	position: relative;
	margin-right: -130px;
}

.features-section .feature-item .feature-image-2 .phone-image {
	top: -25px;
	right: -50px;
	width: 430px;
	position: absolute;
}

.features-section .feature-item .feature-image-2 .comment-image-1,
.features-section .feature-item .feature-image-2 .comment-image-2 {
	width: 400px;
	position: absolute;
}

.features-section .feature-item .feature-image-2 .comment-image-1 {
	top: 160px;
	right: -105px;
}

.features-section .feature-item .feature-image-2 .comment-image-2 {
	right: -120px;
	bottom: 112px;
}

.features-section .feature-item .feature-image-2 .shape-image-1 {
	top: 15px;
	left: 90px;
	z-index: -1;
	position: absolute;
}

.features-section .feature-item .feature-image-2 .shape-image-2 {
	top: 50%;
	z-index: -1;
	right: -50px;
	position: absolute;
}

.features-section .feature-item .feature-image-2 .shape-image-3 {
	z-index: -1;
	left: 110px;
	bottom: 20px;
	position: absolute;
	-webkit-transform: rotate(-130deg);
	-ms-transform: rotate(-130deg);
	transform: rotate(-130deg);
}

.features-section .feature-item:nth-child(2) .feature-image-2 .bg-image {
	margin-right: 0px;
	margin-left: -130px;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
	left: -50px;
	right: unset;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .comment-image-1,
.features-section .feature-item:nth-child(2) .feature-image-2 .comment-image-2 {
	width: 400px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .comment-image-1 {
	top: 50px;
	left: -130px;
	right: unset;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .comment-image-2 {
	left: 65px;
	right: unset;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-1 {
	left: -60px;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-2 {
	right: 80px;
}

.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
	left: -30px;
}

/* features-section - end */
/* app-section - strat */
.app-section .app-image-2 {
	position: relative;
	padding-top: 115px;
}
.app-section .app-image-2 .bg-image {
	width: 570px;
	margin-right: -60px;
}
.app-section .app-image-2 .phone-image {
	top: -36px;
	right: 0px;
	z-index: 1;
	width: 456px;
	position: absolute;
}
.app-section .app-image-2 .shape-image-1 {
	left: 60px;
	top: 140px;
	width: 83px;
	position: absolute;
}
.app-section .app-image-2 .shape-image-2 {
	right: 13px;
	top: 375px;
	width: 83px;
	position: absolute;
}
.app-section .app-image-2 .shape-image-3 {
	left: 62px;
	width: 90px;
	bottom: 15px;
	position: absolute;
}

/* app-section - end */
/* 7.2 - home page 2 - end
================================================== */
/* 7.3 - home page 3 - start
================================================== */
.home-page-3 {
	padding-top: 124px;
}

/* header-section - start */
.home-page-3 .header-section {
	padding: 35px 0px;
}
.home-page-3 .header-section .btns-group .custom-btn {
	background-color: #138afd;
}
.home-page-3 .header-section .btns-group .custom-btn:before {
	background-color: #6b45f3;
}

.home-page-3 .sticky-header.stuck {
	padding: 30px 0px;
}

/* header-section - end */
/* banner-section - start */
.slider-section .mobileapp-main-carousel {
	width: 1750px;
	margin: 0 auto;
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.2);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.2);
}
.slider-section .mobileapp-main-carousel .item {
	height: 900px;
	position: relative;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
}
.slider-section .mobileapp-main-carousel .item.first-item {
	overflow: hidden;
	padding-top: 150px;
}
.slider-section .mobileapp-main-carousel .item.first-item h3 {
	height: 35px;
	display: table;
	font-size: 14px;
	padding: 0px 25px;
	line-height: 37px;
	text-align: center;
	color: #ffffff;
	margin: 0 auto 20px;
	font-weight: 500;
	text-transform: capitalize;
	border: 1px solid rgba(255, 255, 255, 0.5);
	background-color: rgba(255, 255, 255, 0.1);
}
.slider-section .mobileapp-main-carousel .item.first-item h2 {
	font-size: 36px;
	line-height: 48px;
	color: #ffffff;
	margin-bottom: 50px;
	font-weight: 500;
}
.slider-section .mobileapp-main-carousel .item.first-item .subscribe-form {
	margin-bottom: 50px;
}
.slider-section .mobileapp-main-carousel .item.first-item .subscribe-form > p {
	font-size: 14px;
	color: #ffffff;
	font-weight: 500;
}
.slider-section
	.mobileapp-main-carousel
	.item.first-item
	.subscribe-form
	.form-item {
	width: 430px;
	display: table;
	margin: 0 auto 20px;
}
.slider-section
	.mobileapp-main-carousel
	.item.first-item
	.subscribe-form
	.form-item
	input {
	height: 60px;
	padding: 0px 30px;
}
.slider-section
	.mobileapp-main-carousel
	.item.first-item
	.subscribe-form
	.form-item
	.submit-btn {
	top: 50%;
	right: 5px;
	height: 50px;
	font-size: 16px;
	line-height: 50px;
	padding: 0px 30px;
	color: #ffffff;
	position: absolute;
	border-radius: 3px;
	font-weight: 500;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #138afd;
}
.slider-section .mobileapp-main-carousel .item.first-item .phone-image {
	width: 355px;
	display: table;
	margin: 0 auto 0px;
}
.slider-section .mobileapp-main-carousel .item.second-item {
	padding-top: 100px;
}
.slider-section .mobileapp-main-carousel .item.second-item .slider-content {
	padding-top: 172px;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.title-text {
	font-size: 47px;
	color: #ffffff;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.paragraph-text {
	font-size: 16px;
	line-height: 26px;
	color: #ffffff;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.newsletter-form {
	width: 425px;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.newsletter-form
	.form-item {
	margin-bottom: 20px;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.newsletter-form
	.form-item
	.email-input {
	width: 100%;
	height: 60px;
	display: table;
	padding: 0px 30px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-content
	.newsletter-form
	.form-item
	.form-item-btn {
	top: 50%;
	right: 8px;
	width: auto;
	height: 45px;
	line-height: 45px;
	padding: 0px 40px;
	color: #ffffff;
	text-align: center;
	border-radius: 3px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.slider-section .mobileapp-main-carousel .item.second-item .slider-image {
	position: relative;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-image
	.phone-image {
	width: 425px;
	top: unset !important;
	left: unset !important;
	position: unset !important;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-image
	.commentbar-image-1,
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-image
	.commentbar-image-2 {
	width: 400px;
	position: absolute;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-image
	.commentbar-image-1 {
	top: 73px !important;
	left: -92px !important;
}
.slider-section
	.mobileapp-main-carousel
	.item.second-item
	.slider-image
	.commentbar-image-2 {
	top: 440px !important;
	left: -92px !important;
}

.slider-section .owl-theme .owl-dots {
	top: 50%;
	right: 30px;
	position: absolute;
	margin-top: 0px !important;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.slider-section .owl-theme .owl-dots .owl-dot {
	display: table;
	margin: 10px 0px;
	background: rgba(255, 255, 255, 0.5);
}
.slider-section .owl-theme .owl-dots .owl-dot span {
	background-color: #ffffff;
}

/* banner-section - end */
/* client-section - start */
.home-page-3 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -30px;
}

/* client-section - end */
/* features-section - start */
.features-section .feature-item .feature-image-3 {
	position: relative;
	padding-top: 156px;
}
.features-section .feature-item .feature-image-3 .bg-image {
	width: 580px;
	margin-left: -70px;
	margin-right: -40px;
}
.features-section .feature-item .feature-image-3 .phone-image {
	z-index: 1;
	left: 52px;
	top: 113px;
	width: 303px;
	position: absolute;
}
.features-section .feature-item .feature-image-3 .man-image-1 {
	z-index: 2;
	left: -15px;
	bottom: 24px;
	width: 139px;
	position: absolute;
}
.features-section .feature-item .feature-image-3 .man-image-2 {
	z-index: 2;
	right: 0px;
	bottom: 24px;
	width: 189px;
	position: absolute;
}
.features-section .feature-item .feature-image-3 .comment-image {
	z-index: 2;
	top: 228px;
	left: 138px;
	width: 120px;
	position: absolute;
}
.features-section .feature-item .feature-image-3 .leaf-image-1 {
	top: 0px;
	left: -5px;
	width: 180px;
	position: absolute;
}
.features-section .feature-item .feature-image-3 .leaf-image-2 {
	top: 55px;
	right: 40px;
	width: 180px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-3 {
	padding-top: 110px;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
	margin: 0px;
	width: 554px;
	margin-left: -84px;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
	top: 116px;
	left: 85px;
	width: 246px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
	left: -26px;
	bottom: 0px;
	width: 212px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
	right: 0px;
	width: 195px;
	bottom: -4px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-1 {
	top: 27px;
	left: -12px;
	width: 212px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
	top: 0px;
	right: 57px;
	width: 180px;
	position: absolute;
}

/* features-section - end */
/* 7.3 - home page 3 - end
================================================== */
/* 7.4 - home page 4 - start
================================================== */
/* banner-section - start */
.banner-section .mobile-app-banner-4 {
	z-index: 1;
	width: 100%;
	height: 100vh;
	padding-top: 260px;
	position: relative;
}
.banner-section .mobile-app-banner-4 .bg-shape-image {
	top: 0px;
	right: 0px;
	width: 58%;
	z-index: -1;
	position: absolute;
}
.banner-section .mobile-app-banner-4 .banner-content {
	z-index: 1;
	padding-top: 100px;
}
.banner-section .mobile-app-banner-4 .banner-content > h2 {
	font-size: 47px;
	line-height: 60px;
	margin-bottom: 30px;
	font-weight: 700;
	text-transform: initial;
}
.banner-section .mobile-app-banner-4 .banner-content > p {
	font-size: 16px;
	margin-bottom: 70px;
}
.banner-section .mobile-app-banner-4 .banner-content .btns-group > ul > li {
	margin-right: 30px;
}
.banner-section
	.mobile-app-banner-4
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section .mobile-app-banner-4 .banner-image {
	padding-top: 195px;
	padding-bottom: 50px;
}
.banner-section .mobile-app-banner-4 .banner-image .phone-image {
	width: 730px;
	margin-right: -165px;
	top: unset !important;
	left: unset !important;
	position: static !important;
}
.banner-section .mobile-app-banner-4 .banner-image .man-image-1 {
	width: 280px;
	position: absolute;
	top: 60px !important;
	left: 40px !important;
}
.banner-section .mobile-app-banner-4 .banner-image .sms-image-1 {
	width: 215px;
	position: absolute;
	top: 163px !important;
	left: 186px !important;
}
.banner-section .mobile-app-banner-4 .banner-image .man-image-2 {
	z-index: 1;
	width: 330px;
	position: absolute;
	top: 40px !important;
	left: unset !important;
	right: -165px !important;
}
.banner-section .mobile-app-banner-4 .banner-image .sms-image-2 {
	width: 200px;
	position: absolute;
	top: 0px !important;
	left: unset !important;
	right: -100px !important;
}
.banner-section .mobile-app-banner-4 .banner-image .tree-image {
	bottom: 0px;
	right: 60px;
	width: 200px;
	position: absolute;
	top: unset !important;
	left: unset !important;
}

/* banner-section - end */
/* features-section - start */
.features-section .feature-item .feature-image-4 {
	z-index: 1;
	position: relative;
	padding-bottom: 55px;
}
.features-section .feature-item .feature-image-4 .phone-image {
	z-index: 1;
	width: 300px;
	display: table;
}
.features-section .feature-item .feature-image-4 .floor-image {
	z-index: -1;
	bottom: 5px;
	width: 635px;
	left: -200px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .man-image-1 {
	z-index: 2;
	bottom: 55px;
	left: -220px;
	width: 240px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .flower-image-2 {
	width: 100px;
	bottom: 55px;
	right: -130px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .comment-1-image {
	top: 45px;
	z-index: 1;
	left: -150px;
	width: 290px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .comment-2-image {
	top: 160px;
	left: -30px;
	z-index: 1;
	width: 285px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .shape-image-1 {
	bottom: 55px;
	left: -135px;
	position: absolute;
}
.features-section .feature-item .feature-image-4 .shape-image-2 {
	top: 40%;
	z-index: -1;
	right: -60px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-4 {
	padding-bottom: 80px;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
	z-index: 1;
	top: 50px;
	left: 40px;
	width: 80px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
	z-index: 1;
	top: 150px;
	right: 45px;
	width: 80px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
	z-index: 1;
	top: 250px;
	left: 40px;
	width: 80px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
	bottom: 0px;
	left: unset;
	right: -45px;
	width: 185px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
	z-index: 1;
	bottom: 0px;
	left: -225px;
	width: 365px;
	position: absolute;
}
.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
	width: 110px;
	right: -110px;
	bottom: 100px;
	position: absolute;
}

/* features-section - end */
/* 7.4 - home page 4 - end
================================================== */
/* 7.5 - home page 5 - start
================================================== */
/* header-section - start */
.home-page-5 .header-section.stuck:before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#ff4697),
		to(#ffb87e)
	);
	background: -webkit-linear-gradient(left, #ff4697, #ffb87e);
	background: -o-linear-gradient(left, #ff4697, #ffb87e);
	background: linear-gradient(to right, #ff4697, #ffb87e);
}

/* header-section - end */
/* banner-section - start */
.banner-section .mobile-app-banner-5 {
	width: 100%;
	height: auto;
	position: relative;
	padding-top: 200px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: top center;
}
.banner-section .mobile-app-banner-5 .banner-content {
	padding-top: 80px;
}
.banner-section .mobile-app-banner-5 .banner-content .title-text {
	font-size: 47px;
	color: #ffffff;
}
.banner-section .mobile-app-banner-5 .banner-content .paragraph-text {
	font-size: 16px;
	line-height: 26px;
	color: #ffffff;
}
.banner-section .mobile-app-banner-5 .banner-content .newsletter-form {
	width: 425px;
}
.banner-section
	.mobile-app-banner-5
	.banner-content
	.newsletter-form
	.form-item {
	margin-bottom: 20px;
}
.banner-section
	.mobile-app-banner-5
	.banner-content
	.newsletter-form
	.form-item
	.email-input {
	width: 100%;
	height: 60px;
	display: table;
	padding: 0px 30px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.banner-section
	.mobile-app-banner-5
	.banner-content
	.newsletter-form
	.form-item
	.form-item-btn {
	top: 50%;
	right: 8px;
	width: auto;
	height: 45px;
	line-height: 45px;
	padding: 0px 40px;
	color: #ffffff;
	text-align: center;
	border-radius: 3px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.banner-section .mobile-app-banner-5 .banner-image {
	width: 100%;
	height: 100%;
	position: relative;
}
.banner-section .mobile-app-banner-5 .banner-image .phone-image {
	width: 425px;
	top: unset !important;
	left: unset !important;
	position: unset !important;
}
.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1,
.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
	width: 400px;
	position: absolute;
}
.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1 {
	right: 185px;
	top: 73px !important;
	left: unset !important;
}
.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
	right: 150px;
	top: 445px !important;
	left: unset !important;
}

/* banner-section - end */
/* client-section - start */
.home-page-5 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -30px;
}

/* client-section - end */
/* app-section - start */
.app-section .app-image .bg-image {
	z-index: -1;
	width: 100%;
}

.app-section .app-image .shape-image-1 {
	top: 155px;
	left: 135px;
	z-index: -1;
	position: absolute;
}

.app-section .app-image .shape-image-2 {
	top: 53%;
	right: 0px;
	z-index: -1;
	position: absolute;
}

.app-section .app-image .shape-image-3 {
	z-index: -1;
	left: 145px;
	bottom: 45px;
	position: absolute;
}

/* app-section - end */
/* faq-section - start */
.faq-section .faq-tabs-nav {
	width: 270px;
	padding: 30px;
	margin: 0px auto;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 1px 5px 0px rgba(0, 27, 103, 0.15);
}
.faq-section .faq-tabs-nav > .nav {
	margin-bottom: 100px;
}
.faq-section .faq-tabs-nav > .nav > .nav-item {
	margin-bottom: 30px;
}
.faq-section .faq-tabs-nav > .nav > .nav-item:last-child {
	margin-bottom: 0px;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link {
	padding: 0px;
	color: #687596;
	font-size: 18px;
	position: relative;
	display: inline-block;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:before {
	top: 0px;
	opacity: 0;
	width: 2px;
	left: -30px;
	bottom: 0px;
	height: 100%;
	content: '';
	position: absolute;
	background-color: #138afd;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:hover {
	color: #138afd;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:hover:before {
	opacity: 1;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link.active {
	color: #138afd;
}
.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link.active:before {
	opacity: 1;
}
.faq-section .faq-tabs-nav > .contact-info > li {
	font-size: 16px;
}
.faq-section .faq-tabs-nav > .contact-info > li > a {
	color: #138afd;
	text-transform: lowercase;
}

.faq-section .tab-content {
	padding-left: 35px;
	padding-right: 70px;
}

/* faq-section - end */
/* 7.5 - home page 5 - end
================================================== */
/* 7.6 home page 6 - start
================================================== */
/* banner-section - start */
.banner-section .mobile-app-banner-6 {
	z-index: 1;
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
	padding-top: 200px;
}
.banner-section .mobile-app-banner-6 .banner-content {
	z-index: 1;
	padding-top: 210px;
}
.banner-section .mobile-app-banner-6 .banner-content h2 {
	font-size: 37px;
	line-height: 60px;
	margin-bottom: 30px;
}
.banner-section .mobile-app-banner-6 .banner-content p {
	font-size: 16px;
	margin-bottom: 70px;
}
.banner-section .mobile-app-banner-6 .banner-content .btns-group > ul > li {
	margin-right: 30px;
}
.banner-section
	.mobile-app-banner-6
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section .mobile-app-banner-6 .banner-image .phone-image {
	width: 458px;
	margin-right: -176px;
	position: static !important;
}
.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1 {
	width: 400px;
	position: absolute;
	top: 140px !important;
	left: -110px !important;
}
.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
	width: 400px;
	position: absolute;
	top: 365px !important;
	left: -70px !important;
}
.banner-section .mobile-app-banner-6 .shape-bg-image {
	top: -255px;
	z-index: -1;
	right: -315px;
	width: 1255px;
	position: absolute;
}

/* banner-section - end */
/* service-section - start */
.service-section .service-grid-item {
	padding: 60px 30px;
	border: 1px solid transparent;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.service-section .service-grid-item .item-icon {
	width: 105px;
	height: 105px;
	display: table;
	font-size: 36px;
	color: #ffffff;
	position: relative;
	text-align: center;
	margin: 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center center;
}
.service-section .service-grid-item .item-icon > i {
	top: 65%;
	left: 50%;
	position: absolute;
	-webkit-transform: translate(-50%, -65%);
	-ms-transform: translate(-50%, -65%);
	transform: translate(-50%, -65%);
}
.service-section .service-grid-item .item-title {
	font-size: 21px;
	font-weight: 700;
}
.service-section .service-grid-item p {
	font-size: 16px;
	margin-bottom: 0px;
}
.service-section .service-grid-item:hover {
	border-color: #e3e8fe;
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}

/* service-section - end */
/* features-section - start */
.home-page-6 .features-section .feature-item .feature-content {
	padding: 0px;
	padding-top: 100px;
}

.features-section .feature-item .feature-image-6 {
	padding-top: 120px;
}
.features-section .feature-item .feature-image-6 .popup-video {
	top: 280px;
	z-index: 1;
	right: 0px;
	width: 95px;
	height: 95px;
	font-size: 24px;
	line-height: 95px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #6b45f3;
	background-color: #ffffff;
	-webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.features-section .feature-item .feature-image-6 .popup-video:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.features-section .feature-item .feature-image-6 .bg-image {
	width: 570px;
	margin-left: -120px;
}
.features-section .feature-item .feature-image-6 .phone-image {
	top: 0px;
	z-index: 1;
	left: -35px;
	width: 425px;
	position: absolute;
}
.features-section .feature-item .feature-image-6 .comment-image-1,
.features-section .feature-item .feature-image-6 .comment-image-2 {
	z-index: 1;
	width: 400px;
	position: absolute;
}
.features-section .feature-item .feature-image-6 .comment-image-1 {
	top: 180px;
	left: 70px;
}
.features-section .feature-item .feature-image-6 .comment-image-2 {
	left: 115px;
	bottom: 100px;
}
.features-section .feature-item .feature-image-6 .shape-image-1 {
	top: 30px;
	left: -40px;
	position: absolute;
}
.features-section .feature-item .feature-image-6 .shape-image-2 {
	top: 52%;
	right: 100px;
	position: absolute;
}
.features-section .feature-item .feature-image-6 .shape-image-3 {
	left: -30px;
	bottom: 10px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
	margin-left: 0px;
	margin-right: -120px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
	left: unset;
	right: -35px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .comment-image-1,
.features-section .feature-item:nth-child(2) .feature-image-6 .comment-image-2 {
	z-index: 1;
	width: 400px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .comment-image-1 {
	top: 70px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .comment-image-2 {
	left: 270px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-1 {
	left: 100px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-2 {
	right: -40px;
}

.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-3 {
	left: 120px;
}

/* features-section - end */
/* working-process-section - start */
.working-process-section .process-item {
	z-index: 2;
	padding: 30px;
	margin-top: 30px;
	margin-left: -30px;
	margin-right: -30px;
	position: relative;
	border-radius: 5px;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.working-process-section .process-item:hover {
	background-color: #ffffff;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.working-process-section .process-item .item-icon {
	float: left;
	width: 70px;
	height: 115px;
	margin-right: 30px;
	line-height: 115px;
	text-align: center;
}
.working-process-section .process-item .item-content {
	display: table;
}
.working-process-section .process-item .item-content .item-title {
	font-size: 21px;
	margin-bottom: 20px;
	font-weight: 700;
}

.working-process-section .process-phone-image {
	padding-top: 100px;
}
.working-process-section .process-phone-image .bg-image {
	z-index: -1;
}
.working-process-section .process-phone-image .phone-image {
	top: 0px;
	left: 50%;
	z-index: 1;
	width: 295px;
	position: absolute;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
}
.working-process-section .process-phone-image .shape-image-1 {
	top: 150px;
	left: 35px;
	width: 70px;
	position: absolute;
}
.working-process-section .process-phone-image .shape-image-2 {
	width: 70px;
	right: 35px;
	bottom: 110px;
	position: absolute;
}

.working-process-section .seemore-btn {
	margin-top: 45px;
}

/* working-process-section - end */
/* 7.6 home page 6 - end
================================================== */
/* 7 - mobile app -->> home page - end
==================================================================================================== */
/* 8 - sass landing -->> home page - start
==================================================================================================== */
/* 8.1 - home page 7 - start
================================================== */
/* header-section - start */
.home-page-7 .sticky-header.stuck:before {
	background-color: #1e2130;
}

/* header-section - end */
/* banner-section - start */
.banner-section {
	position: relative;
}
.banner-section .sass-banner-1 {
	width: 100%;
	height: 100%;
	padding-top: 250px;
}
.banner-section .sass-banner-1 .banner-content {
	z-index: 1;
	color: #ffffff;
	position: relative;
}
.banner-section .sass-banner-1 .banner-content .title-text {
	font-size: 36px;
	color: #ffffff;
	text-transform: initial;
}
.banner-section .sass-banner-1 .banner-content p {
	width: 630px;
	margin: 0 auto;
	font-size: 16px;
	margin-bottom: 40px;
}
.banner-section .sass-banner-1 .banner-content .newsletter-form .form-item {
	width: 430px;
	margin: 0 auto;
	margin-bottom: 20px;
}
.banner-section
	.sass-banner-1
	.banner-content
	.newsletter-form
	.form-item
	input {
	height: 60px;
}
.banner-section
	.sass-banner-1
	.banner-content
	.newsletter-form
	.form-item
	.submit-btn {
	top: 5px;
	right: 5px;
	z-index: 1;
	height: 50px;
	padding: 0px 40px;
	line-height: 50px;
	color: #ffffff;
	position: absolute;
	text-align: center;
	border-radius: 3px;
}
.banner-section .sass-banner-1 .banner-content .info-list > ul > li {
	margin-right: 30px;
}
.banner-section .sass-banner-1 .banner-content .info-list > ul > li:last-child {
	margin-right: 0px;
}
.banner-section .sass-banner-1 .banner-item-image {
	z-index: 2;
	position: relative;
	margin-bottom: -220px;
}
.banner-section .sass-banner-1 .banner-item-image .child-img-1 {
	top: -14px;
	left: 364px;
	width: 395px;
	position: absolute;
}
.banner-section .sass-banner-1 .banner-item-image .child-img-2 {
	left: 0px;
	bottom: -17px;
	width: 407px;
	position: absolute;
}
.banner-section .sass-banner-1 .banner-item-image .child-img-3 {
	right: 60px;
	bottom: -17px;
	width: 407px;
	position: absolute;
}

.home-page-7 .banner-section .decoration-items {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	overflow: hidden;
	position: absolute;
}
.home-page-7 .banner-section .decoration-items .big-circle-shape {
	left: 50%;
	width: 1380px;
	height: 1380px;
	bottom: -690px;
	overflow: hidden;
	position: absolute;
	border-radius: 100%;
	-webkit-transform: translateX(-50%);
	-ms-transform: translateX(-50%);
	transform: translateX(-50%);
	border: 1px solid rgba(255, 255, 255, 0.05);
}
.home-page-7 .banner-section .decoration-items .big-circle-shape:before,
.home-page-7 .banner-section .decoration-items .big-circle-shape:after {
	top: -3px;
	left: -3px;
	opacity: 0;
	content: '';
	width: 100%;
	height: 100%;
	display: block;
	border-radius: 50%;
	position: absolute;
	-webkit-animation: ripple infinite 4s;
	animation: ripple infinite 4s;
	background-color: rgba(255, 255, 255, 0.05);
}
.home-page-7 .banner-section .decoration-items .big-circle-shape:after {
	-webkit-animation-delay: 0.8s;
	animation-delay: 0.8s;
}
.home-page-7 .banner-section .decoration-items .cross-image {
	top: 55%;
	z-index: 1;
	left: 210px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.home-page-7 .banner-section .decoration-items .flow-image-1 {
	top: 220px;
	z-index: 1;
	left: 385px;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.home-page-7 .banner-section .decoration-items .flow-image-2 {
	z-index: 1;
	left: 370px;
	bottom: 170px;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.home-page-7 .banner-section .decoration-items .circle-image-1 {
	top: 45%;
	left: 27%;
	z-index: 1;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.home-page-7 .banner-section .decoration-items .flow-image-3 {
	left: 55%;
	top: 165px;
	z-index: 1;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.home-page-7 .banner-section .decoration-items .circle-image-2 {
	top: 48%;
	left: 80%;
	z-index: 1;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.home-page-7 .banner-section .decoration-items .circle-image-3 {
	z-index: 1;
	top: 215px;
	right: 300px;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.home-page-7 .banner-section .decoration-items .box-image {
	z-index: 1;
	right: 160px;
	bottom: 270px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.home-page-7 .banner-section .decoration-items .dark-box-1,
.home-page-7 .banner-section .decoration-items .dark-box-2,
.home-page-7 .banner-section .decoration-items .dark-box-3,
.home-page-7 .banner-section .decoration-items .dark-box-4 {
	z-index: 0;
	position: absolute;
	/* animation: zoominout 15s alternate infinite ease-in; */
}
.home-page-7 .banner-section .decoration-items .dark-box-1 {
	top: 37%;
	left: 16%;
}
.home-page-7 .banner-section .decoration-items .dark-box-2 {
	top: 31%;
	left: 13%;
}
.home-page-7 .banner-section .decoration-items .dark-box-3 {
	top: 215px;
	right: 260px;
}
.home-page-7 .banner-section .decoration-items .dark-box-4 {
	bottom: 0px;
	right: 170px;
}

/* banner-section - end */
/* client-section - start */
.home-page-7 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -30px;
}

/* client-section - end */
/* features-section - start */
.features-section .feature-item .feature-image-7 {
	position: relative;
}
.features-section .feature-item .feature-image-7 .bg-image {
	width: 530px;
	right: -260px;
	position: relative;
}
.features-section .feature-item .feature-image-7 .big-chart {
	top: 80px;
	width: 600px;
	right: -150px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .circle-chart-1 {
	width: 40px;
	right: 135px;
	bottom: 60px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .circle-chart-2 {
	width: 40px;
	right: 90px;
	bottom: 60px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .circle-chart-3 {
	width: 40px;
	right: 45px;
	bottom: 60px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .man-image-1 {
	left: 160px;
	width: 160px;
	bottom: 65px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .man-image-2 {
	left: -25px;
	bottom: 65px;
	width: 205px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .man-image-3 {
	z-index: 1;
	bottom: 60px;
	width: 165px;
	right: -140px;
	position: absolute;
}
.features-section .feature-item .feature-image-7 .leaf-image {
	width: 75px;
	bottom: 60px;
	right: -175px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
	width: 512px;
	right: unset;
	left: -245px;
	position: relative;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
	top: 108px;
	width: 540px;
	left: -170px;
	right: unset;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
	top: 0px;
	left: 0px;
	width: 312px;
	bottom: unset;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
	left: -230px;
	bottom: 30px;
	width: 195px;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
	right: 130px;
	bottom: 30px;
	width: 360px;
}

.features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
	right: 75px;
	z-index: -1;
	bottom: 30px;
	width: 220px;
}

/* features-section - end */
/* software-section - start */
.software-section {
	overflow: hidden;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.software-section .shape-img-1,
.software-section .shape-img-2 {
	width: 579px;
	position: absolute;
}
.software-section .shape-img-1 {
	top: 0px;
	left: 0px;
}
.software-section .shape-img-2 {
	right: 0px;
	bottom: 0px;
}
.software-section .section-title {
	color: #ffffff;
}
.software-section .section-title .title-text {
	color: #ffffff;
}
.software-section .software-container {
	width: 100%;
	height: 460px;
	display: table;
	position: relative;
}
.software-section .software-container .software-item {
	z-index: 1;
	width: 134px;
	height: 134px;
	position: absolute;
}
.software-section .software-container .software-item:before {
	top: 50%;
	left: 50%;
	width: 90%;
	height: 90%;
	opacity: 0;
	content: '';
	z-index: -1;
	position: absolute;
	border-radius: 100%;
	border: 2px solid #138afd;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.software-section .software-container .software-item:hover:before {
	opacity: 1;
	width: 130%;
	height: 130%;
}
.software-section .software-container .software-item .software-logo {
	width: 100%;
	height: 100%;
	padding: 35px;
	display: block;
	overflow: hidden;
	border-radius: 100%;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}
.software-section .software-container .software-item:nth-child(1) {
	top: 50%;
	left: 50%;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.software-section .software-container .software-item:nth-child(2) {
	top: 80px;
	left: -26px;
	width: 100px;
	height: 100px;
}
.software-section
	.software-container
	.software-item:nth-child(2)
	.software-logo {
	padding: 30px;
}
.software-section .software-container .software-item:nth-child(3) {
	left: 100px;
	bottom: 50px;
	width: 130px;
	height: 130px;
}
.software-section .software-container .software-item:nth-child(4) {
	top: 0px;
	left: 245px;
	width: 75px;
	height: 75px;
}
.software-section
	.software-container
	.software-item:nth-child(4)
	.software-logo {
	padding: 20px;
}
.software-section .software-container .software-item:nth-child(5) {
	bottom: 0px;
	left: 380px;
	width: 88px;
	height: 88px;
}
.software-section
	.software-container
	.software-item:nth-child(5)
	.software-logo {
	padding: 25px;
}
.software-section .software-container .software-item:nth-child(6) {
	top: 30px;
	right: 100px;
	width: 110px;
	height: 110px;
}
.software-section
	.software-container
	.software-item:nth-child(6)
	.software-logo {
	padding: 20px;
}
.software-section .software-container .software-item:nth-child(7) {
	bottom: 60px;
	right: -57px;
	width: 112px;
	height: 112px;
}
.software-section
	.software-container
	.software-item:nth-child(7)
	.software-logo {
	padding: 25px;
}

/* software-section - end */
/* software-section - start */
.pricing-section .bg-image {
	position: relative;
}
.pricing-section .bg-image .bg-shape-1 {
	left: 0px;
	right: 0px;
	bottom: 0px;
	width: 100%;
	position: absolute;
}

/* software-section - end */
/* free-trail-section - start */
.free-trail-section .section-title .title-text span small {
	color: #138afd;
	font-weight: 700;
	font-family: 'Inter', sans-serif;
}

.free-trail-section .free-trail-form {
	overflow: hidden;
	border-radius: 5px;
	padding: 50px 170px;
	background-repeat: no-repeat;
	background-position: center center;
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}
.free-trail-section .free-trail-form .form-item {
	margin-bottom: 15px;
}
.free-trail-section .free-trail-form .form-item input {
	width: 100%;
	height: 70px;
	display: table;
	padding: 0px 30px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.free-trail-section .free-trail-form .form-item input:focus {
	border-color: #138afd;
}
.free-trail-section .free-trail-form .form-item .form-item-btn {
	top: 50%;
	right: 10px;
	width: auto;
	height: 50px;
	line-height: 50px;
	padding: 0px 40px;
	color: #ffffff;
	text-align: center;
	border-radius: 3px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.free-trail-section .free-trail-form p {
	font-weight: 500;
}

/* free-trail-section - end */
/* 8.1 - home page 7 - end
================================================== */
/* 8.2 - home page 8 - start
================================================== */
/* banner-section - start */
.banner-section .sass-banner-2 {
	overflow: hidden;
	padding-top: 195px;
	padding-bottom: 65px;
}
.banner-section .sass-banner-2 .banner-content {
	padding-top: 150px;
}
.banner-section .sass-banner-2 .banner-content .title-text {
	font-size: 36px;
	line-height: 48px;
}
.banner-section .sass-banner-2 .banner-content p {
	font-size: 16px;
	max-width: 400px;
	margin-bottom: 50px;
}
.banner-section .sass-banner-2 .banner-content .newsletter-form .form-item {
	width: 430px;
	margin-bottom: 20px;
}
.banner-section
	.sass-banner-2
	.banner-content
	.newsletter-form
	.form-item
	input {
	height: 60px;
}
.banner-section
	.sass-banner-2
	.banner-content
	.newsletter-form
	.form-item
	.submit-btn {
	top: 5px;
	right: 5px;
	z-index: 1;
	height: 50px;
	padding: 0px 40px;
	line-height: 50px;
	color: #ffffff;
	position: absolute;
	text-align: center;
	border-radius: 3px;
}
.banner-section .sass-banner-2 .banner-content .newsletter-form p {
	font-size: 14px;
	font-weight: 500;
}
.banner-section .sass-banner-2 .banner-item-image {
	position: relative;
}
.banner-section .sass-banner-2 .banner-item-image .bg-image {
	width: 760px;
	margin-left: -16px;
	margin-right: -170px;
}
.banner-section .sass-banner-2 .banner-item-image .big-image {
	z-index: 1;
	bottom: 60px;
	width: 810px;
	right: -190px;
	position: absolute;
	top: unset !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .man-image-1 {
	z-index: 1;
	width: 150px;
	bottom: 61px;
	position: absolute;
	top: unset !important;
	left: -60px !important;
}
.banner-section .sass-banner-2 .banner-item-image .man-image-2 {
	z-index: 1;
	right: -90px;
	width: 295px;
	bottom: 61px;
	position: absolute;
	top: unset !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .man-image-3 {
	z-index: 1;
	right: 5px;
	width: 165px;
	position: absolute;
	top: 65px !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .man-image-4 {
	z-index: 1;
	width: 160px;
	position: absolute;
	top: 54px !important;
	left: 170px !important;
}
.banner-section .sass-banner-2 .banner-item-image .man-image-5 {
	z-index: 1;
	width: 130px;
	bottom: 61px;
	position: absolute;
	top: unset !important;
	left: 200px !important;
}
.banner-section .sass-banner-2 .banner-item-image .leaf-image-1 {
	width: 230px;
	bottom: 63px;
	right: -180px;
	position: absolute;
	top: unset !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .leaf-image-2 {
	z-index: 0;
	width: 170px;
	bottom: 190px;
	position: absolute;
	left: 90px !important;
	top: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .comment-image {
	z-index: 1;
	width: 125px;
	position: absolute;
	top: 220px !important;
	left: 20px !important;
}
.banner-section .sass-banner-2 .banner-item-image .share-image {
	right: 60px;
	width: 75px;
	position: absolute;
	top: 0px !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-1 {
	z-index: 1;
	width: 170px;
	position: absolute;
	top: 40px !important;
	left: 40px !important;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-2,
.banner-section .sass-banner-2 .banner-item-image .cloud-image-3 {
	z-index: 1;
	width: 95px;
	position: absolute;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-2 {
	right: 166px;
	top: 40px !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-3 {
	right: -80px;
	top: 120px !important;
	left: unset !important;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-4,
.banner-section .sass-banner-2 .banner-item-image .cloud-image-5 {
	z-index: 1;
	width: 65px;
	position: absolute;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-4 {
	top: 180px !important;
	left: -25px !important;
}
.banner-section .sass-banner-2 .banner-item-image .cloud-image-5 {
	right: -120px;
	top: 210px !important;
	left: unset !important;
}

/* banner-section - end */
/* client-section - start */
.home-page-8 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -20px;
}

/* client-section - end */
/* software-section - start */
.home-page-8 .software-section .section-title .title-text {
	color: #272d3a;
}

.home-page-8 .software-section .section-title .paragraph-text {
	color: #535e72;
}

/* software-section - end */
/* features-section - start */
.home-page-8 .features-section .feature-item:nth-child(1) {
	margin-bottom: 250px;
}

.home-page-8 .features-section .feature-item:nth-child(2) {
	margin-bottom: 265px;
}

.features-section .feature-item .feature-image-8 {
	position: relative;
}
.features-section .feature-item .feature-image-8 .image-1 {
	width: 838px;
	margin-right: -368px;
}
.features-section .feature-item .feature-image-8 .image-1 img {
	width: 100%;
	display: block;
}
.features-section .feature-item .feature-image-8 .image-2 {
	left: 30px;
	top: -125px;
	position: absolute;
}
.features-section .feature-item .feature-image-8 .image-3 {
	right: 0px;
	bottom: -183px;
	position: absolute;
}

.features-section .feature-item:nth-child(3) .feature-image-8 {
	position: relative;
}
.features-section .feature-item:nth-child(3) .feature-image-8 .image-1 {
	width: 838px;
	margin-left: -368px;
}
.features-section .feature-item:nth-child(3) .feature-image-8 .image-1 img {
	width: 100%;
	display: block;
}
.features-section .feature-item:nth-child(3) .feature-image-8 .image-2 {
	top: -65px;
	left: -65px;
	position: absolute;
}
.features-section .feature-item:nth-child(3) .feature-image-8 .image-3 {
	right: -65px;
	bottom: 35px;
	position: absolute;
}

/* features-section - end */
/* 8.2 - home page 8 - end
================================================== */
/* 8.3 - home page 9 - start
================================================== */
/* header-section - start */
.home-page-9 .sticky-header.stuck:before {
	background: -webkit-gradient(
		linear,
		left top,
		right top,
		from(#05b5fd),
		to(#75e6ca)
	);
	background: -webkit-linear-gradient(left, #05b5fd, #75e6ca);
	background: -o-linear-gradient(left, #05b5fd, #75e6ca);
	background: linear-gradient(to right, #05b5fd, #75e6ca);
}

/* header-section - end */
/* banner-section - start */
.banner-section .sass-banner-3 {
	width: 100%;
	height: auto;
	overflow: hidden;
	position: relative;
	padding-top: 315px;
	padding-bottom: 75px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center top;
}
.banner-section .sass-banner-3 .banner-content {
	color: #ffffff;
	padding-top: 80px;
}
.banner-section .sass-banner-3 .banner-content .title-text {
	font-size: 47px;
	line-height: 60px;
	color: #ffffff;
	text-transform: initial;
}
.banner-section .sass-banner-3 .banner-content p {
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 70px;
}
.banner-section .sass-banner-3 .banner-content .btns-group > ul > li {
	margin-right: 30px;
}
.banner-section
	.sass-banner-3
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section
	.sass-banner-3
	.banner-content
	.btns-group
	> ul
	> li
	.info-text {
	display: table;
	font-size: 16px;
	font-weight: 500;
}
.banner-section
	.sass-banner-3
	.banner-content
	.btns-group
	> ul
	> li
	.info-text
	span {
	width: 100%;
}
.banner-section .sass-banner-3 .banner-item-image {
	position: relative;
}
.banner-section .sass-banner-3 .banner-item-image .laptop-image {
	width: 930px;
	margin-left: -110px;
}
.banner-section .sass-banner-3 .banner-item-image .laptop-image img {
	width: 100%;
	display: block;
}
.banner-section .sass-banner-3 .banner-item-image .child-image-1 {
	left: 50px;
	top: -110px;
	position: absolute;
}
.banner-section .sass-banner-3 .banner-item-image .child-image-2 {
	bottom: 0px;
	right: -285px;
	position: absolute;
}
.banner-section .sass-banner-3 .shape-1 {
	top: 50%;
	left: 210px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-3 .shape-2 {
	top: 215px;
	left: 390px;
	position: absolute;
	-webkit-animation: zoominout 1s alternate infinite ease-in;
	animation: zoominout 1s alternate infinite ease-in;
}
.banner-section .sass-banner-3 .shape-3 {
	left: 480px;
	bottom: 105px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-3 .shape-4 {
	top: 265px;
	left: 650px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-3 .shape-5 {
	left: 920px;
	bottom: 270px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-3 .shape-6 {
	top: 195px;
	left: 1045px;
	position: absolute;
	-webkit-animation: zoominout 1s alternate infinite ease-in;
	animation: zoominout 1s alternate infinite ease-in;
}
.banner-section .sass-banner-3 .shape-7 {
	top: 215px;
	right: 305px;
	position: absolute;
	-webkit-animation: zoominout 1s alternate infinite ease-in;
	animation: zoominout 1s alternate infinite ease-in;
}

/* banner-section - end */
/* client-section - start */
.home-page-9 .client-section {
	z-index: 1;
	position: relative;
	margin-bottom: -20px;
}

/* client-section - end */
/* software-section - start */
.home-page-9 .software-section .section-title .title-text {
	color: #272d3a;
}

.home-page-9 .software-section .section-title .paragraph-text {
	color: #272d3a;
}

/* software-section - end */
/* 8.3 - home page 9 - end
================================================== */
/* 8.4 - home page 10 - start
================================================== */
/* banner-section - start */
.banner-section .sass-banner-4 {
	width: 100%;
	height: 100vh;
	overflow: hidden;
	position: relative;
	padding-top: 315px;
}
.banner-section .sass-banner-4 .banner-content {
	padding-top: 80px;
}
.banner-section .sass-banner-4 .banner-content .title-text {
	font-size: 47px;
	line-height: 60px;
	text-transform: initial;
}
.banner-section .sass-banner-4 .banner-content p {
	font-size: 16px;
	line-height: 28px;
	margin-bottom: 70px;
}
.banner-section .sass-banner-4 .banner-content .btns-group > ul > li {
	margin-right: 30px;
}
.banner-section
	.sass-banner-4
	.banner-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.banner-section
	.sass-banner-4
	.banner-content
	.btns-group
	> ul
	> li
	.custom-btn {
	background-color: #138afd;
}
.banner-section
	.sass-banner-4
	.banner-content
	.btns-group
	> ul
	> li
	.custom-btn:before {
	background-color: #6b45f3;
}
.banner-section
	.sass-banner-4
	.banner-content
	.btns-group
	> ul
	> li
	.info-text {
	display: table;
	font-size: 16px;
	padding: 13px 0px;
	font-weight: 500;
}
.banner-section .sass-banner-4 .banner-item-image {
	position: relative;
}
.banner-section .sass-banner-4 .banner-item-image .popup-video {
	top: 36%;
	left: 114%;
	z-index: 30;
	width: 95px;
	height: 95px;
	font-size: 24px;
	line-height: 95px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #6b45f3;
	background-color: #ffffff;
	-webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.banner-section .sass-banner-4 .banner-item-image .popup-video:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.banner-section .sass-banner-4 .banner-item-image .laptop-image {
	z-index: 2;
	width: 1000px;
	margin-left: 60px;
	position: relative;
}
.banner-section .sass-banner-4 .banner-item-image .laptop-image img {
	width: 100%;
	display: block;
}
.banner-section .sass-banner-4 .banner-item-image .bg-image {
	z-index: -1;
	top: -580px;
	right: -693px;
	width: 1100px;
	position: absolute;
}
.banner-section .sass-banner-4 .banner-item-image .shape-image {
	top: -70px;
	left: 90px;
	z-index: 1;
	position: absolute;
}
.banner-section .sass-banner-4 .shape-1 {
	top: 50%;
	left: 210px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-4 .shape-2 {
	top: 215px;
	left: 390px;
	position: absolute;
	-webkit-animation: zoominout 1s alternate infinite ease-in;
	animation: zoominout 1s alternate infinite ease-in;
}
.banner-section .sass-banner-4 .shape-3 {
	left: 480px;
	bottom: 105px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-4 .shape-4 {
	top: 265px;
	left: 650px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-4 .shape-5 {
	left: 920px;
	bottom: 270px;
	position: absolute;
	-webkit-animation: spin 5s infinite linear;
	animation: spin 5s infinite linear;
}
.banner-section .sass-banner-4 .shape-6 {
	top: 195px;
	left: 1045px;
	position: absolute;
	-webkit-animation: zoominout 1s alternate infinite ease-in;
	animation: zoominout 1s alternate infinite ease-in;
}

/* banner-section - end */
/* client-section - start */
.client-section .title-paragraph-text {
	display: table;
	font-size: 16px;
	max-width: 430px;
	text-align: center;
	margin: 0 auto 70px;
	font-weight: 500;
}

/* client-section - end */
/* service-section - start */
.home-page-10 .service-section .container {
	max-width: 1200px;
}

.home-page-10 .service-section .service-grid-item {
	border-color: #e3e8fe;
}
.home-page-10 .service-section .service-grid-item:hover {
	border-color: #ffffff;
}

/* service-section - end */
/* features-section - start */
.features-section .feature-item .feature-image-10 {
	position: relative;
}
.features-section .feature-item .feature-image-10 .big-image {
	z-index: 1;
	width: 580px;
	position: relative;
	margin-left: -135px;
}
.features-section .feature-item .feature-image-10 .small-image-1 {
	top: 76px;
	z-index: 2;
	left: -205px;
	position: absolute;
}
.features-section .feature-item .feature-image-10 .small-image-2 {
	z-index: 2;
	right: -20px;
	bottom: -75px;
	position: absolute;
}
.features-section .feature-item .feature-image-10 .shape-1 {
	z-index: 2;
	top: -33px;
	left: -67px;
	position: absolute;
}
.features-section .feature-item .feature-image-10 .shape-2 {
	left: 0px;
	bottom: 0px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-10 .big-image {
	margin-left: 0px;
	margin-right: -135px;
}

.features-section .feature-item:nth-child(2) .feature-image-10 .small-image-1 {
	top: 42px;
	z-index: 2;
	left: 60px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-10 .shape-2 {
	z-index: 2;
	left: 190px;
	bottom: 10px;
	position: absolute;
}

.features-section .feature-item:nth-child(2) .feature-image-10 .shape-3 {
	top: 56%;
	z-index: 2;
	width: 50px;
	right: -97px;
	position: absolute;
}

/* features-section - end */
/* 8.4 - home page 10 - end
================================================== */
/* 8 - sass landing -->> home page - end
==================================================================================================== */
/* 9 - app store -->> home page - end
==================================================================================================== */
/* 9.1 - home page 11 - start
================================================== */
/* header-section - start */
.header-section .btns-group > ul > li {
	position: relative;
	/* main-search-body - start */
	/* main-search-body - end */
}
.header-section .btns-group > ul > li .search-btn {
	float: left;
	width: 50px;
	height: 50px;
	font-size: 18px;
	line-height: 60px;
	text-align: center;
	margin-right: 30px;
	color: #272d3a;
}
.header-section .btns-group > ul > li .search-btn:hover {
	color: #6b45f3;
}
.header-section .btns-group > ul > li .main-search-body {
	top: 50px;
	right: 14px;
	opacity: 0;
	width: 300px;
	padding: 10px;
	border-radius: 5px;
	position: absolute;
	visibility: hidden;
	border-top-right-radius: 0px;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.header-section .btns-group > ul > li .main-search-body .search-input {
	width: 100%;
	margin: 0px;
	height: 50px;
	padding: 0px 20px;
	border-radius: 5px;
	padding-right: 40px;
	border: 1px solid #f4f2ff;
	border-top-right-radius: 0px;
	background-color: #f7f9fe;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.header-section .btns-group > ul > li .main-search-body .search-input:focus {
	outline: none;
	border-color: #6b45f3;
	background-color: #ffffff;
}
.header-section .btns-group > ul > li .main-search-body .search-close {
	top: 50%;
	z-index: 1;
	width: auto;
	right: 31px;
	height: auto;
	line-height: 1;
	font-size: 14px;
	margin-right: 0px;
	position: absolute;
	color: #535e72;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.header-section .btns-group > ul > li .main-search-body .search-close:hover {
	color: #6b45f3;
}
.header-section .btns-group > ul > li .main-search-body.search-open {
	opacity: 1;
	visibility: visible;
}

/* header-section - end */
/* slider-section - start */
.home-page-11 .slider-section {
	height: auto;
}

.slider-section .appstore-main-carousel {
	padding-top: 186px;
	padding-bottom: 30px;
	background-color: #f7f9fe;
}
.slider-section .appstore-main-carousel .item .item-content {
	padding-top: 150px;
}
.slider-section .appstore-main-carousel .item .item-content h1 {
	font-size: 48px;
	line-height: 60px;
	margin-bottom: 40px;
}
.slider-section .appstore-main-carousel .item .item-content p {
	font-size: 16px;
	max-width: 600px;
	line-height: 28px;
	margin-bottom: 50px;
}
.slider-section
	.appstore-main-carousel
	.item
	.item-content
	.btns-group
	> ul
	> li {
	margin-right: 25px;
}
.slider-section
	.appstore-main-carousel
	.item
	.item-content
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.slider-section
	.appstore-main-carousel
	.item
	.item-content
	.btns-group
	> ul
	> li
	.store-btn {
	padding: 15px 20px;
}
.slider-section .appstore-main-carousel .item .item-image {
	padding-top: 85px;
	position: relative;
	padding-bottom: 100px;
}
.slider-section .appstore-main-carousel .item .item-image .bg-image {
	z-index: 0;
	width: 416px;
	position: relative;
	margin-right: -72px;
}
.slider-section .appstore-main-carousel .item .item-image .phone-image-1 {
	right: 0px;
	z-index: 1;
	bottom: 10px;
	width: 295px;
	position: absolute;
}
.slider-section .appstore-main-carousel .item .item-image .man-image-1 {
	z-index: 1;
	bottom: 0px;
	width: 185px;
	right: -110px;
	position: absolute;
}
.slider-section .appstore-main-carousel .item .item-image .man-image-2 {
	left: 0px;
	z-index: 1;
	bottom: 0px;
	width: 140px;
	position: absolute;
}
.slider-section .appstore-main-carousel .item .item-image .comment-image {
	top: 35%;
	left: 44%;
	z-index: 1;
	width: 120px;
	position: absolute;
}
.slider-section .appstore-main-carousel .item .item-image .leaf-image-1 {
	top: 0px;
	left: 10px;
	width: 180px;
	position: absolute;
}
.slider-section .appstore-main-carousel .item .item-image .leaf-image-2 {
	top: 46px;
	width: 215px;
	right: -110px;
	position: absolute;
	-webkit-transform: rotate(-30deg);
	-ms-transform: rotate(-30deg);
	transform: rotate(-30deg);
}
.slider-section .appstore-main-carousel .item .item-image .phone-image-2 {
	width: 390px;
	right: -70px;
	bottom: 65px;
	position: absolute;
}

.home-page-11 .slider-section .owl-theme .owl-nav .owl-prev,
.home-page-11 .slider-section .owl-theme .owl-nav .owl-next {
	top: 50%;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

.home-page-11 .slider-section .owl-theme .owl-nav .owl-prev {
	left: 40px;
}

.home-page-11 .slider-section .owl-theme .owl-nav .owl-next {
	right: 40px;
}

/* slider-section - end */
/* mobile-app-section - start */
.mobile-app-section .section-title .title-text {
	font-size: 36px;
	font-weight: 500;
}

.mobile-app-section .section-title .custom-btn-underline {
	margin: 12px 0px;
}

/* mobile-app-section - end */
/* app-Category-section - start */
.app-Category-section .Category-item {
	margin-top: 40px;
}
.app-Category-section .Category-item .item-title {
	font-size: 21px;
	margin-bottom: 50px;
}
.app-Category-section .Category-item > ul > li {
	margin-bottom: 40px;
}
.app-Category-section .Category-item > ul > li:last-child {
	margin-bottom: 0px;
}
.app-Category-section .Category-item > ul > li > a {
	font-size: 16px;
	color: #535e72;
	font-weight: 500;
}
.app-Category-section .Category-item > ul > li > a:hover {
	color: #138afd;
	text-decoration: underline;
}

/* app-Category-section - end */
/* 9.1 - home page 11 - end
================================================== */
/* 9.2 - home page 12 - start
================================================== */
/* header-section - start */
.home-page-12,
.app-details-page-2 {
	padding-top: 156px;
}
.home-page-12 .header-section.black-content,
.app-details-page-2 .header-section.black-content {
	background-color: #ffffff;
}

/* header-section - end */
/* search-section - start */
.search-section {
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.search-section .overlay-color {
	padding: 240px 0px;
	background-image: -webkit-gradient(
		linear,
		left top,
		right top,
		from(rgba(0, 57, 113, 0.9)),
		to(rgba(99, 5, 134, 0.9))
	);
	background-image: -webkit-linear-gradient(
		left,
		rgba(0, 57, 113, 0.9),
		rgba(99, 5, 134, 0.9)
	);
	background-image: -o-linear-gradient(
		left,
		rgba(0, 57, 113, 0.9),
		rgba(99, 5, 134, 0.9)
	);
	background-image: linear-gradient(
		to right,
		rgba(0, 57, 113, 0.9),
		rgba(99, 5, 134, 0.9)
	);
}
.search-section .banner-content h1 {
	font-size: 48px;
	color: #ffffff;
	margin-bottom: 70px;
}
.search-section .banner-content .search-form .form-item {
	position: relative;
}
.search-section .banner-content .search-form .form-item input {
	height: 70px;
	padding: 0px 35px;
}
.search-section .banner-content .search-form .form-item .search-btn {
	top: 50%;
	right: 35px;
	font-size: 24px;
	position: absolute;
	color: #138afd;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}

/* search-section - end */
/* mobile-app-section - start */
.mobile-app-section .app-nav .nav-title {
	font-size: 16px;
	padding: 20px 40px;
	color: #ffffff;
	font-weight: 500;
	background-color: #138afd;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
}

.mobile-app-section .app-nav > ul {
	border-top: none;
	padding: 25px 0px;
	border: 1px solid #e3e8fe;
	border-bottom-left-radius: 3px;
	border-bottom-right-radius: 3px;
}
.mobile-app-section .app-nav > ul > li > a {
	font-size: 16px;
	position: relative;
	padding: 15px 40px;
	color: #535e72;
	font-weight: 500;
}
.mobile-app-section .app-nav > ul > li > a:before {
	top: 0px;
	left: -1px;
	opacity: 0;
	width: 3px;
	bottom: 0px;
	content: '';
	position: absolute;
	background-color: #138afd;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.mobile-app-section .app-nav > ul > li > a:hover {
	color: #138afd;
}
.mobile-app-section .app-nav > ul > li > a:hover:before {
	opacity: 1;
}
.mobile-app-section .app-nav > ul > li > .active {
	color: #138afd;
}
.mobile-app-section .app-nav > ul > li > .active:before {
	opacity: 1;
}

.mobile-app-section .tab-content .pagination-nav {
	margin-top: 50px;
}

.mobile-app-section .tab-content .tab-pane .top-content-bar {
	padding: 20px 0px;
	margin-bottom: 50px;
}
.mobile-app-section .tab-content .tab-pane .top-content-bar .tab-pane-title {
	font-size: 24px;
}
.mobile-app-section
	.tab-content
	.tab-pane
	.top-content-bar
	.option-form
	.form-control {
	height: 60px;
	font-size: 18px;
	padding: 0px 20px;
	margin: -16px 0px;
	border-radius: 3px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
	border: 1px solid #e3e8fe;
}

.mobile-app-section .tab-content .tab-pane .app-item {
	width: 100%;
	margin: 0px;
	margin-bottom: 50px;
}

/* mobile-app-section - end */
/* 9.2 - home page 12 - end
================================================== */
/* 9 - app store -->> home page - end
==================================================================================================== */
/* 10 - app page - start
==================================================================================================== */
/* 10.1 - app page content - start
================================================== */
.mobile-app-section .app-item {
	width: 185px;
	display: table;
	margin-top: 50px;
	margin-left: -8px;
	margin-right: -8px;
	border: 1px solid #e3e8fe;
}
.mobile-app-section .app-item .app-image {
	width: 100%;
	overflow: hidden;
	position: relative;
}
.mobile-app-section .app-item .app-image img {
	width: 100%;
	display: block;
}
.mobile-app-section .app-item .app-image .addtocart-btn {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	opacity: 0;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.75);
}
.mobile-app-section .app-item .app-image .addtocart-btn span {
	top: 50%;
	left: 50%;
	opacity: 0;
	height: 40px;
	display: table;
	font-size: 14px;
	min-width: 150px;
	line-height: 40px;
	padding: 0px 22px;
	color: #ffffff;
	position: absolute;
	border-radius: 3px;
	text-align: center;
	-webkit-transform: translate(-50%, 50%);
	-ms-transform: translate(-50%, 50%);
	transform: translate(-50%, 50%);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
	background-color: #6b45f3;
}
.mobile-app-section .app-item .app-content {
	margin: 0px;
	padding: 20px 18px;
}
.mobile-app-section .app-item .app-content .item-admin {
	font-size: 12px;
	line-height: 14px;
	margin-bottom: 10px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}
.mobile-app-section .app-item .app-content .item-admin .admin-link {
	color: #535e72;
}
.mobile-app-section .app-item .app-content .item-admin .admin-link:hover {
	color: #138afd;
}
.mobile-app-section .app-item .app-content .item-title {
	line-height: 14px;
}
.mobile-app-section .app-item .app-content .item-title .title-link {
	font-size: 14px;
	color: #272d3a;
	font-weight: 500;
}
.mobile-app-section .app-item .app-content .rating-star {
	margin-bottom: 20px;
}
.mobile-app-section .app-item .app-content .rating-star > ul {
	float: left;
}
.mobile-app-section .app-item .app-content .rating-star > ul > li {
	font-size: 12px;
	margin-right: 2px;
}
.mobile-app-section .app-item .app-content .rating-star > ul > li:last-child {
	margin-right: 0px;
}
.mobile-app-section .app-item .app-content .rating-star .review-text {
	font-size: 12px;
	margin-left: 5px;
	color: #272d3a;
}
.mobile-app-section .app-item .app-content .free-text,
.mobile-app-section .app-item .app-content .price-text {
	font-size: 14px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}
.mobile-app-section .app-item .app-content .price-text {
	color: #138afd;
}
.mobile-app-section .app-item:hover .app-image .addtocart-btn {
	opacity: 1;
}
.mobile-app-section .app-item:hover .app-image .addtocart-btn span {
	opacity: 1;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
}
.mobile-app-section .app-item:hover .app-content .item-title .title-link {
	color: #138afd;
	text-decoration: underline;
}

/* 10.1 - app page content - start
================================================== */
/* 10.2 - app details page - start
================================================== */
.app-details-page,
.app-details-page-2 {
	background-color: #f7f9fe;
}

/* app-details-section - start */
.app-details-section .app-details-content .app-item {
	padding: 50px;
	border-radius: 3px;
	position: relative;
	margin-bottom: 50px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.app-details-section .app-details-content .app-item .rating-star {
	top: 50px;
	right: 50px;
	position: absolute;
}
.app-details-section .app-details-content .app-item .app-image {
	float: left;
	width: 180px;
	height: 180px;
	overflow: hidden;
}
.app-details-section .app-details-content .app-item .app-content {
	display: table;
	padding: 15px 30px;
}
.app-details-section .app-details-content .app-item .app-content .item-admin {
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 10px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-admin
	.admin-link {
	color: #535e72;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-admin
	.admin-link:hover {
	color: #138afd;
}
.app-details-section .app-details-content .app-item .app-content .item-title {
	font-size: 24px;
	margin-bottom: 10px;
	font-weight: 500;
}
.app-details-section .app-details-content .app-item .app-content .item-tag {
	margin-bottom: 20px;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-tag
	> ul
	> li {
	margin-right: 15px;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-tag
	> ul
	> li:last-child {
	margin-right: 0px;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-tag
	> ul
	> li
	> a {
	font-size: 14px;
	color: #138afd;
	font-weight: 500;
}
.app-details-section
	.app-details-content
	.app-item
	.app-content
	.item-tag
	> ul
	> li
	> a:hover {
	text-decoration: underline;
}
.app-details-section .app-details-content .app-item .app-content .free-text {
	font-size: 24px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}
.app-details-section .app-details-content .app-item .btns-group {
	margin-top: 50px;
}
.app-details-section .app-details-content .app-item .btns-group > ul > li {
	margin-right: 30px;
}
.app-details-section
	.app-details-content
	.app-item
	.btns-group
	> ul
	> li:last-child {
	margin-right: 0px;
}
.app-details-section .app-details-content .app-item .btns-group > ul > li > a {
	height: 50px;
	font-size: 16px;
	padding: 0px 25px;
	line-height: 50px;
	border-radius: 3px;
	color: #ffffff;
	text-align: center;
	font-weight: 500;
}
.app-details-section
	.app-details-content
	.app-item
	.btns-group
	> ul
	> li
	.dropdown-menu {
	float: left;
	margin: 0px;
	border: none;
	font-size: 14px;
	min-width: 200px;
	padding: 15px 0px;
	border-radius: 5px;
	color: #535e72;
	-webkit-box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
	box-shadow: 7px 5px 30px 0px rgba(72, 73, 121, 0.15);
}
.app-details-section
	.app-details-content
	.app-item
	.btns-group
	> ul
	> li
	.dropdown-menu
	> .store-list
	> ul
	> li
	> a {
	width: 100%;
	display: block;
	padding: 10px 25px;
	color: #535e72;
}
.app-details-section
	.app-details-content
	.app-item
	.btns-group
	> ul
	> li
	.dropdown-menu
	> .store-list
	> ul
	> li
	> a:hover {
	color: #138afd;
	background-color: #f7f9fe;
}

.app-details-section .app-details-content .overall-review {
	padding: 50px;
	border-radius: 3px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.app-details-section .app-details-content .overall-review .overall-review-nav {
	margin-bottom: 50px;
	border-bottom: 2px solid #e3e8fe;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li {
	margin-right: 50px;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li:last-child {
	margin-right: 0px;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> a {
	padding: 0px;
	font-size: 18px;
	position: relative;
	padding-bottom: 35px;
	color: #535e72;
	font-weight: 500;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> a:before {
	left: 15px;
	right: 15px;
	height: 2px;
	bottom: -2px;
	opacity: 0;
	content: '';
	position: absolute;
	background-color: #138afd;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> a:hover {
	color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> a:hover:before {
	left: 0px;
	right: 0px;
	opacity: 1;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> .active {
	color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.overall-review-nav
	> ul
	> li
	> .active:before {
	left: 0px;
	right: 0px;
	opacity: 1;
}
.app-details-section
	.app-details-content
	.overall-review
	.write-review-btn
	> button {
	height: 50px;
	font-size: 16px;
	padding: 0px 35px;
	line-height: 50px;
	border-radius: 3px;
	color: #ffffff;
	font-weight: 500;
	background-color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	.pane-item-title {
	font-size: 24px;
	color: #272d3a;
	font-weight: 500;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	> p {
	font-size: 16px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	> p
	> a {
	color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	.info-list {
	max-width: 555px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	.info-list
	> ul
	> li {
	margin-bottom: 30px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	.info-list
	> ul
	> li:last-child {
	margin-bottom: 0px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	.info-list
	> ul
	> li
	.icon {
	float: left;
	margin-right: 30px;
	color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#about-pane
	.info-list
	> ul
	> li
	> p {
	display: table;
	font-size: 16px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	> p {
	font-size: 16px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	> p
	> a {
	color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	.info-list {
	max-width: 555px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	.info-list
	.title-text {
	font-size: 18px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	.info-list
	> ul
	> li {
	font-size: 16px;
	position: relative;
	padding-left: 15px;
	margin-bottom: 10px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	.info-list
	> ul
	> li:before {
	top: 50%;
	left: 0px;
	width: 4px;
	height: 4px;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #535e72;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#overview-pane
	.info-list
	> ul
	> li:last-child {
	margin-bottom: 0px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings {
	margin-bottom: 50px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li {
	margin-bottom: 15px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li:last-child {
	margin-bottom: 0px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li
	.rating-star {
	margin-right: 30px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li
	.single-experties {
	width: 270px;
	margin-right: 30px;
	position: relative;
	display: inline-block;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li
	.single-experties
	.progress {
	width: 100%;
	height: 5px;
	float: right;
	border-radius: 0;
	overflow: visible;
	background-color: #e3e8fe;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li
	.single-experties
	.progress
	.progress-bar {
	height: 5px;
	position: relative;
	background-color: #138afd;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.item-ratings
	> ul
	> li
	.review-text {
	font-size: 14px;
	color: #272d3a;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.customer-reviews
	.review-item {
	margin-bottom: 45px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.customer-reviews
	.review-item:last-child {
	margin-bottom: 0px;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.customer-reviews
	.review-item
	.customer-name {
	font-size: 21px;
	font-weight: 500;
}
.app-details-section
	.app-details-content
	.overall-review
	.tab-content
	#review-pane
	.customer-reviews
	.review-item
	.customer-name
	.comment-date {
	font-size: 14px;
	color: #535e72;
	font-weight: 400;
}

@-webkit-keyframes Rx-width-100 {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@keyframes Rx-width-100 {
	0% {
		width: 0%;
	}
	100% {
		width: 100%;
	}
}

@-webkit-keyframes Rx-width-80 {
	0% {
		width: 0%;
	}
	100% {
		width: 80%;
	}
}

@keyframes Rx-width-80 {
	0% {
		width: 0%;
	}
	100% {
		width: 80%;
	}
}

@-webkit-keyframes Rx-width-60 {
	0% {
		width: 0%;
	}
	100% {
		width: 60%;
	}
}

@keyframes Rx-width-60 {
	0% {
		width: 0%;
	}
	100% {
		width: 60%;
	}
}

@-webkit-keyframes Rx-width-40 {
	0% {
		width: 0%;
	}
	100% {
		width: 40%;
	}
}

@keyframes Rx-width-40 {
	0% {
		width: 0%;
	}
	100% {
		width: 40%;
	}
}

@-webkit-keyframes Rx-width-20 {
	0% {
		width: 0%;
	}
	100% {
		width: 20%;
	}
}

@keyframes Rx-width-20 {
	0% {
		width: 0%;
	}
	100% {
		width: 20%;
	}
}

.Rx-width-100 {
	-webkit-animation-name: Rx-width-100;
	animation-name: Rx-width-100;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.Rx-width-80 {
	-webkit-animation-name: Rx-width-80;
	animation-name: Rx-width-80;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.Rx-width-60 {
	-webkit-animation-name: Rx-width-60;
	animation-name: Rx-width-60;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.Rx-width-40 {
	-webkit-animation-name: Rx-width-40;
	animation-name: Rx-width-40;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

.Rx-width-20 {
	-webkit-animation-name: Rx-width-20;
	animation-name: Rx-width-20;
	-webkit-animation-fill-mode: forwards;
	animation-fill-mode: forwards;
}

/* app-details-section - end */
/* app-details-sidebar - start */
.sidebar-section.app-details-sidebar .widget {
	background-color: #ffffff;
}

.sidebar-section.app-details-sidebar .widget_title {
	font-size: 16px;
	padding-top: 30px;
	margin-top: -30px;
	color: #ffffff;
	border-bottom: none;
	border-top-left-radius: 3px;
	border-top-right-radius: 3px;
	background-color: #138afd;
}
.sidebar-section.app-details-sidebar .widget_title:before {
	display: none;
}

.sidebar-section.app-details-sidebar .widget_search {
	padding: 0px;
	border: none;
}
.sidebar-section.app-details-sidebar .widget_search .form-item input {
	background-color: #ffffff;
}
.sidebar-section.app-details-sidebar .widget_search .form-item .search-btn {
	top: 0px;
	right: 0px;
	z-index: 1;
	width: auto;
	height: 50px;
	font-size: 20px;
	padding: 0px 25px;
	line-height: 50px;
	position: absolute;
	color: #ffffff;
	text-align: center;
	background-color: #138afd;
	border-top-right-radius: 3px;
	border-bottom-right-radius: 3px;
}

.sidebar-section.app-details-sidebar .widget_categories > ul li > a:before {
	top: 0px;
	left: -31px;
	opacity: 0;
	width: 3px;
	bottom: 0px;
	height: 100%;
	right: unset;
}

.sidebar-section.app-details-sidebar
	.widget_categories
	> ul
	li:hover
	> a:before {
	opacity: 1;
}

/* app-details-sidebar - end */
/* 10.2 - app details page - end
================================================== */
/* 10 - app page - end
==================================================================================================== */
/* 11 - about page - start
==================================================================================================== */
.about-section .about-image {
	position: relative;
}
.about-section .about-image .popup-video {
	z-index: 1;
	right: 65px;
	width: 95px;
	height: 95px;
	bottom: 130px;
	font-size: 24px;
	line-height: 95px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #6b45f3;
	background-color: #ffffff;
	-webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.about-section .about-image .popup-video:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.about-section .about-image .item-image {
	width: 570px;
	margin-left: -50px;
}

.about-section .about-content {
	padding: 35px 0px;
}
.about-section .about-content > p {
	font-size: 18px;
}
.about-section .about-content .title-text {
	font-size: 36px;
	line-height: 42px;
	font-weight: 500;
	text-transform: initial;
}
.about-section .about-content .hero-content {
	margin-top: 20px;
}
.about-section .about-content .hero-content .hero-name {
	width: 100%;
	display: table;
	font-size: 24px;
	font-weight: 700;
	color: #272d3a;
}
.about-section .about-content .hero-content .hero-name .hero-title {
	font-size: 16px;
	color: #535e72;
	font-weight: 400;
}

/* 11 - about page - end
==================================================================================================== */
/* 12- blog - start
==================================================================================================== */
/* 12.1 - blog page - start
================================================== */
.blog-section {
	/* blog-grid-item - start */
	/* blog-grid-item - end */
	/* big-blog-item - start */
	/* big-blog-item - end */
}
.blog-section .pagination-nav {
	margin-top: 50px;
}
.blog-section .blog-carousel {
	position: relative;
}
.blog-section .blog-carousel:after,
.blog-section .blog-carousel:before {
	content: '';
	z-index: -1;
	position: absolute;
	background-repeat: no-repeat;
	background-position: center center;
}
.blog-section .big-blog-item,
.blog-section .blog-grid-item {
	overflow: hidden;
	border-radius: 3px;
	margin-bottom: 50px;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
}
.blog-section .big-blog-item .blog-image,
.blog-section .blog-grid-item .blog-image {
	overflow: hidden;
	position: relative;
}
.blog-section .big-blog-item .blog-image > img,
.blog-section .blog-grid-item .blog-image > img {
	width: 100%;
	display: block;
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.blog-section .big-blog-item .blog-image .post-date,
.blog-section .blog-grid-item .blog-image .post-date {
	left: 25px;
	bottom: 25px;
	padding: 10px 15px;
	text-align: center;
	border-radius: 3px;
	position: absolute;
	color: #138afd;
	border: 1px solid #e3e8fe;
	background-color: rgba(255, 255, 255, 0.85);
}
.blog-section .big-blog-item .blog-image .post-date .date-text,
.blog-section .blog-grid-item .blog-image .post-date .date-text {
	width: 100%;
	display: table;
	line-height: 0.8;
	font-size: 20px;
	margin-bottom: 8px;
}
.blog-section .big-blog-item .blog-image .post-date .month-text,
.blog-section .blog-grid-item .blog-image .post-date .month-text {
	width: 100%;
	display: table;
	line-height: 0.8;
}
.blog-section .big-blog-item .blog-content,
.blog-section .blog-grid-item .blog-content {
	width: 100%;
	display: table;
	padding: 30px 25px;
}
.blog-section .big-blog-item .blog-content .tag-link,
.blog-section .blog-grid-item .blog-content .tag-link {
	width: auto;
	height: 30px;
	font-size: 12px;
	line-height: 30px;
	padding: 0px 15px;
	margin-bottom: 20px;
	color: #138afd;
	text-transform: uppercase;
	border: 1px solid #e3e8fe;
	background-color: #f7f9fe;
}
.blog-section .big-blog-item .blog-content .blog-title .title-link,
.blog-section .blog-grid-item .blog-content .blog-title .title-link {
	font-size: 20px;
	line-height: 30px;
	color: #272d3a;
	font-weight: 700;
}
.blog-section .big-blog-item:hover .blog-image > img,
.blog-section .blog-grid-item:hover .blog-image > img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}
.blog-section .big-blog-item:hover .blog-content .blog-title .title-link,
.blog-section .blog-grid-item:hover .blog-content .blog-title .title-link {
	color: #138afd;
}
.blog-section .big-blog-item .blog-image .post-date {
	left: 50px;
	bottom: 50px;
}
.blog-section .big-blog-item .blog-content {
	padding: 50px;
}
.blog-section .big-blog-item .blog-content .post-tags > ul > li {
	margin-right: 20px;
}
.blog-section .big-blog-item .blog-content .post-tags > ul > li:last-child {
	margin-right: 0px;
}
.blog-section .big-blog-item .blog-content .blog-title .title-link {
	font-size: 30px;
	line-height: 36px;
}
.blog-section .big-blog-item .blog-content .item-paragraph {
	font-size: 16px;
}

/* 12.1 - blog page - start
================================================== */
/* 12.2 - blog details page - start
================================================== */
.blog-details-page-1 .breadcrumb-section {
	padding: 0px;
}
.blog-details-page-1 .breadcrumb-section .overlay-black {
	width: 100%;
	height: 650px;
	background-color: rgba(0, 0, 0, 0);
}

.blog-details-page-1 .blog-details-section {
	position: relative;
}
.blog-details-page-1 .blog-details-section .blog-details-content {
	z-index: 1;
	padding: 50px;
	position: relative;
	border-radius: 3px;
	margin-top: -430px;
	box-shadow: rgb(0 0 0 / 20%) 0px 0px 20px;
	background-color: #ffffff;
}

.blog-details-section .blog-details-content > p {
	font-size: 16px;
}
.blog-details-section .blog-details-content > p > a {
	color: #138afd;
}

.blog-details-section .blog-details-content .blog-title {
	font-size: 36px;
	line-height: 48px;
}

.blog-details-section .blog-details-content .title-text {
	font-size: 21px;
}

.blog-details-section .blog-details-content .post-meta > ul > li > .post-admin {
	padding: 3px 0px;
	font-weight: 700;
	color: #272d3a;
}
.blog-details-section
	.blog-details-content
	.post-meta
	> ul
	> li
	> .post-admin
	.admin-image {
	float: left;
	width: 30px;
	height: 30px;
	overflow: hidden;
	margin: -6px 0px;
	margin-right: 10px;
	border: 1px solid #e3e8fe;
}

.blog-details-section .blog-details-content .blockquote {
	margin: 50px 30px;
	padding: 30px 40px;
	border-left: 6px solid #138afd;
}
.blog-details-section .blog-details-content .blockquote > p {
	font-size: 18px;
	line-height: 30px;
	color: #272d3a;
}

.blog-details-section .blog-details-content .video-item {
	width: 620px;
	overflow: hidden;
	margin: 50px auto;
	position: relative;
}
.blog-details-section .blog-details-content .video-item .overlay {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	position: absolute;
	background-color: rgba(0, 0, 0, 0.5);
}
.blog-details-section .blog-details-content .video-item .popup-video {
	top: 50%;
	left: 50%;
	z-index: 1;
	width: 65px;
	height: 65px;
	font-size: 24px;
	line-height: 65px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #6b45f3;
	background-color: #ffffff;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
	animation: pulse 2s infinite cubic-bezier(0.66, 0, 0, 1);
}
.blog-details-section .blog-details-content .video-item .popup-video:before {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: -1;
	content: '';
	position: absolute;
	border-radius: 100%;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}

.blog-details-section .blog-details-content .features-list > ul {
	width: 100%;
}
.blog-details-section .blog-details-content .features-list > ul > li {
	margin-bottom: 10px;
}
.blog-details-section
	.blog-details-content
	.features-list
	> ul
	> li:last-child {
	margin-bottom: 0px;
}
.blog-details-section .blog-details-content .features-list > ul > li .icon {
	float: left;
	font-size: 18px;
	margin-right: 30px;
	color: #138afd;
}
.blog-details-section
	.blog-details-content
	.features-list
	> ul
	> li
	.info-text {
	display: table;
	font-size: 16px;
	line-height: 26px;
}

.blog-details-section .blog-details-content .post-admin {
	padding: 30px 60px;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	background-position: center center;
}
.blog-details-section .blog-details-content .post-admin .admin-image {
	float: left;
	width: 70px;
	height: 70px;
	overflow: hidden;
	margin-right: 20px;
	text-align: center;
	border: 2px solid #e3e8fe;
}
.blog-details-section .blog-details-content .post-admin .admin-content {
	display: table;
}
.blog-details-section .blog-details-content .post-admin .admin-content > p {
	color: #2b2b3e;
	font-size: 18px;
	line-height: 30px;
}
.blog-details-section
	.blog-details-content
	.post-admin
	.admin-content
	.admin-name {
	width: 100%;
	display: table;
	font-size: 16px;
	position: relative;
	padding-left: 25px;
	color: #272d3a;
	font-weight: 500;
}
.blog-details-section
	.blog-details-content
	.post-admin
	.admin-content
	.admin-name:before {
	top: 50%;
	left: 0px;
	width: 15px;
	height: 2px;
	content: '';
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	background-color: #272d3a;
}

.blog-details-section .blog-details-content .tag-share-links .title-text {
	float: left;
	font-size: 16px;
	padding: 5px 0px;
	margin-right: 20px;
	color: #272d3a;
	font-weight: 500;
	text-transform: capitalize;
}

.blog-details-section
	.blog-details-content
	.tag-share-links
	.tag-links
	> ul
	> li {
	margin-right: 15px;
}
.blog-details-section
	.blog-details-content
	.tag-share-links
	.tag-links
	> ul
	> li:last-child {
	margin-right: 0px;
}
.blog-details-section
	.blog-details-content
	.tag-share-links
	.tag-links
	> ul
	> li
	> a {
	width: auto;
	height: 30px;
	font-size: 12px;
	line-height: 30px;
	padding: 0px 15px;
	color: #138afd;
	text-transform: uppercase;
	border: 1px solid #e3e8fe;
	background-color: #f7f9fe;
}

.blog-details-section
	.blog-details-content
	.tag-share-links
	.share-links
	> ul
	> li {
	margin-right: 5px;
}
.blog-details-section
	.blog-details-content
	.tag-share-links
	.share-links
	> ul
	> li:last-child {
	margin-right: 0px;
}
.blog-details-section
	.blog-details-content
	.tag-share-links
	.share-links
	> ul
	> li
	> a {
	width: 30px;
	height: 30px;
	font-size: 14px;
	line-height: 30px;
	text-align: center;
	color: #535e72;
}
.blog-details-section
	.blog-details-content
	.tag-share-links
	.share-links
	> ul
	> li
	> a:hover {
	color: #138afd;
}

.blog-details-section .blog-details-content .peoples-comments .title-text {
	font-size: 24px;
	font-weight: 700;
}

.blog-details-section .blog-details-content .peoples-comments .comment-item {
	margin-bottom: 30px;
	padding-bottom: 40px;
	border-bottom: 1px solid #e3e8fe;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item:last-child {
	margin-bottom: 0px;
	border-bottom: none;
	padding-bottom: 0px;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.hero-image {
	float: left;
	width: 70px;
	height: 70px;
	overflow: hidden;
	margin-right: 30px;
	border-radius: 100%;
	border: 2px solid #e3e8fe;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content {
	display: table;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content
	.hero-info {
	position: relative;
	margin-bottom: 20px;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content
	.hero-info
	.hero-name {
	font-size: 18px;
	font-weight: 700;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content
	.hero-info
	.reply-btn {
	top: 50%;
	right: 0px;
	position: absolute;
	color: #535e72;
	font-weight: 500;
	text-transform: uppercase;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content
	.hero-info
	.reply-btn:hover {
	color: #138afd;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-content
	.paragraph-text {
	font-size: 16px;
}
.blog-details-section
	.blog-details-content
	.peoples-comments
	.comment-item
	.comment-item {
	margin-top: 30px;
	padding-top: 40px;
	padding-left: 55px;
	border-top: 1px solid #e3e8fe;
}

.blog-details-section .blog-details-content .comment-form .title-text {
	font-size: 24px;
	font-weight: 700;
}

.ww {
	/* These are technically the same, but use both */
	overflow-wrap: break-word;
	word-wrap: break-word;

	-ms-word-break: break-all;
	/* This is the dangerous one in WebKit, as it breaks things wherever */
	word-break: break-all;
	/* Instead use this non-standard one: */
	word-break: break-word;

	/* Adds a hyphen where the word breaks, if supported (No Blink) */
	-ms-hyphens: auto;
	-moz-hyphens: auto;
	-webkit-hyphens: auto;
	hyphens: auto;
}

/* 12.2 - blog details page - end
================================================== */
/* 12- blog - end
==================================================================================================== */
/* 13 - team page - start
==================================================================================================== */
.team-page .team-section .section-title {
	margin-bottom: 50px;
}

.team-page .team-section .team-member-grid,
.team-page .team-section .team-member-bordered {
	margin-top: 50px;
}

.team-section {
	/* team-member-bordered - satrt */
	/* team-member-bordered - end */
	/* team-member-grid - start */
	/* team-member-grid - end */
}
.team-section .team-member-bordered {
	width: 270px;
	display: table;
	margin: 0 auto;
	border-radius: 2px;
	padding: 60px 30px;
	border: 1px solid #e0e6fe;
	background-color: #ffffff;
}
.team-section .team-member-bordered .member-name {
	color: #010101;
	font-size: 18px;
	font-weight: 700;
}
.team-section .team-member-bordered .member-title {
	text-transform: capitalize;
}
.team-section .team-member-bordered .member-image {
	z-index: 1;
	position: relative;
}
.team-section .team-member-bordered .member-image .animate-item {
	opacity: 0;
	z-index: -1;
	position: absolute;
	-webkit-transform: rotate(630deg);
	-ms-transform: rotate(630deg);
	transform: rotate(630deg);
	-webkit-transition: all 2.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	-o-transition: all 2.5s cubic-bezier(0.075, 0.82, 0.165, 1);
	transition: all 2.5s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.team-section .team-member-bordered .member-image .image-container {
	width: 170px;
	height: 170px;
	margin: 0px auto;
	border-radius: 100%;
	border: 1px solid #e7ecfe;
}
.team-section .team-member-bordered .member-image .image-container > img {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.8s ease-in-out;
	-o-transition: all 0.8s ease-in-out;
	transition: all 0.8s ease-in-out;
}
.team-section .team-member-bordered .member-image .image-container:before {
	top: 50%;
	left: 50%;
	z-index: 1;
	opacity: 0;
	content: '';
	width: 100%;
	height: 100%;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	-ms-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%);
	-webkit-transition: all 0.9s ease-in-out;
	-o-transition: all 0.9s ease-in-out;
	transition: all 0.9s ease-in-out;
	background-color: rgba(0, 0, 0, 0.7);
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	margin: 0px;
	padding: 0px 20px;
	text-align: center;
	position: absolute;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li {
	top: 100%;
	opacity: 0;
	position: absolute;
	display: inline-block;
	-webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li:nth-child(1) {
	left: 30px;
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li:nth-child(2) {
	left: 60px;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li:nth-child(3) {
	right: 60px;
	-webkit-transition-delay: 0.4s;
	-o-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li:nth-child(4) {
	right: 30px;
	-webkit-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transition-delay: 0.5s;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li
	a {
	font-size: 14px;
	color: #ffffff;
}
.team-section
	.team-member-bordered
	.member-image
	.image-container
	.member-social-links
	> li
	a:hover {
	color: #138afd;
}
.team-section .team-member-bordered .member-image .animate-item-1,
.team-section .team-member-bordered .member-image .animate-item-5,
.team-section .team-member-bordered .member-image .animate-item-6 {
	top: 50%;
	left: 50%;
}
.team-section .team-member-bordered .member-image .animate-item-2,
.team-section .team-member-bordered .member-image .animate-item-3 {
	top: 50%;
	right: 50%;
}
.team-section .team-member-bordered .member-image .animate-item-4 {
	right: 50%;
	bottom: 50%;
}
.team-section .team-member-bordered .member-image .animate-item-7 {
	left: 50%;
	bottom: 50%;
}
.team-section .team-member-bordered:hover .member-image .image-container > img {
	-webkit-transform: scale(1.1);
	-ms-transform: scale(1.1);
	transform: scale(1.1);
}
.team-section
	.team-member-bordered:hover
	.member-image
	.image-container:before {
	opacity: 1;
}
.team-section
	.team-member-bordered:hover
	.member-image
	.image-container
	.member-social-links
	> li {
	top: 50%;
	opacity: 1;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.team-section .team-member-bordered:hover .member-image .animate-item {
	opacity: 1;
	-webkit-transform: rotate(0deg);
	-ms-transform: rotate(0deg);
	transform: rotate(0deg);
}
.team-section .team-member-bordered:hover .member-image .animate-item-1 {
	left: 50%;
	top: -20px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-2 {
	top: -25px;
	right: 15px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-3 {
	top: 50px;
	right: 0px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-4 {
	right: 10px;
	bottom: 30px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-5 {
	top: -35px;
	left: 30px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-6 {
	top: 35px;
	left: 0px;
}
.team-section .team-member-bordered:hover .member-image .animate-item-7 {
	left: 10px;
	bottom: 20px;
}
.team-section .team-member-grid {
	width: 270px;
	display: table;
	margin: 0 auto;
	position: relative;
	border-radius: 2px;
	border: 1px solid #e0e6fe;
	background-color: #ffffff;
}
.team-section .team-member-grid .member-image:before {
	z-index: 1;
	top: -50%;
	right: -50%;
	opacity: 0;
	content: '';
	width: 50px;
	height: 50px;
	position: absolute;
	border-radius: 100%;
	background-color: rgba(0, 0, 0, 0.7);
	-webkit-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	-o-transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
	transition: all 600ms cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.team-section .team-member-grid .member-image > img {
	-webkit-transform: scale(1);
	-ms-transform: scale(1);
	transform: scale(1);
	-webkit-transition: all 0.8s ease-in-out;
	-o-transition: all 0.8s ease-in-out;
	transition: all 0.8s ease-in-out;
}
.team-section .team-member-grid .member-image .member-social-links {
	top: 0px;
	left: 0px;
	right: 0px;
	bottom: 0px;
	z-index: 1;
	margin: 0px;
	padding: 0px 20px;
	text-align: center;
	position: absolute;
}
.team-section .team-member-grid .member-image .member-social-links > li {
	top: 100%;
	opacity: 0;
	position: absolute;
	display: inline-block;
	-webkit-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	-o-transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
	transition: all 400ms cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.team-section
	.team-member-grid
	.member-image
	.member-social-links
	> li:nth-child(1) {
	left: 70px;
	-webkit-transition-delay: 0.2s;
	-o-transition-delay: 0.2s;
	transition-delay: 0.2s;
}
.team-section
	.team-member-grid
	.member-image
	.member-social-links
	> li:nth-child(2) {
	left: 106px;
	-webkit-transition-delay: 0.3s;
	-o-transition-delay: 0.3s;
	transition-delay: 0.3s;
}
.team-section
	.team-member-grid
	.member-image
	.member-social-links
	> li:nth-child(3) {
	right: 106px;
	-webkit-transition-delay: 0.4s;
	-o-transition-delay: 0.4s;
	transition-delay: 0.4s;
}
.team-section
	.team-member-grid
	.member-image
	.member-social-links
	> li:nth-child(4) {
	right: 70px;
	-webkit-transition-delay: 0.5s;
	-o-transition-delay: 0.5s;
	transition-delay: 0.5s;
}
.team-section .team-member-grid .member-image .member-social-links > li a {
	font-size: 14px;
	color: #ffffff;
}
.team-section
	.team-member-grid
	.member-image
	.member-social-links
	> li
	a:hover {
	color: #138afd;
}
.team-section .team-member-grid .member-info {
	left: 0px;
	right: 0px;
	z-index: 1;
	bottom: 0px;
	padding: 30px;
	position: absolute;
	background-color: #ffffff;
	border-top: 1px solid #e0e6fe;
}
.team-section .team-member-grid .member-info .member-name {
	color: #000000;
	font-size: 18px;
	margin-bottom: 5px;
}
.team-section .team-member-grid .member-info .member-title {
	text-transform: capitalize;
}
.team-section .team-member-grid:hover .member-image:before {
	opacity: 1;
	width: 200%;
	height: 200%;
}
.team-section .team-member-grid:hover .member-image .member-social-links > li {
	top: 40%;
	opacity: 1;
	-webkit-transform: translateY(-40%);
	-ms-transform: translateY(-40%);
	transform: translateY(-40%);
}

/* 13 - team page - end
==================================================================================================== */
/* 14 - pricing plan page - start
==================================================================================================== */
.pricing-section .bg-image {
	margin: 0 auto;
	max-width: 1690px;
	position: relative;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center center;
}
.pricing-section .bg-image .pricing-plan {
	margin-top: -200px;
}

.pricing-section .pricing-tab-nav {
	margin-bottom: 270px;
}
.pricing-section .pricing-tab-nav > ul {
	display: table;
	border-radius: 3px;
	background-color: #ffffff;
	border: 2px solid #138afd;
}
.pricing-section .pricing-tab-nav > ul > li {
	width: 50%;
	float: left;
	height: 40px;
	display: inline-block;
}
.pricing-section .pricing-tab-nav > ul > li > a {
	width: 100%;
	height: 100%;
	display: block;
	font-size: 16px;
	padding: 0px 30px;
	line-height: 40px;
	color: #535e72;
}
.pricing-section .pricing-tab-nav > ul > li > a:hover {
	color: #ffffff;
	background-color: #138afd;
}
.pricing-section .pricing-tab-nav > ul > li > .active {
	color: #ffffff;
	background-color: #138afd;
}

.pricing-section .pricing-plan {
	padding: 50px 30px;
	border-radius: 5px;
	position: relative;
	border: 1px solid #e3e8fe;
	background-color: #ffffff;
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.pricing-section .pricing-plan .offer-text {
	top: 45px;
	right: 0px;
	font-size: 14px;
	padding: 8px 10px;
	border-radius: 3px;
	position: absolute;
	color: #ffffff;
	background-color: #6b45f3;
}
.pricing-section .pricing-plan .offer-text:before {
	left: 0px;
	bottom: -10px;
	content: '';
	position: absolute;
	border-top: 10px solid transparent;
	border-left: 10px solid #6b45f3;
	border-right: 10px solid transparent;
	border-bottom: 10px solid transparent;
}
.pricing-section .pricing-plan .plan-title {
	font-size: 18px;
	color: #535e72;
}
.pricing-section .pricing-plan .item-icon {
	width: 120px;
	height: 115px;
	font-size: 54px;
	line-height: 115px;
	text-align: center;
	background-repeat: no-repeat;
	background-position: center center;
}
.pricing-section .pricing-plan .item-icon > i {
	background: -webkit-gradient(
		linear,
		left top,
		left bottom,
		from(#e93194),
		to(#701d94)
	);
	background: -webkit-linear-gradient(top, #e93194, #701d94);
	background: -o-linear-gradient(top, #e93194, #701d94);
	background: linear-gradient(to bottom, #e93194, #701d94);
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}
.pricing-section .pricing-plan .price-text {
	font-size: 36px;
	color: #138afd;
	font-weight: 700;
}
.pricing-section .pricing-plan .item-list {
	padding: 50px 0px;
}
.pricing-section .pricing-plan .item-list > ul > li {
	font-size: 16px;
	margin-bottom: 15px;
}
.pricing-section .pricing-plan .item-list > ul > li:last-child {
	margin-bottom: 0px;
}
.pricing-section .pricing-plan .start-btn {
	width: auto;
	height: 55px;
	font-size: 16px;
	padding: 0px 45px;
	line-height: 55px;
	border-radius: 3px;
	color: #535e72;
	background-color: #f7f9fe;
}
.pricing-section .pricing-plan:hover {
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}
.pricing-section .pricing-plan:hover .start-btn {
	color: #ffffff;
	background-color: #138afd;
}

.pricing-section .pricing-plan.active-item {
	-webkit-box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
	box-shadow: 0px 15px 43.65px 1.35px rgba(0, 27, 103, 0.1);
}
.pricing-section .pricing-plan.active-item .start-btn {
	color: #ffffff;
	background-color: #138afd;
}
.pricing-section .pricing-plan.active-item:hover .start-btn {
	background-color: #6b45f3;
}

.pricing-section .review-btn {
	margin-top: 70px;
}
.pricing-section .review-btn .custom-btn-underline {
	font-size: 18px;
	color: #ffffff;
}
.pricing-section .review-btn .custom-btn-underline:before {
	border-bottom: 2px solid #ffffff;
}

/* 14 - pricing plan page - end
==================================================================================================== */
/* 15 - testimonial / review page - start
==================================================================================================== */
.testimonial-section {
	/* testimonial-carousel - start */
	/* testimonial-carousel - end */
	/* review-item - start */
	/* review-item - end */
}
.testimonial-section .testimonial-carousel .item {
	padding-top: 45px;
	padding-left: 65px;
	padding-bottom: 45px;
}
.testimonial-section .testimonial-carousel .item .hero-image {
	z-index: 1;
	width: 35%;
	float: left;
	position: relative;
	padding-right: 40px;
	margin-bottom: -45px;
}
.testimonial-section .testimonial-carousel .item .hero-image .design-image {
	top: -45px;
	left: -65px;
	z-index: -1;
	width: 210px;
	position: absolute;
}
.testimonial-section .testimonial-carousel .item .hero-image .icon {
	top: 30px;
	right: 0px;
	width: 80px;
	height: 80px;
	font-size: 36px;
	line-height: 86px;
	position: absolute;
	text-align: center;
	border-radius: 100%;
	color: #138afd;
	background-color: #ffffff;
	-webkit-box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
	box-shadow: 0px 25px 57px 3px rgba(0, 27, 103, 0.15);
}
.testimonial-section .testimonial-carousel .item .testimonial-content {
	width: 65%;
	display: table;
	padding: 0px 70px;
	padding-top: 47px;
}
.testimonial-section
	.testimonial-carousel
	.item
	.testimonial-content
	.hero-info {
	position: relative;
	text-transform: capitalize;
}
.testimonial-section
	.testimonial-carousel
	.item
	.testimonial-content
	.hero-info
	.hero-name {
	font-size: 18px;
}
.testimonial-section
	.testimonial-carousel
	.item
	.testimonial-content
	.hero-info
	.rating-star {
	top: 0px;
	right: 0px;
	z-index: 1;
	position: absolute;
}
.testimonial-section
	.testimonial-carousel
	.item
	.testimonial-content
	.paragraph-text {
	font-size: 21px;
	line-height: 32px;
	font-style: italic;
	color: #272d3a;
	font-weight: 500;
}
.testimonial-section .testimonial-carousel .item.item-style-2 {
	padding: 0px;
	padding-bottom: 45px;
}
.testimonial-section .testimonial-carousel .item.item-style-2 .hero-image {
	margin: 0px;
	padding: 0px;
	padding-left: 15px;
}
.testimonial-section
	.testimonial-carousel
	.item.item-style-2
	.hero-image
	.design-image {
	left: 0px;
	top: 100px;
	width: 165px;
}
.testimonial-section
	.testimonial-carousel
	.item.item-style-2
	.hero-image
	.icon {
	top: 35px;
	right: 15px;
}
.testimonial-section
	.testimonial-carousel
	.item.item-style-2
	.hero-image
	.shape-image {
	left: 120px;
	bottom: 30px;
	position: absolute;
}
.testimonial-section .owl-theme .owl-nav {
	left: 44.55%;
	bottom: 0px;
	margin: 0px;
	display: table;
	position: absolute;
}
.testimonial-section .owl-theme .owl-nav .owl-prev {
	margin-right: 15px;
}
.testimonial-section .review-item {
	position: relative;
	margin-bottom: 70px;
}
.testimonial-section .review-item .reviewer-image {
	top: 50%;
	left: 0px;
	width: 130px;
	position: absolute;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
}
.testimonial-section .review-item .reviewer-content {
	padding-left: 180px;
}
.testimonial-section
	.review-item
	.reviewer-content
	.reviewer-info
	.reviewer-name {
	font-size: 18px;
	margin-bottom: 10px;
}
.testimonial-section .review-item .reviewer-content .reviewer-comment {
	font-size: 21px;
	line-height: 32px;
	font-style: italic;
	color: #272d3a;
	font-weight: 500;
}
.testimonial-section .load-more .load-more-btn {
	width: auto;
	height: 55px;
	font-size: 16px;
	line-height: 55px;
	padding: 0px 50px;
	text-align: center;
	border-radius: 3px;
	color: #272d3a;
	font-weight: 500;
	background-color: #f7f9fe;
}
.testimonial-section .load-more .load-more-btn:hover {
	color: #ffffff;
	background-color: #138afd;
}

/* 15 - testimonial / review page - end
==================================================================================================== */
/* 16 - contact pages - start
==================================================================================================== */
.contact-section .contact-info-item {
	padding: 50px 30px;
	border-radius: 2px;
	border: 1px solid #e3e8fe;
}
.contact-section .contact-info-item .item-icon {
	width: 105px;
	height: 105px;
	display: table;
	font-size: 36px;
	color: #ffffff;
	position: relative;
	text-align: center;
	margin: 0 auto 30px;
	background-repeat: no-repeat;
	background-position: center center;
}
.contact-section .contact-info-item .item-icon > i {
	top: 65%;
	left: 50%;
	position: absolute;
	-webkit-transform: translate(-50%, -65%);
	-ms-transform: translate(-50%, -65%);
	transform: translate(-50%, -65%);
}
.contact-section .contact-info-item > ul > li {
	color: #687596;
	font-size: 18px;
	margin-bottom: 10px;
	font-weight: 500;
}
.contact-section .contact-info-item > ul > li:last-child {
	margin-bottom: 0px;
}

.contact-section .contact-info-item.phone-item {
	border-color: #7a49ff;
}

.contact-section .contact-info-item.address-item {
	border-color: #31c8e9;
}

.contact-section .contact-info-item.email-item {
	border-color: #ff718e;
}

/* map-section - start */
.map-section {
	position: relative;
}
.map-section .google-map {
	height: 930px;
	position: relative;
}
.map-section .contact-form {
	z-index: 1;
	bottom: 0px;
	width: 512px;
	right: 366px;
	padding: 80px;
	position: absolute;
	background-color: #ffffff;
}
.map-section .contact-form .title-text {
	font-size: 30px;
}

/* map-section - end */
/* 16 - contact pages - end
==================================================================================================== */
/* 17 - others pages - start
==================================================================================================== */
.common-page .common-container {
	width: 50%;
	float: left;
	height: 100vh;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	position: relative;
}
.common-page .common-container .image-container {
	margin: auto;
	display: table;
}
.common-page .common-container .shape-1 {
	top: 47%;
	left: 60px;
	position: absolute;
	-webkit-animation: spin 4s infinite linear;
	animation: spin 4s infinite linear;
}
.common-page .common-container .shape-2 {
	top: 19%;
	left: 32%;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.common-page .common-container .shape-3 {
	top: 110px;
	right: 200px;
	position: absolute;
	-webkit-animation: spin 4s infinite linear;
	animation: spin 4s infinite linear;
}
.common-page .common-container .shape-4 {
	top: 30%;
	left: 68%;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.common-page .common-container .shape-5 {
	top: 29%;
	right: 30px;
	position: absolute;
	-webkit-animation: spin 4s infinite linear;
	animation: spin 4s infinite linear;
}
.common-page .common-container .shape-6 {
	top: 63%;
	left: 46%;
	position: absolute;
	-webkit-animation: spin 4s infinite linear;
	animation: spin 4s infinite linear;
}
.common-page .common-container .shape-7 {
	top: 55%;
	right: 110px;
	position: absolute;
	-webkit-animation: updown 1s infinite alternate;
	animation: updown 1s infinite alternate;
}
.common-page .common-container .shape-8 {
	top: 80%;
	left: 30%;
	position: absolute;
	-webkit-animation: zoominout 1.5s alternate infinite ease-in;
	animation: zoominout 1.5s alternate infinite ease-in;
}
.common-page .common-container .shape-9 {
	right: 282px;
	bottom: 135px;
	position: absolute;
	-webkit-animation: spin 4s infinite linear;
	animation: spin 4s infinite linear;
}
.common-page .common-container .item-content {
	z-index: 1;
	display: table;
	margin: auto 0px;
}
.common-page .common-container .item-content .title-text {
	font-size: 48px;
	color: #ffffff;
	text-transform: uppercase;
}
.common-page .common-container .item-content > p {
	font-size: 18px;
	line-height: 30px;
	color: #ffffff;
}
.common-page .common-container .item-content .custom-btn {
	background-color: #272d3a;
}

/* header & footer - start
================================================== */
.common-page .brand-logo {
	top: 50px;
	left: 100px;
	z-index: 999;
	position: absolute;
}

.common-page .social-links {
	left: 100px;
	bottom: 50px;
	z-index: 999;
	position: absolute;
}
.common-page .social-links .title-text {
	font-size: 18px;
	color: #272d3a;
	font-weight: 500;
}

/* header & footer - end
================================================== */
/* 17.1 - 404 page - start
================================================== */
.error-section .common-container {
	padding: 0px 215px;
}
.error-section .common-container .item-content {
	width: 325px;
}

/* 17.1 - 404 page - end
================================================== */
/* 17.2 - coming soon page - start
================================================== */
.coming-soon-section .common-container {
	padding: 0px 170px;
}
.coming-soon-section .common-container .item-content {
	width: 585px;
}
.coming-soon-section .common-container .item-content .title-text {
	text-transform: initial;
}
.coming-soon-section .common-container .item-content .countdown-timer ul li {
	color: #ffffff;
	text-align: center;
	margin-right: 40px;
	position: relative;
	padding-right: 40px;
}
.coming-soon-section
	.common-container
	.item-content
	.countdown-timer
	ul
	li:before {
	top: 25px;
	right: -4px;
	content: ':';
	font-size: 66px;
	position: absolute;
}
.coming-soon-section
	.common-container
	.item-content
	.countdown-timer
	ul
	li:last-child {
	margin-right: 0px;
	padding-right: 0px;
}
.coming-soon-section
	.common-container
	.item-content
	.countdown-timer
	ul
	li:last-child:before {
	display: none;
}
.coming-soon-section
	.common-container
	.item-content
	.countdown-timer
	ul
	li
	strong {
	width: 100%;
	display: table;
	font-size: 72px;
	font-weight: 400;
}
.coming-soon-section
	.common-container
	.item-content
	.countdown-timer
	ul
	li
	small {
	font-size: 14px;
	letter-spacing: 2px;
	text-transform: uppercase;
}
.coming-soon-section .common-container .item-content .email-form .form-item {
	width: 430px;
	margin-bottom: 15px;
}
.coming-soon-section
	.common-container
	.item-content
	.email-form
	.form-item
	input {
	height: 60px;
}
.coming-soon-section
	.common-container
	.item-content
	.email-form
	.form-item
	.submit-btn {
	top: 5px;
	z-index: 1;
	right: 5px;
	width: auto;
	height: 50px;
	padding: 0px 40px;
	line-height: 50px;
	position: absolute;
	border-radius: 3px;
	color: #ffffff;
}
.coming-soon-section .common-container .item-content .email-form > p {
	color: #ffffff;
	font-weight: 500;
}

/* 17.2 - coming soon page - end
================================================== */
/* 17.3 - register page - start
================================================== */
.register-section .common-container {
	padding: 0px 160px;
}
.register-section .common-container .item-content {
	width: 400px;
}
.register-section .common-container .item-content .title-text {
	text-transform: initial;
}
.register-section .common-container .item-content .register-form .custom-btn {
	background-color: #6b45f3;
}
.register-section
	.common-container
	.item-content
	.register-form
	.form-item
	label {
	top: 50%;
	left: 20px;
	cursor: text;
	position: absolute;
	font-weight: 500;
	text-transform: capitalize;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	transform: translateY(-50%);
	-webkit-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
	transition: all 0.3s ease-in-out;
}
.register-section
	.common-container
	.item-content
	.register-form
	.form-item
	input:focus
	+ label {
	left: 0px;
	top: -10px;
	color: #ffffff;
	-webkit-transform: translateY(-10px);
	-ms-transform: translateY(-10px);
	transform: translateY(-10px);
}
.register-section
	.common-container
	.item-content
	.register-form
	.tearm-condition
	input {
	float: left;
	margin-top: 6px;
}
.register-section
	.common-container
	.item-content
	.register-form
	.tearm-condition
	label {
	color: #ffffff;
	padding-left: 15px;
	position: relative;
	font-weight: 500;
}
.register-section
	.common-container
	.item-content
	.register-form
	.forgot-password
	a {
	color: #ffffff;
}
.register-section .common-container .item-content .register-form p {
	color: #ffffff;
	font-weight: 500;
}
.register-section .common-container .item-content .register-form p a {
	color: #ffffff;
}

/* 17.3 - register page - end
================================================== */
/* 17 - others pages - end
==================================================================================================== */
/* ================================================== 

*	css code for responsive layout To make Responsive
================================================== */
/* ==================================================
*	1 - media screen and (max-width: 1440px)
*	2 - media screen and (max-width: 1199px)
*	3 - media screen and (max-width: 991px)
*	4 - media screen and (max-width: 767px)
*	5 - media screen and (max-width: 680px)
*	6 - media screen and (max-width: 480px)
*	7 - media screen and (max-width: 320px)
================================================== */
/*  ====================================================================================================
1 - media screen and (max-width: 1440px) - start
==================================================================================================== */
@media screen and (max-width: 1440px) {
	/* breadcrumb section - start
	================================================== */
	.breadcrumb-section .design-image-1 {
		right: 180px;
	}
	.breadcrumb-section .cross-image-1 {
		left: 15px;
	}
	.breadcrumb-section .cross-image-2 {
		left: 325px;
	}
	.breadcrumb-section .flow-image-1 {
		top: 150px;
		left: 200px;
	}
	.breadcrumb-section .box-image-1 {
		top: 220px;
		left: 460px;
	}
	.breadcrumb-section .flow-image-2 {
		top: 380px;
		left: 620px;
	}
	.breadcrumb-section .circle-half-1 {
		left: 660px;
	}
	.breadcrumb-section .circle-image-1 {
		right: 380px;
	}
	.breadcrumb-section .box-image-2 {
		right: 100px;
		bottom: 100px;
	}
	.breadcrumb-section .circle-half-2 {
		top: 270px;
		right: 80px;
	}
	/* breadcrumb section - end
	================================================== */
	/* home 1 pages - start
	================================================== */
	.banner-section .mobile-app-banner-1 .shape-1 {
		top: 340px !important;
		left: 15px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-2 {
		top: 145px !important;
		left: 100px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-3 {
		bottom: 30px;
		left: 170px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-4 {
		top: 155px !important;
		left: 400px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-5 {
		bottom: 60px;
		left: 650px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-6 {
		bottom: 250px;
		left: 720px !important;
	}
	.banner-section .mobile-app-banner-1 .shape-7 {
		top: 150px !important;
		left: 780px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-content {
		padding-top: 50px;
	}
	.banner-section .mobile-app-banner-1 {
		overflow: hidden;
	}
	.banner-section .mobile-app-banner-1 .banner-image .bg-image {
		width: 540px;
		margin-right: 0px;
	}
	.banner-section .mobile-app-banner-1 .banner-image .phone-image {
		right: 130px;
		width: 210px;
		top: 155px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-1 {
		width: 190px;
		left: 0px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-2 {
		width: 185px;
		right: -20px;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-3 {
		right: 60px;
		width: 135px;
		top: 0px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .cog-image {
		top: 80px !important;
		left: 40% !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .leaf-image-2 {
		right: 0;
		width: 175px;
		top: 245px !important;
	}
	.features-section .feature-item .feature-image-1 .circle-image {
		width: 400px;
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-1 {
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 {
		margin-top: 60px;
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .circle-image {
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-1 .phone-image {
		top: -25px;
		left: 25px;
		width: 350px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
		top: -29px;
		right: 95px;
	}
	.features-section .feature-item .feature-content {
		padding-top: 0px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-1,
	.features-section .feature-item .feature-image-1 .comment-image-2 {
		width: 300px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-1 {
		left: 75px;
		top: 115px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-2 {
		left: 110px;
		bottom: -13px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-2 {
		width: 320px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-1 {
		top: 15px;
		left: -35px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-2 {
		bottom: 0px;
		right: 20px;
	}
	.service-section .container {
		max-width: 1200px;
	}
	.app-section .app-image .circle-image {
		width: 400px;
		margin: 0px auto;
	}
	.app-section .app-image .phone-image {
		top: 5px;
		right: 48px;
		width: 300px;
	}
	.app-section .app-content {
		padding-top: 78px;
	}
	.blog-section .blog-carousel:before {
		left: -50px;
	}
	.blog-section .blog-carousel:after {
		right: -50px;
	}
	/* home 1 pages - end
	================================================== */
	/* home 2 pages - start
	================================================== */
	.banner-section .mobile-app-banner-2 .shape-image-2 {
		left: 560px;
		bottom: 40px;
	}
	.banner-section .mobile-app-banner-2 .shape-image-3 {
		top: 202px;
		left: 600px;
	}
	.banner-section .mobile-app-banner-2 .shape-image-4 {
		top: 150px;
		right: 250px;
	}
	.banner-section .mobile-app-banner-2 .banner-image .man-image-2 {
		width: 175px;
		right: 30px !important;
	}
	.banner-section .mobile-app-banner-2 .banner-image .message-image {
		width: 120px;
		top: 240px !important;
	}
	.banner-section .mobile-app-banner-2 .banner-image .man-image-1 {
		width: 125px;
		left: 70px !important;
	}
	.banner-section .mobile-app-banner-2 {
		height: 850px;
		padding-top: 170px;
		margin-bottom: 40px;
	}
	.banner-section .mobile-app-banner-2 .banner-image .phone-image {
		z-index: 1;

		transform: translate3d(0px, 0px, 0px) !important;
		transform-style: preserve-3d !important;
		backface-visibility: hidden !important;
		position: relative !important;
		display: block !important;
		left: 0px !important;
		top: -200px !important;
		left: 100px !important;
	}
	.banner-section .mobile-app-banner-2 .banner-image .leaf-image-2 {
		width: 180px;
		right: 30px !important;
	}
	.features-section {
		overflow: hidden;
	}
	.features-section .feature-item .feature-image-2 .item-image {
		width: 570px;
		margin-left: 0px;
	}
	.features-section .feature-item .feature-image-2 .popup-video {
		right: -65px;
		bottom: 104px;
	}
	.features-section .feature-item .feature-image-2 .bg-image {
		width: 500px;
		margin-top: 115px;
		margin-right: 0px;
	}
	.features-section .feature-item .feature-image-2 .phone-image {
		right: 0px;
		width: 400px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1 {
		top: 145px;
		right: -80px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		right: -100px;
		bottom: 80px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-1 {
		top: 15px;
		left: 70px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-2 {
		top: 50%;
		right: -20px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-3 {
		left: 90px;
		bottom: 10px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .bg-image {
		margin-left: 0px;
		margin-right: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
		left: 60px;
		right: unset;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-1 {
		top: 50px;
		left: 0;
		right: unset;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-2 {
		left: 170px;
		right: unset;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-1 {
		left: 55px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-2 {
		right: 0;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
		left: 60px;
		bottom: 0px;
	}
	.app-section .app-image-2 .bg-image {
		width: 500px;
		margin-right: 0px;
	}
	.app-section .app-image-2 .phone-image {
		top: 0;
		width: 400px;
	}
	.app-section {
		overflow: hidden;
	}
	.app-section .app-image-2 .phone-image {
		right: 30px;
		width: 400px;
	}
	/* home 2 pages - end
	================================================== */
	/* home 3 pages - start
	================================================== */
	.slider-section .mobileapp-main-carousel {
		width: 1300px;
		margin: 0 auto;
	}
	.slider-section .mobileapp-main-carousel .item.first-item {
		overflow: hidden;
		padding-top: 80px;
	}
	.slider-section .mobileapp-main-carousel .item {
		height: 790px;
		background-size: cover;
	}
	.slider-section .mobileapp-main-carousel .item.second-item {
		padding-top: 80px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.phone-image {
		width: 380px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-1 {
		top: 50px !important;
		left: -120px !important;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-2 {
		top: 355px !important;
		left: -105px !important;
	}
	.slider-section .mobileapp-main-carousel .item.second-item .slider-content {
		padding-top: 100px;
	}
	/* home 3 pages - end
	================================================== */
	/* home 4 pages - start
	================================================== */
	.banner-section .mobile-app-banner-4 {
		height: auto;
		padding-top: 200px;
	}
	.banner-section .mobile-app-banner-4 .banner-content {
		padding-top: 0px;
	}
	.banner-section .mobile-app-banner-4 .banner-image {
		padding-top: 100px;
		padding-bottom: 0px;
	}
	.banner-section .mobile-app-banner-4 .banner-image .phone-image {
		width: 590px;
		margin-right: 0px;
	}
	.banner-section .mobile-app-banner-4 .banner-image .man-image-1 {
		width: 220px;
		top: 10px !important;
		left: 40px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .sms-image-1 {
		width: 150px;
		top: 105px !important;
		left: 155px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .man-image-2 {
		width: 230px;
		top: 20px !important;
		right: -3px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .sms-image-2 {
		width: 150px;
		top: -30px !important;
		right: 30px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .tree-image {
		right: 170px;
		bottom: 0px;
		width: 150px;
	}
	.banner-section .mobile-app-banner-4 .bg-shape-image {
		top: 0px;
		right: 0px;
		width: 53%;
	}
	.features-section .feature-item .feature-image-4 {
		padding-bottom: 40px;
		float: none !important;
	}
	.features-section .feature-item .feature-image-4 .phone-image {
		width: 250px;
		display: table;
		margin: 0px auto;
		float: none !important;
	}
	.features-section .feature-item .feature-image-4 .floor-image {
		bottom: 2px;
		width: 480px;
		left: 0px;
	}
	.features-section .feature-item .feature-image-4 .man-image-1 {
		bottom: 40px;
		left: -12px;
		width: 150px;
	}
	.features-section .feature-item .feature-image-4 .flower-image-2 {
		width: 80px;
		bottom: 37px;
		right: -5px;
	}
	.features-section .feature-item .feature-image-4 .comment-1-image {
		top: 85px;
		left: 25px;
		width: 250px;
	}
	.features-section .feature-item .feature-image-4 .comment-2-image {
		top: 225px;
		left: 110px;
		width: 250px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-1 {
		bottom: 42px;
		left: 60px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-2 {
		top: 40%;
		right: 60px;
		width: 50px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
		top: 50px;
		left: 148px;
		width: 80px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
		top: 150px;
		right: 150px;
		width: 80px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
		top: 250px;
		left: 150px;
		width: 80px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
		bottom: 0px;
		right: 60px;
		width: 160px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
		bottom: 0px;
		left: -105px;
		width: 340px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
		width: 110px;
		right: -20px;
		bottom: 100px;
	}
	/* home 4 pages - end
	================================================== */
	/* home 5 pages - start
	================================================== */
	.banner-section .mobile-app-banner-5 .banner-content .title-text {
		font-size: 48px;
	}
	.banner-section .mobile-app-banner-5 {
		padding-top: 170px;
	}
	.banner-section .mobile-app-banner-5 .banner-content {
		padding-top: 20px;
	}
	.banner-section .mobile-app-banner-5 .banner-image .phone-image {
		width: 390px;
		display: table;
		margin: 0px auto;
		float: none !important;
	}
	.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1,
	.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
		width: 350px;
	}
	.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-1 {
		right: 160px;
		top: 73px !important;
	}
	.banner-section .mobile-app-banner-5 .banner-image .commentbar-image-2 {
		right: 140px;
		top: 390px !important;
	}
	/* home 5 pages - end
	================================================== */
	/* home 6 pages - start
	================================================== */
	.banner-section .mobile-app-banner-6 .banner-content {
		padding-top: 100px;
	}
	.banner-section .mobile-app-banner-6 .banner-image .phone-image {
		width: 400px;
		margin-right: -45px;
	}
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1,
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
		width: 370px;
	}
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1 {
		top: 125px !important;
		left: -152px !important;
	}
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
		top: 340px !important;
		left: -140px !important;
	}
	.banner-section .mobile-app-banner-6 .shape-bg-image {
		top: -305px;
		right: -525px;
		width: 1255px;
	}
	.features-section .feature-item .feature-image-6 {
		padding-top: 95px;
	}
	.features-section .feature-item .feature-image-6 .bg-image {
		width: 550px;
		margin-left: -30px;
	}
	.features-section .feature-item .feature-image-6 .phone-image {
		top: 0px;
		left: 75px;
		width: 400px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1 {
		top: 170px;
		left: 150px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-2 {
		left: 190px;
		bottom: 90px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-1 {
		top: 30px;
		left: 80px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-2 {
		top: 52%;
		right: 0px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-3 {
		left: 100px;
		bottom: 10px;
	}
	.features-section .feature-item .feature-image-6 .popup-video {
		top: 280px;
		right: -75px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
		margin-left: 0px;
		margin-right: -30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
		right: 50px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1 {
		top: 60px;
		left: -60px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		left: 160px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-1 {
		left: 70px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-2 {
		right: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-3 {
		left: 80px;
	}
	/* home 6 pages - end
	================================================== */
	/* home 7 pages - start
	================================================== */
	.features-section .feature-item .feature-image-7 .bg-image {
		width: 490px;
		right: -80px;
	}
	.features-section .feature-item .feature-image-7 .big-chart {
		top: 80px;
		right: 0px;
		width: 500px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-1 {
		width: 40px;
		right: 270px;
		bottom: 90px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-2 {
		width: 40px;
		right: 220px;
		bottom: 90px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-3 {
		width: 40px;
		right: 170px;
		bottom: 90px;
	}
	.features-section .feature-item .feature-image-7 .man-image-1 {
		left: 25px;
		width: 155px;
		bottom: 65px;
	}
	.features-section .feature-item .feature-image-7 .man-image-2 {
		left: -95px;
		bottom: 65px;
		width: 195px;
	}
	.features-section .feature-item .feature-image-7 .man-image-3 {
		bottom: 60px;
		width: 165px;
		right: -30px;
	}
	.features-section .feature-item .feature-image-7 .leaf-image {
		width: 75px;
		bottom: 60px;
		right: -60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
		width: 500px;
		left: -60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
		left: 0px;
		top: 100px;
		width: 500px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
		top: 5px;
		left: 160px;
		width: 280px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
		right: 0px;
		bottom: 30px;
		width: 360px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
		right: -70px;
		bottom: 30px;
		width: 200px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
		left: -60px;
		bottom: 30px;
		width: 195px;
	}
	/* home 7 pages - end
	================================================== */
	/* home 9 pages - start
	================================================== */
	.banner-section .sass-banner-3 {
		padding-top: 240px;
		padding-bottom: 130px;
	}
	.banner-section .sass-banner-3 .banner-content {
		padding-top: 0px;
	}
	.banner-section .sass-banner-3 .banner-item-image .laptop-image {
		width: 820px;
		margin-left: -120px;
	}
	/* home 9 pages - end
	================================================== */
	/* home 10 pages - start
	================================================== */
	.banner-section .sass-banner-4 .banner-content {
		padding-top: 0px;
	}
	.banner-section .sass-banner-4 {
		height: auto;
		padding-top: 260px;
		padding-bottom: 120px;
	}
	.banner-section .sass-banner-4 .banner-item-image .bg-image {
		top: -630px;
		right: -525px;
		width: 1000px;
	}
	.banner-section .sass-banner-4 .banner-item-image .laptop-image {
		width: 600px;
		margin-left: 0px;
	}
	.banner-section .sass-banner-4 .banner-item-image .popup-video {
		top: 35%;
		left: 55%;
	}
	.banner-section .sass-banner-4 .banner-item-image .shape-image {
		top: -55px;
		left: 30px;
	}
	.features-section .feature-item .feature-image-10 {
		float: none !important;
	}
	.features-section .feature-item .feature-image-10 .big-image {
		width: 580px;
		margin-left: -40px;
	}
	.features-section .feature-item .feature-image-10 .small-image-1 {
		top: 76px;
		left: -100px;
	}
	.features-section .feature-item .feature-image-10 .small-image-2 {
		right: -80px;
		bottom: -75px;
	}
	.features-section .feature-item .feature-image-10 .shape-1 {
		top: -33px;
		left: 15px;
	}
	.features-section .feature-item .feature-image-10 .shape-2 {
		left: 90px;
		bottom: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-10 .big-image {
		margin-left: -60px;
		margin-right: 0px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-10
		.small-image-1 {
		top: 42px;
		left: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-10 .shape-2 {
		left: 120px;
		bottom: 10px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-10 .shape-3 {
		top: 56%;
		right: 0px;
		width: 50px;
	}
	/* home 10 pages - end
	================================================== */
	/* home 11 pages - start
	================================================== */
	/* blank */
	/* home 11 pages - end
	================================================== */
	/* home 12 pages - start
	================================================== */
	.search-section .overlay-color {
		padding: 150px 0px;
	}
	/* home 12 pages - end
	================================================== */
	/* app details 1 pages - start
	================================================== */
	/* app details 1 pages - end
	================================================== */
	/* app details 2 pages - start
	================================================== */
	/* app details 2 pages - end
	================================================== */
	/* pricing pages - start
	================================================== */
	.pricing-section .bg-image {
		max-width: 100%;
	}
	/* pricing pages - end
	================================================== */
	/* contact pages - start
	================================================== */
	.map-section .contact-form {
		right: 90px;
	}
	/* contact pages - end
	================================================== */
	/* 404 error pages - start
	================================================== */
	.error-section .common-container {
		padding: 0px 170px;
	}
	.common-page .common-container .shape-1 {
		top: 44%;
	}
	.common-page .common-container .shape-3 {
		top: 40px;
		right: 130px;
	}
	.common-page .common-container .shape-6 {
		top: 70%;
		left: 58%;
	}
	.common-page .common-container .shape-9 {
		right: 180px;
		bottom: 60px;
	}
	.common-page .common-container .shape-2 {
		top: 15%;
		left: 35%;
	}
	/* 404 error pages - end
	================================================== */
	/* coming soon pages - start
	================================================== */
	.coming-soon-section .common-container {
		padding: 0px 100px;
	}
	.coming-soon-section .common-container .item-content .title-text {
		font-size: 36px;
		margin-bottom: 15px;
	}
	.coming-soon-section .common-container .item-content .countdown-timer ul li {
		margin-right: 30px;
		padding-right: 30px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li:last-child {
		margin-right: 0px;
		padding-right: 0px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li
		strong {
		font-size: 60px;
	}
	/* coming soon pages - end
	================================================== */
	/* register pages - start
	================================================== */
	.register-section .common-container {
		padding: 0px 130px;
	}
	/* register pages - end
	================================================== */
}

/* ====================================================================================================
1 - media screen and (max-width: 1440px) - end
==================================================================================================== */
/*  ====================================================================================================
2 - media screen and (max-width: 1199px) - start
==================================================================================================== */
@media screen and (max-width: 1199px) {
	/* global reset - start
	================================================== */
	.section-title .paragraph-text {
		font-size: 14px;
		line-height: 24px;
	}
	.section-title .title-text {
		font-size: 30px;
	}
	/* global reset - end
	================================================== */
	/* header section - start
	================================================== */
	.header-section .btns-group > ul > li .custom-btn {
		padding: 0px 20px;
	}
	.home-page-7 .header-section .btns-group > ul > li .mobile-menu-btn {
		margin-left: 0px;
	}
	/* header section - end
	================================================== */
	/* header section - start
	================================================== */
	.footer-section .footer-content {
		margin-bottom: 60px;
	}
	/* header section - end
	================================================== */
	/* footer section - start
	================================================== */
	.footer-section {
		background-size: 100% 40%;
	}
	/* footer section - end
	================================================== */
	/* breadcrumb section - start
	================================================== */
	.breadcrumb-section .section-title .title-text {
		font-size: 30px;
	}
	.breadcrumb-section .section-title .paragraph-text {
		font-size: 14px;
		max-width: 100%;
	}
	.breadcrumb-section .design-image-1 {
		right: 15px;
	}
	.breadcrumb-section .cross-image-1,
	.breadcrumb-section .cross-image-2,
	.breadcrumb-section .box-image-1,
	.breadcrumb-section .box-image-2,
	.breadcrumb-section .flow-image-1,
	.breadcrumb-section .flow-image-2,
	.breadcrumb-section .circle-half-1,
	.breadcrumb-section .circle-half-2,
	.breadcrumb-section .circle-image-1 {
		display: none;
	}
	/* breadcrumb section - end
	================================================== */
	/* home 1 pages - start
	================================================== */
	.banner-section .mobile-app-banner-1 .banner-image .bg-image {
		width: 370px;
		margin-right: 0px;
	}
	.banner-section .mobile-app-banner-1 .banner-image .phone-image {
		right: 160px;
		width: 150px;
		top: 130px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-1 {
		width: 135px;
		left: 0px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-2 {
		width: 135px;
		right: 50px;
	}
	.banner-section .mobile-app-banner-1 .banner-image .man-image-3 {
		right: 95px;
		width: 115px;
		top: 0px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .cog-image {
		width: 45px;
		top: 70px !important;
		left: 30% !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .leaf-image-1 {
		z-index: 0;
		width: 130px;
		position: absolute;
		top: 90px !important;
		left: 0px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image .leaf-image-2 {
		right: 70px;
		width: 130px;
		top: 160px !important;
	}
	.banner-section .mobile-app-banner-1 .banner-content > h2 {
		font-size: 36px;
		line-height: 42px;
	}
	.features-section .feature-item .feature-content .section-title .title-text {
		font-size: 30px;
	}
	.features-section .feature-item .feature-content > p {
		font-size: 14px;
	}
	.features-section .feature-item .feature-image-1 .circle-image {
		width: 350px;
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-1 .phone-image {
		top: -25px;
		left: 48px;
		width: 300px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
		top: -29px;
		right: 50px;
	}
	.app-section,
	.features-section {
		padding-top: 40px;
		overflow: hidden;
	}
	.blog-section .blog-carousel:after,
	.blog-section .blog-carousel:before {
		display: none;
	}
	/* home 1 pages - end
	================================================== */
	/* home 2 pages - start
	================================================== */
	.banner-section .mobile-app-banner-2 .banner-content > h2,
	.banner-section .mobile-app-banner-2 .banner-content > h1 {
		font-size: 40px;
		line-height: 54px;
	}
	.features-section .feature-item .feature-image-2 .item-image {
		width: 480px;
	}
	.features-section .feature-item .feature-content .feature-item-title {
		font-size: 30px;
		margin-bottom: 30px;
	}
	.features-section
		.feature-item
		.feature-content
		.service-list
		> ul
		> li
		.item-icon {
		margin-right: 20px;
	}
	.home-page-2 .features-section .feature-item .feature-content {
		padding-top: 30px;
	}
	.features-section .feature-item .feature-image-2 .bg-image {
		width: 350px;
		margin-top: 110px;
		margin-right: 0px;
	}
	.features-section .feature-item .feature-image-2 .phone-image {
		right: 70px;
		width: 280px;
		top: 10px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1,
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		width: 250px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1 {
		top: 130px;
		right: 150px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		right: 40px;
		bottom: 60px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-1 {
		top: 25px;
		left: 40px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-2 {
		top: 50%;
		right: 51px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-3 {
		left: 60px;
		bottom: 0;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
		left: 40px;
		right: unset;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-2 {
		width: 250px;
		position: absolute;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-2 {
		left: 120px;
		right: unset;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-2 {
		right: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
		left: 0;
		bottom: 0px;
	}
	.app-section .app-image-2 {
		padding-top: 60px;
	}
	.app-section .app-image-2 .bg-image {
		width: 330px;
	}
	.app-section .app-image-2 .phone-image {
		right: 0px;
		width: 300px;
		top: -20px;
	}
	.app-section .app-image-2 .shape-image-1 {
		left: 20px;
		top: 60px;
	}
	.app-section .app-image-2 .shape-image-2 {
		right: 0;
		top: 220px;
	}
	.app-section .app-image-2 .shape-image-3 {
		left: 34px;
		bottom: -40px;
	}
	/* home 2 pages - end
	================================================== */
	/* home 3 pages - start
	================================================== */
	.slider-section .mobileapp-main-carousel {
		width: 100%;
		margin: 0 auto;
	}
	.slider-section .mobileapp-main-carousel .item.first-item h2 {
		font-size: 30px;
		line-height: 42px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.title-text {
		font-size: 40px;
	}
	.slider-section .mobileapp-main-carousel .item.second-item .slider-content {
		padding-top: 0px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.phone-image {
		width: 250px;
		display: table;
		margin: 0px auto;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-1,
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-2 {
		width: 220px;
		position: absolute;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-1 {
		top: 40px !important;
		left: -10px !important;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-image
		.commentbar-image-2 {
		top: 250px !important;
		left: -10px !important;
	}
	.slider-section .mobileapp-main-carousel .item {
		height: 600px;
		background-size: cover;
	}
	.slider-section .mobileapp-main-carousel .item.first-item .phone-image {
		width: 230px;
		display: table;
		margin: 0 auto 0px;
	}
	.features-section .feature-item .feature-image-3 .bg-image {
		width: 350px;
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-3 {
		padding-top: 130px;
	}
	.features-section .feature-item .feature-image-3 .phone-image {
		z-index: 1;
		left: 70px;
		top: 50px;
		width: 220px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-3 .comment-image {
		z-index: 2;
		top: 135px;
		left: 138px;
		width: 80px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-3 .leaf-image-2 {
		top: 10px;
		right: 40px;
		width: 140px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-3 .man-image-2 {
		z-index: 2;
		right: 35px;
		bottom: 0px;
		width: 125px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-3 .man-image-1 {
		z-index: 2;
		left: 20px;
		bottom: 0px;
		width: 90px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
		width: 350px;
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
		top: 100px;
		left: 115px;
		width: 175px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-1 {
		top: 35px;
		left: 15px;
		width: 200px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
		top: 25px;
		right: 10px;
		width: 200px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
		left: 10px;
		bottom: -20px;
		width: 140px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
		right: 15px;
		width: 125px;
		bottom: -10px;
		position: absolute;
	}
	/* home 3 pages - end
	================================================== */
	/* home 4 pages - start
	================================================== */
	.banner-section .mobile-app-banner-4 .banner-content > h2 {
		font-size: 38px;
		line-height: 50px;
	}
	.banner-section .mobile-app-banner-4 .banner-image .phone-image {
		width: 390px;
		margin-right: 0px;
	}
	.banner-section .mobile-app-banner-4 .banner-image .man-image-1 {
		width: 160px;
		top: 10px !important;
		left: 40px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .sms-image-1 {
		width: 101px;
		top: 75px !important;
		left: 130px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .man-image-2 {
		width: 170px;
		top: 23px !important;
		right: 96px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .sms-image-2 {
		width: 120px;
		top: -26px !important;
		right: 120px !important;
	}
	.banner-section .mobile-app-banner-4 .banner-image .tree-image {
		right: 180px;
		bottom: 20px;
		width: 130px;
	}
	.banner-section .mobile-app-banner-4 .bg-shape-image {
		top: 0px;
		right: 0px;
		width: 66%;
	}
	.features-section .feature-item .feature-image-4 .phone-image {
		width: 200px;
	}
	.features-section .feature-item .feature-image-4 .floor-image {
		bottom: 15px;
		width: 350px;
		left: 0px;
	}
	.features-section .feature-item .feature-image-4 .man-image-1 {
		bottom: 40px;
		left: 0px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-4 .comment-1-image {
		top: 85px;
		left: 25px;
		width: 190px;
	}
	.features-section .feature-item .feature-image-4 .comment-2-image {
		top: 185px;
		left: 95px;
		width: 190px;
	}
	.features-section .feature-item .feature-image-4 .flower-image-2 {
		width: 50px;
		bottom: 37px;
		right: 40px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
		top: 50px;
		left: 120px;
		width: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
		top: 130px;
		right: 130px;
		width: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
		top: 220px;
		left: 130px;
		width: 58px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
		bottom: 0px;
		right: 60px;
		width: 125px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
		bottom: 0px;
		left: -75px;
		width: 280px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
		width: 80px;
		right: 10px;
		bottom: 100px;
	}
	/* home 4 pages - end
	================================================== */
	/* home 5 pages - start
	================================================== */
	.banner-section .mobile-app-banner-5 .banner-content .title-text {
		font-size: 40px;
	}
	.banner-section .mobile-app-banner-6 .banner-image .phone-image {
		width: 400px;
		margin-right: -15px;
	}
	.working-process-section .process-item {
		margin-left: -15px;
		margin-right: -15px;
	}
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-1 {
		top: 125px !important;
		left: -220px !important;
	}
	.banner-section .mobile-app-banner-6 .banner-image .commentbar-image-2 {
		top: 340px !important;
		left: -210px !important;
	}
	.features-section .feature-item .feature-image-6 .bg-image {
		width: 430px;
		margin-left: 0;
	}
	.features-section .feature-item .feature-image-6 .phone-image {
		top: 0px;
		left: 70px;
		width: 330px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1,
	.features-section .feature-item .feature-image-6 .comment-image-2 {
		width: 320px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1 {
		top: 140px;
		left: 140px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-2 {
		left: 150px;
		bottom: 60px;
	}
	.features-section .feature-item .feature-image-6 .popup-video {
		top: 50%;
		right: unset;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
	}
	.home-page-6 .features-section .feature-item .feature-content {
		padding: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
		margin-left: 0px;
		margin-right: 0;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		width: 320px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1 {
		top: 60px;
		left: 0;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		left: 0;
	}
	/* home 5 pages - end
	================================================== */
	/* home 6 pages - start
	================================================== */
	.banner-section .mobile-app-banner-6 .banner-content h2 {
		font-size: 36px;
	}
	/* home 6 pages - end
	================================================== */
	/* home 7 pages - start
	================================================== */
	.banner-section .sass-banner-1 .banner-item-image .child-img-1 {
		top: -14px;
		left: 320px;
		width: 350px;
	}
	.banner-section .sass-banner-1 .banner-item-image .child-img-2 {
		left: 0px;
		bottom: -17px;
		width: 380px;
	}
	.banner-section .sass-banner-1 .banner-item-image .child-img-3 {
		right: 60px;
		bottom: -17px;
		width: 380px;
	}
	.home-page-7 .banner-section {
		margin-bottom: 0px;
	}
	.banner-section .sass-banner-1 .banner-item-image {
		z-index: 2;
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-image-7 .bg-image {
		right: 0px;
		width: 450px;
	}
	.features-section .feature-item .feature-image-7 .big-chart {
		top: 80px;
		right: 0px;
		width: 450px;
	}
	.features-section .feature-item .feature-image-7 .man-image-1 {
		left: 30px;
		width: 140px;
		bottom: 65px;
	}
	.features-section .feature-item .feature-image-7 .man-image-2 {
		left: -60px;
		bottom: 65px;
		width: 170px;
	}
	.features-section .feature-item .feature-image-7 .man-image-3 {
		bottom: 60px;
		width: 150px;
		right: -15px;
	}
	.features-section .feature-item .feature-image-7 .leaf-image {
		width: 70px;
		bottom: 60px;
		right: -10px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
		left: 0px;
		width: 450px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
		left: 15px;
		top: 100px;
		width: 450px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
		left: 0px;
		bottom: 30px;
		width: 150px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
		right: 0px;
		bottom: 30px;
		width: 310px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
		top: 0px;
		left: 160px;
		width: 280px;
	}
	/* home 7 pages - end
	================================================== */
	/* home 8 pages - start
	================================================== */
	.banner-section .sass-banner-2 .banner-content .title-text {
		font-size: 30px;
		line-height: 42px;
	}
	/* home 8 pages - end
	================================================== */
	/* home 9 pages - start
	================================================== */
	.banner-section .sass-banner-3 .banner-content .title-text {
		font-size: 36px;
		line-height: 54px;
	}
	/* home 9 pages - end
	================================================== */
	/* home 10 pages - start
	================================================== */
	.banner-section .sass-banner-4 .banner-content .title-text {
		font-size: 42px;
		line-height: 54px;
	}
	.features-section .feature-item .feature-image-10 .big-image {
		width: 450px;
		margin-left: 0px;
	}
	.features-section .feature-item .feature-image-10 .small-image-2 {
		right: -80px;
		bottom: -75px;
		width: 250px;
	}
	.features-section .feature-item .feature-image-10 .small-image-1 {
		top: 60px;
		left: -20px;
		width: 250px;
	}
	.features-section .feature-item .feature-image-10 .shape-1 {
		top: -20px;
		left: 40px;
		width: 60px;
	}
	.features-section .feature-item .feature-image-10 .shape-2 {
		left: 130px;
		bottom: 0px;
		width: 60px;
	}
	/* home 10 pages - end
	================================================== */
	/* home 11 pages - start
	================================================== */
	.home-page-11 .slider-section .owl-theme .owl-nav .owl-prev,
	.home-page-11 .slider-section .owl-theme .owl-nav .owl-next {
		position: relative;
		-webkit-transform: unset;
		-ms-transform: unset;
		transform: unset;
		left: 0px;
		right: 0px;
	}
	.home-page-11 .slider-section .owl-theme .owl-nav {
		margin-top: 30px;
	}
	.slider-section .appstore-main-carousel .item .item-content h1 {
		font-size: 36px;
		line-height: 54px;
		margin-bottom: 20px;
	}
	.slider-section
		.appstore-main-carousel
		.item
		.item-content
		.btns-group
		> ul
		> li {
		margin-right: 5px;
	}
	.mobile-app-section .app-item {
		width: 100%;
		display: table;
		margin: 50px auto 0px;
	}
	.mobile-app-section .app-item .app-content {
		padding: 20px 15px;
	}
	/* home 11 pages - end
	================================================== */
	/* home 12 pages - start
	================================================== */
	.search-section .banner-content h1 {
		font-size: 42px;
		line-height: 54px;
		margin-bottom: 30px;
	}
	.search-section .banner-content h1 {
		font-size: 32px;
		line-height: 42px;
		margin-bottom: 20px;
	}
	.search-section .banner-content h3 {
		font-size: 25px;
		line-height: 54px;
		margin-bottom: 30px;
	}
	.search-section .banner-content h3 {
		font-size: 32px;
		line-height: 25px;
		margin-bottom: 20px;
	}
	.search-section .overlay-color {
		padding: 100px 0px;
	}
	.mobile-app-section .app-nav > ul {
		margin-bottom: 60px;
	}
	.mobile-app-section
		.tab-content
		.tab-pane
		.top-content-bar
		.option-form
		.form-control {
		height: 50px;
		display: table;
		font-size: 16px;
		max-width: 320px;
		margin: 30px auto 0px;
	}
	.mobile-app-section .tab-content .tab-pane .top-content-bar .tab-pane-title {
		text-align: center;
	}
	/* home 12 pages - end
	================================================== */
	/* app details 1 pages - start
	================================================== */
	.sidebar-section.app-details-sidebar .widget_search .form-item .search-btn {
		padding: 0px 15px;
	}
	/* app details 1 pages - end
	================================================== */
	/* app details 2 pages - start
	================================================== */
	/* app details 2 pages - end
	================================================== */
	/* about page - start
	================================================== */
	.team-section .team-member-bordered {
		width: 220px;
		padding: 40px 15px;
	}
	.about-section .about-content > p {
		font-size: 14px;
	}
	.about-section .about-image .item-image {
		width: 500px;
		margin-left: -60px;
	}
	.about-section .about-image .popup-video {
		right: -35px;
	}
	.testimonial-section
		.testimonial-carousel
		.item
		.testimonial-content
		.hero-info {
		margin-bottom: 30px;
	}
	.testimonial-section
		.testimonial-carousel
		.item
		.testimonial-content
		.paragraph-text {
		font-size: 18px;
		line-height: 30px;
	}
	/* about page - end
	================================================== */
	/* testimonial pages - start
	================================================== */
	.testimonial-section .review-item .reviewer-content .reviewer-comment {
		font-size: 18px;
		line-height: 30px;
	}
	.testimonial-section .review-item .reviewer-content .reviewer-info {
		margin-bottom: 30px;
	}
	/* testimonial pages - end
	================================================== */
	/* team pages - start
	================================================== */
	.team-section .team-member-grid {
		width: 230px;
	}
	/* team pages - end
	================================================== */
	/* contact pages - start
	================================================== */
	.map-section .contact-form {
		right: 15px;
	}
	/* contact pages - end
	================================================== */
	/* blog pages - start
	================================================== */
	.blog-section .blog-grid-item .blog-content .blog-title .title-link {
		font-size: 18px;
	}
	/* blog pages - end
	================================================== */
	/* 404 error pages - start
	================================================== */
	.common-page .common-container {
		width: 100%;
		padding: 0px 30px;
		text-align: center;
	}
	.common-page .common-container:first-child {
		display: none;
	}
	.common-page .common-container .item-content {
		margin: auto;
	}
	.common-page .brand-logo {
		left: 0px;
		right: 0px;
		width: 100%;
		text-align: center;
	}
	.common-page .social-links {
		left: 0px;
		right: 0px;
		width: 100%;
		display: table;
		text-align: center;
	}
	.common-page .social-links > ul {
		display: table;
		margin: 0 auto;
	}
	/* 404 error pages - end
	================================================== */
	/* coming soon pages - start
	================================================== */
	.coming-soon-section .common-container .item-content .countdown-timer ul {
		display: table;
		margin: 0 auto;
		text-align: center;
	}
	.coming-soon-section .common-container .item-content .email-form .form-item {
		margin: 0 auto 30px;
	}
	/* coming soon pages - end
	================================================== */
	/* register pages (login) - start
	================================================== */
	.register-section
		.common-container
		.item-content
		.register-form
		.forgot-password {
		text-align: center !important;
	}
	/* register pages (login) - end
	================================================== */
}

/* ====================================================================================================
2 - media screen and (max-width: 1199px) - end
==================================================================================================== */
/* ====================================================================================================
3 - media screen and (max-width: 991px) - start --->>>for medium device
==================================================================================================== */
@media screen and (max-width: 991px) {
	/* global reset - start
	================================================== */
	.section-title {
		margin-bottom: 60px;
	}
	.sec-ptb-160 {
		padding: 100px 0px;
	}
	/* global reset - end
	================================================== */
	/* header section - start
	================================================== */
	.header-section.black-content .col-lg-7,
	.header-section.white-content .col-lg-7 {
		display: none;
	}
	.header-section.black-content {
		/* home 1 */
	}
	.header-section.black-content .col-lg-8 {
		display: none;
	}
	.header-section .btns-group > ul > li.d-none {
		display: inline-block !important;
	}
	.home-page-7 .header-section .btns-group > ul > li .mobile-menu-btn {
		margin-left: 30px;
	}
	.home-page-7 .banner-section:after {
		display: none;
	}
	/* header section - end
	================================================== */
	/* footer section - start
	================================================== */
	.footer-section .footer-content .useful-links,
	.footer-section .footer-content .about-content {
		margin-bottom: 60px;
	}
	.funfact-section .bg-image {
		background-size: 95% 100%;
	}
	.footer-section,
	.home-page-1 .footer-section {
		background-size: 100% 15%;
	}
	/* footer section - end
	================================================== */
	/* breadcrumb section - start
	================================================== */
	.breadcrumb-section .design-image-1,
	.breadcrumb-section .design-image-2 {
		display: none;
	}
	.breadcrumb-section .breadcrumb-content {
		text-align: center;
	}
	.breadcrumb-section .breadcrumb-nav > .breadcrumb {
		display: table;
		margin: 0 auto;
		text-align: center;
	}
	.breadcrumb-section .breadcrumb-nav > .breadcrumb > .breadcrumb-item {
		float: left;
		display: inline-block;
	}
	.breadcrumb-section .section-title {
		margin-bottom: 60px;
	}
	.breadcrumb-section .section-title .paragraph-text {
		font-size: 14px;
		max-width: 100%;
		margin: 0px auto;
	}
	.breadcrumb-section {
		padding-top: 160px;
		padding-bottom: 60px;
	}
	/* breadcrumb section - end
	================================================== */
	/* sidebar section - start
	================================================== */
	.sidebar-section .widget_instagram > ul > li {
		width: 100px;
		height: 100px;
	}
	/* sidebar section - end
	================================================== */
	/* home 1 pages - start
	================================================== */
	.mobile-banner-image.d-none {
		margin-bottom: 30px;
		display: block !important;
	}
	.banner-section .mobile-app-banner-1 .banner-image {
		display: none;
	}
	.banner-section .mobile-app-banner-1 {
		padding-top: 145px;
		padding-bottom: 100px;
	}
	.banner-section .mobile-app-banner-1 .banner-content {
		padding-top: 0px;
		text-align: center;
	}
	.banner-section .mobile-app-banner-1 .banner-content > h2 {
		font-size: 40px;
		line-height: 42px;
	}
	.banner-section .mobile-app-banner-1 .banner-content .btns-group > ul {
		display: table;
		margin: 0 auto;
	}
	.client-section .owl-theme .owl-nav {
		margin-top: 60px;
	}
	.client-section {
		margin-bottom: 0px !important;
	}
	.working-process-section .process-steps-list > ul {
		width: 100%;
		display: table;
	}
	.working-process-section .process-steps-list > ul > li {
		width: 50%;
		margin-bottom: 40px;
	}
	.working-process-section .process-steps-list > ul > li:nth-child(3),
	.working-process-section .process-steps-list > ul > li:nth-child(4) {
		margin-bottom: 0px;
	}
	.working-process-section .process-steps-list > ul:before {
		display: none;
	}
	.extra-features-section .tab-content .tab-pane .feature-content {
		font-size: 16px;
		text-align: center;
		margin-bottom: 40px;
	}
	.extra-features-section
		.tab-content
		.tab-pane
		.feature-content
		.instructions-list {
		text-align: left;
	}
	.features-section .feature-item .feature-content {
		margin-top: 60px;
		text-align: center;
	}
	.features-section .feature-item:nth-child(2) .feature-content {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.features-section .feature-item .feature-content .info-list {
		text-align: left;
	}
	.features-section .feature-item .feature-image-1 .phone-image {
		left: 29px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
		right: -36px;
	}
	.features-section .feature-item .feature-content .btns-group > ul {
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item {
		margin-bottom: 100px;
	}
	.features-section .feature-item:last-child {
		margin-bottom: 0px;
	}
	.testimonial-section .testimonial-carousel .item .hero-image {
		float: none;
		width: 500px;
		display: table;
		margin: 0 auto 40px;
	}
	.app-section .app-content {
		padding: 0px;
		text-align: center;
		margin-bottom: 60px;
	}
	.app-section .app-content .btns-group > ul {
		display: table;
		margin: 0 auto;
	}
	.app-section .app-image {
		display: table;
		margin: 0 auto;
		float: none !important;
	}
	.faq-section .queistions-container .queistions-item {
		text-align: left;
	}
	/* home 1 pages - end
	================================================== */
	/* home 2 pages - start
	================================================== */
	.banner-section .mobile-app-banner-2 .shape-image-2,
	.banner-section .mobile-app-banner-2 .shape-image-3,
	.banner-section .mobile-app-banner-2 .shape-image-4 {
		display: none;
	}
	.banner-section .mobile-app-banner-2 {
		padding-top: 170px;
		padding-bottom: 120px;
		background-size: cover;
		/* height: 400px; */
		/* background-image: url(./../images/banner/bg-img.png); */
	}

	@media screen and (min-width: 3000px) {
		.banner-section .mobile-app-banner-2 {
			padding-top: 100px;
			padding-bottom: 100px;
		}
	}

	.banner-section .mobile-app-banner-2 {
		overflow: hidden;
	}
	.banner-section .mobile-app-banner-2 .mobile-banner-image > img {
		display: table;

		max-width: 370px;
		margin: 0px auto;
		margin-top: 30px !important;
	}
	.banner-section .mobile-app-banner-2 .banner-image {
		display: none;
	}
	.banner-section .mobile-app-banner-2 .banner-content {
		padding-top: 0px;
		text-align: center;
	}
	.banner-section .mobile-app-banner-2 {
		height: auto;
		padding-top: 170px;
		padding-bottom: 100px;
	}
	.features-section .feature-item .feature-image-2 .item-image {
		width: 480px;
		display: table;
		margin: 0 auto;
	}
	.features-section .feature-item .feature-image-2 .popup-video {
		top: 50%;
		left: 50%;
		-webkit-transform: translate(-50%, -50%);
		-ms-transform: translate(-50%, -50%);
		transform: translate(-50%, -50%);
		right: unset;
		bottom: unset;
	}
	.features-section .feature-item .feature-content .service-list > ul > li {
		text-align: left;
	}
	.features-section .feature-item .feature-image-2 .phone-image {
		right: 40px;
		width: 265px;
		top: 0px;
	}
	.features-section .feature-item .feature-image-2 .bg-image {
		width: 350px;
		margin-top: 75px;
		margin-right: 0px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1 {
		top: 115px;
		right: 115px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		right: 0px;
		bottom: 60px;
	}
	.features-section .feature-item .feature-image-2 {
		margin-top: 60px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-2 {
		top: 50%;
		right: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
		left: 55px;
		right: unset;
	}
	.features-section .feature-item .feature-image-2 {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
		left: 50px;
		bottom: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 {
		margin-bottom: 60px;
	}
	.features-section .feature-item .feature-content {
		margin-top: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-content {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.faq-section {
		overflow: hidden;
	}
	.faq-section .faq-tabs-nav {
		margin-bottom: 60px;
	}
	.faq-section .faq-tabs-nav > .nav {
		margin-bottom: 60px;
	}
	/* home 2 pages - end
	================================================== */
	/* home 3 pages - start
	================================================== */
	.slider-section .mobileapp-main-carousel .item.first-item .phone-image,
	.slider-section .mobileapp-main-carousel .item.second-item .slider-image {
		display: none;
	}
	.slider-section .mobileapp-main-carousel .item.second-item .slider-content {
		text-align: center;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.newsletter-form {
		width: 100%;
		display: table;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.first-item
		.subscribe-form
		.form-item {
		width: 100%;
		display: table;
		margin: 0 auto 20px;
	}
	.slider-section .mobileapp-main-carousel .item {
		height: auto;
		padding: 80px 0px;
	}
	.slider-section .mobileapp-main-carousel .item.first-item .subscribe-form {
		margin-bottom: 0px;
	}
	.slider-section .mobileapp-main-carousel {
		-webkit-box-shadow: none;
		box-shadow: none;
	}
	.home-page-3 .slider-section {
		margin-bottom: 30px;
	}
	.features-section .feature-item .feature-image-3 {
		margin-top: 60px;
		padding-top: 130px;
	}
	.features-section .feature-item .feature-image-3 .man-image-2 {
		right: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
		right: -20px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
		right: -12px;
	}
	/* home 3 pages - end
	================================================== */
	/* home 4 pages - start
	================================================== */
	.banner-section .mobile-app-banner-4 .banner-image {
		display: none;
	}
	.banner-section .mobile-app-banner-4 .banner-content {
		text-align: center;
	}
	.banner-section .mobile-app-banner-4 .banner-content .btns-group > ul {
		display: table;
		margin: 0px auto;
	}
	.banner-section .mobile-app-banner-4 .bg-shape-image {
		display: none;
	}
	.features-section .feature-item .feature-image-4 .flower-image-2 {
		width: 50px;
		bottom: 40px;
		right: 10px;
	}
	.features-section .feature-item .feature-image-4 .man-image-1 {
		bottom: 40px;
		left: -20px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-4 .comment-2-image {
		top: 185px;
		left: 75px;
		width: 190px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-1 {
		bottom: 42px;
		left: 35px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-2 {
		top: 40%;
		right: 45px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-4 {
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 {
		margin-top: 60px;
		margin-bottom: 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
		bottom: 0px;
		left: -95px;
		width: 280px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
		width: 80px;
		right: 0;
		bottom: 100px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
		bottom: 0px;
		right: 50px;
		width: 125px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
		top: 220px;
		left: 110px;
		width: 58px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
		top: 135px;
		right: 110px;
		width: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
		top: 50px;
		left: 110px;
		width: 60px;
	}
	/* home 4 pages - end
	================================================== */
	/* home 5 pages - start
	================================================== */
	.banner-section .mobile-app-banner-5 .banner-image {
		display: none;
	}
	.banner-section .mobile-app-banner-5 .mobile-banner-image {
		margin-bottom: 0px;
	}
	.banner-section .mobile-app-banner-5 .mobile-banner-image > img {
		max-width: 370px;
		display: table;
		margin: 0px auto;
	}
	.banner-section .mobile-app-banner-5 .banner-content {
		text-align: center;
	}
	.banner-section .mobile-app-banner-5 .banner-content .newsletter-form {
		width: 100%;
	}
	.banner-section .mobile-app-banner-5 {
		padding-top: 170px;
		padding-bottom: 120px;
		background-size: cover;
		background-image: -webkit-gradient(
			linear,
			left bottom,
			right top,
			from(#ff4797),
			to(#ffb87e)
		) !important;
		background-image: -webkit-linear-gradient(
			bottom left,
			#ff4797,
			#ffb87e
		) !important;
		background-image: -o-linear-gradient(
			bottom left,
			#ff4797,
			#ffb87e
		) !important;
		background-image: linear-gradient(
			to top right,
			#ff4797,
			#ffb87e
		) !important;
	}

	/* home 5 pages - end
	================================================== */
	/* home 6 pages - start
	================================================== */
	.banner-section .mobile-app-banner-6 .mobile-banner-image > img {
		max-width: 370px;
		display: table;
		margin: 0px auto;
	}
	.banner-section .mobile-app-banner-6 .banner-image {
		display: none;
	}
	.banner-section .mobile-app-banner-6 .shape-bg-image {
		display: none;
	}
	.banner-section .mobile-app-banner-6 .banner-content {
		padding-top: 50px;
		text-align: center;
	}
	.banner-section .mobile-app-banner-6 .banner-content h2 {
		font-size: 30px;
		line-height: 42px;
	}
	.banner-section .mobile-app-banner-6 .banner-content .btns-group > ul {
		display: table;
		margin: 0px auto 0px;
	}
	.service-section .service-grid-item {
		width: 360px;
		display: table;
		margin: 30px auto 0px;
	}
	.features-section .feature-item .feature-image-6 {
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 {
		margin-bottom: 0px;
		margin-top: 60px;
	}
	.features-section .feature-item .feature-image-6 .bg-image {
		width: 100%;
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-6 .phone-image {
		top: 15px;
		left: 55px;
		width: 270px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1 {
		top: 100px;
		left: 88px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-2 {
		left: 120px;
		bottom: 65px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-1 {
		top: 60px;
		left: 60px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-2 {
		top: 55%;
		right: 55px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-3 {
		left: 75px;
		bottom: 65px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-6 .popup-video {
		width: 60px;
		height: 60px;
		font-size: 20px;
		line-height: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
		right: 50px;
		top: 75px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1 {
		top: 120px;
		left: 0px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		width: 250px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-1 {
		left: 70px;
		top: 95px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-2 {
		right: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .shape-image-3 {
		left: 70px;
		bottom: 0px;
	}
	.working-process-section .process-item {
		margin-left: 0px;
		margin-right: 0px;
	}
	.working-process-section .process-phone-image .phone-image {
		width: 360px;
	}
	/* home 6 pages - end
	================================================== */
	/* home 7 pages - start
	================================================== */
	.home-page-7 .banner-section .decoration-items {
		display: none;
	}
	.banner-section .sass-banner-1 .banner-item-image .child-img-1 {
		top: -14px;
		left: 250px;
		width: 300px;
	}
	.banner-section .sass-banner-1 .banner-item-image .child-img-2 {
		left: 0px;
		bottom: -17px;
		width: 270px;
	}
	.banner-section .sass-banner-1 .banner-item-image .child-img-3 {
		right: 60px;
		bottom: -17px;
		width: 251px;
	}
	.banner-section .sass-banner-1 .banner-item-image {
		display: none;
	}
	.banner-section .sass-banner-1 {
		padding-top: 200px;
		padding-bottom: 80px;
	}
	.software-section .software-container .software-item:nth-child(2) {
		left: 0px;
	}
	.software-section .software-container .software-item:nth-child(7) {
		right: 0px;
	}
	.software-section .shape-img-1,
	.software-section .shape-img-2 {
		width: 300px;
	}
	.pricing-section .bg-image .bg-shape-1 {
		display: none;
	}
	.free-trail-section .free-trail-form {
		padding: 50px 100px;
	}
	.features-section .feature-item .feature-image-7 {
		margin-top: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	/* home 7 pages - end
	================================================== */
	/* home 8 pages - start
	================================================== */
	.banner-section .sass-banner-2 .banner-item-image {
		display: none;
	}
	.banner-section .sass-banner-2 .banner-content {
		padding-top: 50px;
		text-align: center;
	}
	.banner-section .sass-banner-2 .banner-content .newsletter-form .form-item {
		width: 100%;
		margin-bottom: 20px;
	}
	.features-section .feature-item .feature-image-8 {
		margin-top: 60px;
	}
	.features-section .feature-item .feature-image-8 .image-1 {
		width: 100%;
		margin-right: 0px;
	}
	.features-section .feature-item .feature-image-8 .image-2 {
		left: 50px;
		top: -50px;
		width: 200px;
	}
	.features-section .feature-item .feature-image-8 .image-3 {
		right: 0px;
		width: 200px;
		bottom: -70px;
	}
	.home-page-8 .features-section .feature-item:nth-child(2) {
		margin-bottom: 120px;
	}
	.features-section .feature-item:nth-child(3) .feature-image-8 {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.features-section .feature-item:nth-child(3) .feature-image-8 .image-1 {
		width: 100%;
		margin-left: 0px;
	}
	.features-section .feature-item:nth-child(3) .feature-image-8 .image-2 {
		top: 0;
		left: 180px;
	}
	/* home 8 pages - end
	================================================== */
	/* home 9 pages - start
	================================================== */
	.banner-section .sass-banner-3 .banner-item-image {
		display: none;
	}
	.banner-section .sass-banner-3 {
		padding-top: 200px;
		padding-bottom: 100px;
		background-size: cover;
		background-image: -webkit-gradient(
			linear,
			left bottom,
			right top,
			from(#0eb9f9),
			to(#75e6ca)
		) !important;
		background-image: -webkit-linear-gradient(
			bottom left,
			#0eb9f9,
			#75e6ca
		) !important;
		background-image: -o-linear-gradient(
			bottom left,
			#0eb9f9,
			#75e6ca
		) !important;
		background-image: linear-gradient(
			to top right,
			#0eb9f9,
			#75e6ca
		) !important;
	}
	.banner-section .sass-banner-3 .banner-content {
		text-align: center;
	}
	.banner-section .sass-banner-3 .banner-content .title-text {
		font-size: 34px;
		line-height: 48px;
		margin-bottom: 20px;
	}
	.banner-section .sass-banner-3 .banner-content .btns-group > ul > li {
		margin-right: 10px;
	}
	.banner-section .sass-banner-3 .shape-1,
	.banner-section .sass-banner-3 .shape-2,
	.banner-section .sass-banner-3 .shape-3,
	.banner-section .sass-banner-3 .shape-4,
	.banner-section .sass-banner-3 .shape-5,
	.banner-section .sass-banner-3 .shape-6,
	.banner-section .sass-banner-3 .shape-7 {
		display: none;
	}
	/* home 9 pages - end
	================================================== */
	/* home 10 pages - start
	================================================== */
	.banner-section .sass-banner-4 .banner-item-image {
		display: none;
	}
	.banner-section .sass-banner-4 .banner-content {
		text-align: center;
	}
	.banner-section .sass-banner-4 .banner-content .title-text {
		font-size: 34px;
		line-height: 42px;
		margin-bottom: 20px;
	}
	.banner-section .sass-banner-4 .banner-content .btns-group > ul {
		display: table;
		margin: 0px auto;
	}
	.banner-section .sass-banner-4 {
		padding-top: 260px;
		padding-bottom: 80px;
	}
	.features-section .feature-item .feature-image-10 {
		margin-bottom: 80px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-10 {
		margin-bottom: 0px;
		margin-top: 60px;
	}
	/* home 10 pages - end
	================================================== */
	/* home 11 pages - start
	================================================== */
	.slider-section .appstore-main-carousel .item .mobile-banner-image > img {
		display: table;
		margin: 0px auto;
		max-width: 350px;
	}
	.slider-section .appstore-main-carousel .item .item-image {
		display: none;
	}
	.slider-section .appstore-main-carousel .item .item-content {
		padding-top: 0px;
		text-align: center;
	}
	.slider-section .appstore-main-carousel .item .item-content .btns-group > ul {
		width: 600px;
		display: table;
		margin: 0px auto;
	}
	.app-Category-section .Category-item .item-title {
		font-size: 21px;
		margin-bottom: 30px;
	}
	.app-Category-section .Category-item > ul > li {
		margin-bottom: 15px;
	}
	.app-Category-section .Category-item > ul > li:last-child {
		margin-bottom: 0px;
	}
	/* home 11 pages - end
	================================================== */
	/* app details 1 pages - start
	================================================== */
	.app-details-section .sidebar-section {
		margin-top: 60px;
	}
	/* app details 1 pages - end
	================================================== */
	/* about page - start
	================================================== */
	.about-section .about-image .item-image {
		width: 100%;
		margin: 0px;
	}
	.about-section .about-image .popup-video {
		right: 0px;
	}
	.about-section .about-content {
		padding: 0px;
		margin-top: 60px;
		text-align: center;
	}
	.service-section .section-title {
		margin-bottom: 30px;
	}
	.service-section .section-title .paragraph-text {
		width: 100%;
		margin: 0 auto;
		font-size: 18px;
		line-height: 30px;
	}
	.team-section .section-title {
		margin-bottom: 70px;
	}
	.team-section .team-member-bordered {
		width: 100%;
		margin-top: 30px;
		padding: 60px 30px;
	}
	.testimonial-section .testimonial-carousel .item.item-style-2 .hero-image {
		float: none;
		width: 500px;
		display: table;
		margin: 0 auto;
	}
	.testimonial-section .testimonial-carousel .item .testimonial-content {
		width: 80%;
		padding: 0px;
		margin: 0 auto;
		display: table;
		margin-top: 60px;
	}
	.testimonial-section .owl-theme .owl-nav {
		width: 100%;
		position: static;
		text-align: center;
	}
	.funfact-section .section-title .title-text {
		width: 100%;
	}
	/* about page - end
	================================================== */
	/* pricing pages - start
	================================================== */
	.pricing-section .bg-image .pricing-plan {
		max-width: 370px;
		margin: 30px auto 0px;
	}
	.pricing-section .pricing-tab-nav {
		margin-bottom: 60px;
	}
	.pricing-section .pricing-plan {
		max-width: 370px;
		margin: 30px auto 0px;
	}
	.pricing-section .review-btn .custom-btn-underline {
		color: #138afd;
	}
	.pricing-section .review-btn .custom-btn-underline:before {
		border-bottom: 2px solid #138afd;
	}
	.pricing-section .bg-image {
		padding: 0px !important;
		background-image: none !important;
	}
	/* pricing pages - end
	================================================== */
	/* team pages - start
	================================================== */
	.team-section .team-member-grid {
		width: 100%;
	}
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(2) {
		left: 133px;
	}
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(3) {
		right: 133px;
	}
	/* team pages - end
	================================================== */
	/* faq pages - start
	================================================== */
	.faq-section .tab-content {
		padding-left: 0px;
		padding-right: 0px;
	}
	.faq-section .faq-tabs-nav {
		width: 100%;
		padding: 30px 20px;
	}
	.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:before {
		left: -20px;
	}
	.faq-section .faq-tabs-nav > .contact-info > li {
		font-size: 14px;
	}
	/* faq pages - end
	================================================== */
	/* contact pages - start
	================================================== */
	.map-section .contact-form {
		right: 15px;
	}
	.contact-section .contact-info-item {
		width: 300px;
		margin: 30px auto 0px;
	}
	.map-section .contact-form {
		width: 100%;
		position: static;
		padding: 80px 30px;
		padding-bottom: 0px;
	}
	.map-section .google-map {
		height: 500px;
	}
	/* contact pages - end
	================================================== */
	/* blog pages - start
	================================================== */
	.blog-section .sidebar-section {
		margin-top: 80px;
	}
	/* blog pages - end
	================================================== */
	/* blog details pages - start
	================================================== */
	.blog-details-page-1 .breadcrumb-section .overlay-black {
		height: 400px;
	}
	.blog-details-page-1 .blog-details-section .blog-details-content {
		padding: 40px;
		margin-top: -150px;
	}
	.blog-details-section .blog-details-content .video-item {
		width: 100%;
	}
	.blog-details-section .blog-details-content .post-admin .admin-image {
		float: none;
		margin: 0px;
		display: table;
		margin-bottom: 30px;
	}
	.blog-details-section .blog-details-content .post-admin .admin-content {
		width: 100%;
	}
	.blog-details-section .blog-details-content .blockquote {
		margin: 50px 0px;
	}
	.blog-details-section .blog-details-content .tag-share-links .share-links {
		margin-top: 20px;
		float: left !important;
	}
	.blog-details-section .sidebar-section {
		margin-top: 80px;
	}
	/* blog details pages - end
	================================================== */
}

/* ====================================================================================================
3 - media screen and (max-width: 991px) - end --->>>for medium device
==================================================================================================== */
/* ====================================================================================================
4 - media screen and (max-width: 767px) - start --->>>For Mobile Device
==================================================================================================== */
/* ====================================================================================================
4 - media screen and (max-width: 767px) - end --->>>For Mobile Device
==================================================================================================== */
/* ====================================================================================================
5 - media screen and (max-width: 680px) - start
==================================================================================================== */
@media screen and (max-width: 580px) {
	/* header section - start
	================================================== */
	.header-section {
		padding: 30px 0px;
	}
	.sticky-header.stuck {
		padding: 20px 0px;
	}
	.header-section .brand-logo .brand-link {
		width: 80%;
	}
	.header-section .btns-group > ul > li {
		display: none;
	}
	.header-section .btns-group > ul > li:last-child {
		margin-top: -50px;
		display: inline-block;
	}
	/* header section - end
	================================================== */
	/* home 12 pages - start
	================================================== */
	.home-page-12,
	.app-details-page-2 {
		padding-top: 104px;
	}
	/* home 12 pages - end
	================================================== */
}

/* ====================================================================================================
5 - media screen and (max-width: 680px) - end
==================================================================================================== */
/* ====================================================================================================
6 - media screen and (max-width: 480px) - start
==================================================================================================== */
@media screen and (max-width: 480px) {
	/* global reset - start
	================================================== */
	.sec-ptb-160 {
		padding: 80px 0px;
	}
	.section-title {
		margin-bottom: 30px;
	}
	.section-title .title-text {
		font-size: 24px;
	}
	.section-title .paragraph-text {
		font-size: 14px;
		line-height: 24px;
	}

	.ac {
		padding-left: 28%;
	}
	/* global reset - start
	================================================== */
	/* footer section - start
	================================================== */
	.footer-section .newsletter-section .newsletter-form {
		padding: 15px;
	}
	.footer-section .newsletter-section .newsletter-form .form-item #email-input {
		height: 50px;
		padding: 0px 20px;
	}
	.footer-section
		.newsletter-section
		.newsletter-form
		.form-item
		.form-item-btn {
		right: 5px;
		height: 40px;
		line-height: 40px;
		padding: 0px 15px;
	}
	.footer-section .btns-group > ul > li {
		width: 100%;
		margin: 0px;
		text-align: center;
		margin-bottom: 15px;
	}
	.footer-section .btns-group > ul > li:last-child {
		margin-bottom: 0px;
	}
	.footer-section .footer-bottom {
		padding: 30px 0px;
		text-align: center;
	}
	.footer-section .footer-bottom .useful-links > ul {
		float: none;
		display: table;
		text-align: center;
		margin: 15px auto 0px;
	}
	.footer-section .footer-content {
		text-align: center;
	}
	.footer-section .footer-content .about-content .social-links > ul {
		display: table;
		margin: 0px auto;
	}
	/* footer section - end
	================================================== */
	/* sidebar menu - start
	================================================== */
	.sidebar-menu-wrapper .sidebar-menu {
		right: -320px;
		height: 100vh;
		min-width: 300px;
		max-width: 300px;
		padding: 40px 30px;
		overflow-y: scroll;
	}
	.sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li {
		margin: 0px;
		width: 100%;
		display: table;
		text-align: center;
		margin-bottom: 15px;
	}
	.sidebar-menu-wrapper .sidebar-menu .btns-group > ul > li:last-child {
		margin-bottom: 0px;
	}
	.sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li .icon {
		margin-right: 10px;
	}
	.sidebar-menu-wrapper .sidebar-menu .contact-info > ul > li {
		font-size: 14px;
	}
	/* sidebar menu - end
	================================================== */
	/* breadcrumb section - start
	================================================== */
	.breadcrumb-section {
		padding-top: 130px;
		padding-bottom: 60px;
	}
	.breadcrumb-section .section-title .title-text {
		font-size: 24px;
	}
	/* breadcrumb section - end
	================================================== */
	/* sidebar section - start
	================================================== */
	.sidebar-section .widget_search .form-item .search-btn {
		height: 45px;
		font-size: 18px;
		padding: 0px 15px;
		line-height: 45px;
	}
	.sidebar-section .widget_instagram > ul > li {
		width: 92px;
		height: 92px;
	}
	.sidebar-section .widget_newsletter .form-item .submit-btn {
		height: 45px;
		font-size: 18px;
		padding: 0px 15px;
		line-height: 45px;
	}
	.sidebar-section .widget_categories > ul > li > a,
	.sidebar-section .widget_categories > ul > li > .badge {
		font-size: 14px;
	}
	.sidebar-section .widget_cixposttype > ul > li .item-title {
		font-size: 16px;
	}
	/* sidebar section - end
	================================================== */
	/* home 1 pages - start
	================================================== */
	.banner-section .mobile-app-banner-1 .banner-content {
		padding-top: 0;
	}
	.banner-section .mobile-app-banner-1 .banner-content > h2 {
		font-size: 26px;
		line-height: 36px;
		margin-bottom: 20px;
	}
	.banner-section .mobile-app-banner-1 .banner-content > p {
		font-size: 14px;
		margin-bottom: 30px;
	}
	.banner-section .mobile-app-banner-1 .banner-content .btns-group > ul > li {
		width: 100%;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 5px;
	}
	.banner-section
		.mobile-app-banner-1
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.client-section .owl-theme .owl-nav {
		margin-top: 30px;
	}
	.client-section .client-carousel .item .client-logo {
		height: 80px;
	}
	.working-process-section .process-steps-list > ul > li {
		width: 100%;
		text-align: center;
		margin-bottom: 40px !important;
	}
	.working-process-section .process-steps-list > ul > li .item-icon {
		margin-bottom: 20px;
	}
	.extra-features-section .features-nav > .nav > .nav-item {
		width: 100%;
		margin-bottom: 40px;
	}
	.extra-features-section .features-nav > .nav > .nav-item:last-child {
		margin-bottom: 0px;
	}
	.extra-features-section .tab-content .tab-pane .feature-content .item-title {
		font-size: 24px;
	}
	.extra-features-section .tab-content .tab-pane .feature-content > p {
		font-size: 14px;
	}
	.extra-features-section
		.tab-content
		.tab-pane
		.feature-content
		.instructions-list
		> ul
		> li
		> i {
		margin-right: 10px;
	}
	.extra-features-section .features-nav {
		padding-bottom: 30px;
		margin-bottom: 30px;
	}
	.features-section .feature-item .feature-image-1 .circle-image {
		width: 250px;
	}
	.features-section .feature-item .feature-image-1 .phone-image {
		top: -15px;
		left: 54px;
		width: 220px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-1,
	.features-section .feature-item .feature-image-1 .comment-image-2 {
		width: 200px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-1 {
		left: 85px;
		top: 73px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-2 {
		left: 100px;
		bottom: 10px;
	}
	.features-section .feature-item .feature-content .btns-group > ul > li {
		width: 100%;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 15px;
	}
	.features-section
		.feature-item
		.feature-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-content .section-title .title-text {
		font-size: 20px;
	}
	.features-section
		.feature-item
		.feature-content
		.info-list
		> ul
		> li
		.info-text {
		font-size: 14px;
		line-height: 24px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
		top: 0px;
		right: 55px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-2 {
		width: 200px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-1 {
		top: 30px;
		left: 15px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-1
		.comment-image-2 {
		bottom: 15px;
		right: 10px;
	}
	.service-section .section-title {
		margin-bottom: 0px;
	}
	.service-section .section-title .paragraph-text {
		font-size: 16px;
		line-height: 28px;
	}
	.testimonial-section .testimonial-carousel .item .hero-image {
		width: 100%;
		margin: 0 auto 40px;
	}
	.testimonial-section .testimonial-carousel .item .hero-image .icon {
		top: -35px;
		right: 25px;
	}
	.testimonial-section .testimonial-carousel .item {
		padding-left: 30px;
		padding-bottom: 45px;
	}
	.app-section .app-content .section-title .title-text {
		line-height: 30px;
	}
	.section-title .title-text {
		font-size: 20px;
	}
	.app-section .app-content .btns-group > ul li {
		width: 100%;
		margin-right: 0px;
		margin-bottom: 5px;
		text-align: center;
	}
	.app-section .app-content .btns-group > ul li:last-child {
		margin-bottom: 0px;
	}
	.app-section .app-image .circle-image {
		width: 250px;
	}
	.app-section .app-image .phone-image {
		right: 16px;
		width: 220px;
	}
	.funfact-section .bg-image {
		padding: 0px;
		background-image: none !important;
	}
	.funfact-section .counter-items-list {
		margin-bottom: 40px;
	}
	.faq-section .queistions-container {
		margin-bottom: 60px;
	}
	/* home 1 pages - end
	================================================== */
	/* home 2 pages - start
	================================================== */
	.banner-section .mobile-app-banner-2 {
		height: auto;
		padding-top: 125px;
		padding-bottom: 100px;
	}
	.banner-section .mobile-app-banner-2 .mobile-banner-image > img {
		display: table;
		max-width: 320px;
		margin: 0px auto;
	}
	.banner-section .mobile-app-banner-2 .banner-content > h2,
	.banner-section .mobile-app-banner-2 .banner-content > h1 {
		font-size: 26px;
		line-height: 36px;
	}
	.banner-section .mobile-app-banner-2 .banner-content .btns-group > ul > li {
		width: 100%;
		text-align: center;
		margin-right: 0px;
		margin-bottom: 10px;
	}
	.banner-section
		.mobile-app-banner-2
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.banner-section .mobile-app-banner-2 .banner-content > p {
		font-size: 14px;
		margin-bottom: 40px;
	}
	.features-section .feature-item .feature-image-2 .item-image {
		width: 100%;
	}
	.features-section .feature-item .feature-image-2 {
		margin-top: 0px;
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-image-2 .bg-image {
		width: 250px;
		display: table;
		margin: 75px auto 0px;
	}
	.features-section .feature-item .feature-image-2 .phone-image {
		right: 60px;
		width: 200px;
		top: -50px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1,
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		width: 200px;
		position: absolute;
	}
	.features-section .feature-item .feature-image-2 .comment-image-1 {
		top: 30px;
		right: 100px;
	}
	.features-section .feature-item .feature-image-2 .comment-image-2 {
		right: 15px;
		bottom: 15px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-1 {
		top: 0px;
		left: 60px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-2 {
		top: 50%;
		right: 50px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-2 .shape-image-3 {
		left: 75px;
		bottom: -20px;
		width: 50px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .bg-image {
		display: table;
		margin: 75px auto 0px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .phone-image {
		left: 65px;
		right: unset;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-2 {
		width: 200px;
		position: absolute;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-1 {
		top: 0;
		left: 0;
		right: unset;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-2
		.comment-image-2 {
		left: 110px;
		right: unset;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-1 {
		left: 55px;
		top: -15px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-2 .shape-image-3 {
		left: 70px;
		bottom: -20px;
	}
	.app-section .app-image-2 .bg-image {
		float: none;
		width: 250px;
		display: table;
		margin: 0 auto;
	}
	.app-section .app-image-2 {
		padding-top: 60px;
		float: none !important;
	}
	.app-section .app-image-2 .phone-image {
		top: 0px;
		width: 200px;
		right: 55px;
	}
	.app-section .app-image-2 .shape-image-1 {
		left: 70px;
		top: 30px;
		width: 50px;
	}
	.app-section .app-image-2 .shape-image-2 {
		right: 50px;
		top: 130px;
		width: 50px;
	}
	.app-section .app-image-2 .shape-image-3 {
		left: 80px;
		bottom: -10px;
		width: 50px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.paragraph-text {
		font-size: 14px;
		margin-bottom: 30px;
	}
	/* home 2 pages - end
	================================================== */
	/* home 3 pages - start
	================================================== */
	.home-page-3 {
		padding-top: 114px;
	}
	.slider-section .mobileapp-main-carousel .item.first-item h2 {
		font-size: 18px;
		line-height: 28px;
		margin-bottom: 30px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.title-text {
		font-size: 26px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.first-item
		.subscribe-form
		.form-item
		input,
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.newsletter-form
		.form-item
		.email-input {
		height: 50px;
		padding: 0px 20px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.first-item
		.subscribe-form
		.form-item
		.submit-btn,
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.newsletter-form
		.form-item
		.form-item-btn {
		height: 40px;
		font-size: 14px;
		line-height: 40px;
		padding: 0px 20px;
	}
	.slider-section .owl-theme .owl-dots {
		left: 50%;
		top: unset;
		right: unset;
		bottom: 20px;
		margin-top: 0px !important;
		-webkit-transform: translateX(-50%);
		-ms-transform: translateX(-50%);
		transform: translateX(-50%);
	}
	.slider-section .owl-theme .owl-dots .owl-dot {
		margin: 0px 5px;
		display: inline-block;
	}
	.features-section .feature-item .feature-image-3 .bg-image {
		width: 300px;
	}
	.features-section .feature-item .feature-image-3 .phone-image {
		width: 200px;
	}
	.features-section .feature-item .feature-image-3 .leaf-image-1 {
		left: 0;
		width: 130px;
	}
	.features-section .feature-item .feature-image-3 .leaf-image-2 {
		top: 0;
		right: 0;
		width: 130px;
	}
	.features-section .feature-item .feature-image-3 .man-image-2 {
		right: -15px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-3 .comment-image {
		width: 60px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
		width: 300px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
		left: 100px;
		width: 150px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
		top: 45px;
		right: 0px;
		width: 150px;
		position: absolute;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
		right: 5px;
		width: 110px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
		left: 20px;
		width: 120px;
	}
	/* home 3 pages - end
	================================================== */
	/* home 4 pages - start
	================================================== */
	.banner-section .mobile-app-banner-4 .banner-content > h2 {
		font-size: 26px;
		line-height: 36px;
	}
	.banner-section .mobile-app-banner-4 {
		padding-top: 150px;
	}
	.banner-section .mobile-app-banner-4 .banner-content > p {
		font-size: 14px;
		margin-bottom: 40px;
	}
	.banner-section .mobile-app-banner-4 .banner-content .btns-group > ul {
		width: 100%;
	}
	.banner-section .mobile-app-banner-4 .banner-content .btns-group > ul > li {
		width: 100%;
		display: table;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 10px;
	}
	.banner-section
		.mobile-app-banner-4
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-image-4 .phone-image {
		width: 140px;
	}
	.features-section .feature-item .feature-image-4 .floor-image {
		bottom: 20px;
		width: 250px;
		left: 30px;
	}
	.features-section .feature-item .feature-image-4 .man-image-1 {
		bottom: 40px;
		left: 10px;
		width: 90px;
	}
	.features-section .feature-item .feature-image-4 .flower-image-2 {
		width: 30px;
		bottom: 40px;
		right: 50px;
	}
	.features-section .feature-item .feature-image-4 .comment-1-image {
		top: 40px;
		left: 25px;
		width: 140px;
	}
	.features-section .feature-item .feature-image-4 .comment-2-image {
		top: 120px;
		left: 80px;
		width: 140px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-1 {
		bottom: 42px;
		left: 60px;
		width: 30px;
	}
	.features-section .feature-item .feature-image-4 .shape-image-2 {
		top: 40%;
		right: 70px;
		width: 40px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 {
		margin-top: 60px;
		padding-bottom: 40px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
		top: 40px;
		left: 120px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
		top: 90px;
		right: 120px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
		top: 150px;
		left: 120px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
		bottom: 0px;
		right: 70px;
		width: 90px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
		bottom: 0px;
		left: -10px;
		width: 180px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
		width: 60px;
		right: 30px;
		bottom: 50px;
	}
	/* home 4 pages - end
	================================================== */
	/* home 5 pages - start
	================================================== */
	.banner-section .mobile-app-banner-5 .banner-content .title-text {
		font-size: 24px;
	}
	.banner-section .mobile-app-banner-5 {
		padding-top: 130px;
		padding-bottom: 160px;
	}
	.banner-section .mobile-app-banner-5 .mobile-banner-image > img {
		max-width: 270px;
	}
	.banner-section .mobile-app-banner-6 .banner-content .btns-group > ul > li {
		width: 100%;
		display: table;
		margin-right: 0px;
		margin-bottom: 5px;
		text-align: center;
	}
	.banner-section
		.mobile-app-banner-6
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.banner-section .mobile-app-banner-6 .banner-content {
		padding-top: 0px;
	}
	.banner-section .mobile-app-banner-6 {
		padding-top: 150px;
	}
	.home-page-6 .banner-section {
		margin-bottom: 0px;
	}
	.service-section .service-grid-item {
		width: 100%;
		display: table;
		margin: 30px auto 0px;
	}
	.features-section .feature-item .feature-image-6 {
		float: none !important;
	}
	.features-section .feature-item .feature-image-6 .bg-image {
		width: 250px;
		display: table;
		margin: 0px auto;
	}
	.features-section .feature-item .feature-image-6 .phone-image {
		top: 25px;
		left: 85px;
		width: 200px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1,
	.features-section .feature-item .feature-image-6 .comment-image-2 {
		width: 200px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1 {
		top: 100px;
		left: 125px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-1 {
		left: 80px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-2 {
		right: 65px;
	}
	.features-section .feature-item .feature-image-6 .shape-image-3 {
		left: 90px;
		bottom: 55px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .bg-image {
		margin: 0px auto;
	}
	.features-section .feature-item:nth-child(2) .feature-image-6 .phone-image {
		top: 80px;
		right: 75px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1,
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		width: 200px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-1 {
		top: 110px;
		left: 25px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-6
		.comment-image-2 {
		left: 50px;
		bottom: 40px;
	}
	.working-process-section {
		overflow: hidden;
	}
	.working-process-section .process-item {
		padding: 0px;
		text-align: center;
	}
	.working-process-section .process-item .item-icon {
		float: none;
		height: auto;
		line-height: 1;
		display: table;
		margin: 0px auto 30px;
	}
	.working-process-section .process-item .item-content {
		width: 100%;
		display: table;
	}
	.working-process-section .process-phone-image .phone-image {
		top: 30px;
		width: 250px;
	}
	/* home 5 pages - end
	================================================== */
	/* home 6 pages - start
	================================================== */
	.banner-section .mobile-app-banner-6 .mobile-banner-image > img {
		max-width: 270px;
	}
	.banner-section .mobile-app-banner-6 .banner-content h2 {
		font-size: 22px;
		line-height: 30px;
		margin-bottom: 20px;
	}
	.banner-section .mobile-app-banner-6 .banner-content p {
		font-size: 14px;
		margin-bottom: 30px;
	}
	.home-page-6 .service-section {
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-image-6 .phone-image {
		top: 25px;
		left: 65px;
		width: 200px;
	}
	.features-section .feature-item .feature-image-6 .comment-image-1 {
		left: 98px;
	}
	.working-process-section .process-phone-image {
		display: none;
	}
	/* home 6 pages - end
	================================================== */
	/* home 7 pages - start
	================================================== */
	.banner-section .sass-banner-1 .banner-item-image {
		display: none;
	}
	.banner-section .sass-banner-1 .banner-content p {
		width: 100%;
		font-size: 14px;
	}
	.banner-section .sass-banner-1 .banner-content .newsletter-form .form-item {
		width: 100%;
	}
	.home-page-7 .banner-section {
		margin-bottom: 0px;
	}
	.banner-section .sass-banner-1 {
		padding-top: 150px;
	}
	.banner-section .sass-banner-1 .banner-content .title-text {
		font-size: 24px;
	}
	.features-section .feature-item .feature-image-7 {
		float: none !important;
	}
	.features-section .feature-item .feature-image-7 .bg-image {
		width: 100%;
	}
	.features-section .feature-item .feature-image-7 .big-chart {
		top: 55px;
		width: 100%;
	}
	.features-section .feature-item .feature-image-7 .man-image-1 {
		left: 30px;
		width: 95px;
		bottom: 65px;
	}
	.features-section .feature-item .feature-image-7 .man-image-2 {
		left: -50px;
		bottom: 65px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-7 .man-image-3 {
		bottom: 60px;
		width: 110px;
		right: -15px;
	}
	.features-section .feature-item .feature-image-7 .leaf-image {
		width: 40px;
		bottom: 60px;
		right: -20px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-1 {
		width: 20px;
		right: 160px;
		bottom: 73px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-2 {
		width: 20px;
		right: 130px;
		bottom: 73px;
	}
	.features-section .feature-item .feature-image-7 .circle-chart-3 {
		width: 20px;
		right: 100px;
		bottom: 73px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .bg-image {
		width: 100%;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .big-chart {
		left: 0;
		top: 45px;
		width: 100%;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
		top: -12px;
		left: 110px;
		width: 175px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
		left: 0px;
		bottom: 30px;
		width: 120px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
		right: 0px;
		bottom: 30px;
		width: 235px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
		right: 0px;
		bottom: 30px;
		width: 140px;
		z-index: 1;
	}
	.software-section .software-container .software-item {
		display: table;
		margin: 30px auto 0px;
	}
	.software-section .software-container .software-item:nth-child(1),
	.software-section .software-container .software-item:nth-child(2),
	.software-section .software-container .software-item:nth-child(3),
	.software-section .software-container .software-item:nth-child(4),
	.software-section .software-container .software-item:nth-child(5),
	.software-section .software-container .software-item:nth-child(6),
	.software-section .software-container .software-item:nth-child(7) {
		top: unset;
		left: unset;
		right: unset;
		bottom: unset;
		-webkit-transform: unset;
		-ms-transform: unset;
		transform: unset;
		position: static;
		width: 130px;
		height: 130px;
	}
	.software-section
		.software-container
		.software-item:nth-child(1)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(2)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(3)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(4)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(5)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(6)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(7)
		.software-logo {
		padding: 35px;
	}
	.free-trail-section .free-trail-form {
		padding: 15px;
	}
	.free-trail-section .free-trail-form .form-item .form-item-btn {
		width: 100%;
		display: block;
		position: static;
		-webkit-transform: unset;
		-ms-transform: unset;
		transform: unset;
		padding: 0px 40px;
		margin-top: 15px;
	}
	/* home 7 pages - end
	================================================== */
	/* home 8 pages - start
	================================================== */
	.banner-section .sass-banner-2 {
		padding-top: 130px;
		padding-bottom: 65px;
	}
	.banner-section .sass-banner-2 .banner-content .title-text {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 20px;
	}
	.banner-section .sass-banner-2 .banner-content {
		padding-top: 0px;
	}
	.banner-section
		.sass-banner-2
		.banner-content
		.newsletter-form
		.form-item
		.submit-btn {
		height: 40px;
		padding: 0px 20px;
		line-height: 40px;
	}
	.banner-section
		.sass-banner-2
		.banner-content
		.newsletter-form
		.form-item
		input {
		height: 50px;
	}
	.home-page-8 .features-section .feature-item:nth-child(1) {
		margin-bottom: 100px;
	}
	.features-section .feature-item:nth-child(1) .feature-image-2 {
		margin-top: 0px;
		margin-bottom: 60px;
	}
	.features-section .feature-item .feature-image-8 .image-2 {
		left: 20px;
		top: -50px;
		width: 150px;
	}
	.features-section .feature-item .feature-image-8 .image-3 {
		right: 0px;
		width: 150px;
		bottom: -50px;
	}
	.features-section .feature-item:nth-child(3) .feature-image-8 .image-2 {
		top: 0px;
		left: 100px;
	}
	.features-section .feature-item:nth-child(3) .feature-image-8 .image-3 {
		right: 0px;
		bottom: 0px;
	}
	.software-section .software-container .software-item:nth-child(1),
	.software-section .software-container .software-item:nth-child(2),
	.software-section .software-container .software-item:nth-child(3),
	.software-section .software-container .software-item:nth-child(4),
	.software-section .software-container .software-item:nth-child(5),
	.software-section .software-container .software-item:nth-child(6),
	.software-section .software-container .software-item:nth-child(7) {
		width: 100px;
		height: 100px;
	}
	.software-section
		.software-container
		.software-item:nth-child(1)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(2)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(3)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(4)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(5)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(6)
		.software-logo,
	.software-section
		.software-container
		.software-item:nth-child(7)
		.software-logo {
		padding: 25px;
	}
	/* home 8 pages - end
	================================================== */
	/* home 9 pages - start
	================================================== */
	.banner-section .sass-banner-3 .banner-content .title-text {
		font-size: 24px;
		line-height: 36px;
	}
	.banner-section .sass-banner-3 .banner-content p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 30px;
	}
	.banner-section .sass-banner-3 .banner-content .btns-group > ul > li {
		width: 100%;
		display: table;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 10px;
	}
	.banner-section
		.sass-banner-3
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.banner-section
		.sass-banner-3
		.banner-content
		.btns-group
		> ul
		> li
		.info-text {
		width: 100%;
		display: table;
		font-size: 14px;
	}
	.banner-section .sass-banner-3 {
		padding-top: 160px;
	}
	.features-section .feature-item .feature-content .feature-item-title {
		font-size: 24px;
		margin-bottom: 20px;
	}
	.features-section
		.feature-item
		.feature-content
		.service-list
		> ul
		> li
		.item-icon {
		float: none;
		display: table;
		margin: 0px auto 30px;
	}
	.features-section
		.feature-item
		.feature-content
		.service-list
		> ul
		> li
		.item-content {
		width: 100%;
		display: table;
		text-align: center;
	}
	/* home 9 pages - end
	================================================== */
	/* home 10 pages - start
	================================================== */
	.banner-section .sass-banner-4 {
		padding-top: 170px;
		padding-bottom: 60px;
	}
	.banner-section .sass-banner-4 .banner-content .title-text {
		font-size: 24px;
		line-height: 36px;
	}
	.banner-section .sass-banner-4 .shape-1,
	.banner-section .sass-banner-4 .shape-2,
	.banner-section .sass-banner-4 .shape-3,
	.banner-section .sass-banner-4 .shape-4,
	.banner-section .sass-banner-4 .shape-5,
	.banner-section .sass-banner-4 .shape-6 {
		display: none;
	}
	.banner-section .sass-banner-4 .banner-content p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 40px;
	}
	.banner-section .sass-banner-4 .banner-content .btns-group > ul > li {
		width: 100%;
		display: table;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 15px;
	}
	.banner-section
		.sass-banner-4
		.banner-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.banner-section
		.sass-banner-4
		.banner-content
		.btns-group
		> ul
		> li
		.info-text {
		width: 100%;
		padding: 0px;
		display: table;
		font-size: 14px;
		font-weight: 500;
		text-align: center;
	}
	.client-section .title-paragraph-text {
		font-size: 16px;
		max-width: 100%;
		line-height: 28px;
		margin: 0 auto 50px;
	}
	.features-section .feature-item .feature-image-10 .big-image {
		width: 100%;
	}
	.features-section .feature-item .feature-image-10 .small-image-1 {
		top: 45px;
		left: 0px;
		width: 160px;
	}
	.features-section .feature-item .feature-image-10 .small-image-2 {
		right: 0px;
		bottom: -30px;
		width: 160px;
	}
	.features-section .feature-item .feature-image-10 {
		margin-bottom: 60px;
	}
	.features-section .feature-item .feature-image-10 .shape-1 {
		top: -20px;
		left: 40px;
		width: 50px;
	}
	.features-section .feature-item .feature-image-10 .shape-2 {
		left: 90px;
		bottom: 0px;
		width: 50px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-10 .big-image {
		margin-left: 0px;
		margin-right: 0px;
	}
	.features-section
		.feature-item:nth-child(2)
		.feature-image-10
		.small-image-1 {
		top: 35px;
		left: 25px;
	}
	/* home 10 pages - end
	================================================== */
	/* home 11 pages - start
	================================================== */
	.slider-section .appstore-main-carousel .item .mobile-banner-image > img {
		max-width: 250px;
	}
	.slider-section .appstore-main-carousel {
		padding-top: 135px;
	}
	.slider-section .appstore-main-carousel .item .item-content h1 {
		font-size: 24px;
		line-height: 36px;
	}
	.slider-section .appstore-main-carousel .item .item-content p {
		font-size: 14px;
		max-width: 100%;
		line-height: 26px;
		margin-bottom: 30px;
	}
	.slider-section .appstore-main-carousel .item .item-content .btns-group > ul {
		width: 100%;
	}
	.slider-section
		.appstore-main-carousel
		.item
		.item-content
		.btns-group
		> ul
		> li {
		width: 100%;
		display: table;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 5px;
	}
	.slider-section
		.appstore-main-carousel
		.item
		.item-content
		.btns-group
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.mobile-app-section .section-title .custom-btn-underline {
		display: table;
		margin: 30px auto 0px;
		float: none !important;
	}
	.mobile-app-section .section-title .title-text {
		width: 100%;
		display: table;
		font-size: 30px;
		text-align: center;
		float: none !important;
	}
	.mobile-app-section .app-item {
		width: 230px;
		display: table;
		margin: 30px auto 0px;
	}
	.app-Category-section .Category-item {
		text-align: center;
	}
	.app-Category-section .Category-item > ul > li > a {
		font-size: 14px;
		font-weight: 400;
	}
	/* home 11 pages - end
	================================================== */
	/* home 12 pages - start
	================================================== */
	.search-section .banner-content h1 {
		font-size: 22px;
		line-height: 30px;
		margin-bottom: 30px;
	}
	.search-section .banner-content h3 {
		font-size: 15px;
		line-height: 20px;
		margin-bottom: 20px;
	}
	.search-section .banner-content .search-form .form-item input {
		height: 50px;
		padding: 0px 30px;
	}
	.search-section .banner-content .search-form .form-item .search-btn {
		right: 30px;
		font-size: 18px;
		-webkit-transform: translateY(-50%);
		-ms-transform: translateY(-50%);
		transform: translateY(-50%);
	}
	.mobile-app-section .tab-content .tab-pane .app-item {
		width: 230px;
		display: table;
		margin: 30px auto 0px;
	}
	/* home 12 pages - end
	================================================== */
	/* app details 1 pages - start
	================================================== */
	.app-details-section .app-details-content .app-item {
		padding: 30px;
	}
	.app-details-section .app-details-content .app-item .app-image {
		width: 100%;
		height: auto;
		text-align: center;
		float: none !important;
	}
	.app-details-section .app-details-content .app-item .rating-star {
		display: table;
		position: static;
		margin: 15px auto 0px;
	}
	.app-details-section .app-details-content .app-item .app-content {
		width: 100%;
		padding: 0px;
		display: table;
		margin-top: 30px;
		text-align: center;
	}
	.app-details-section
		.app-details-content
		.app-item
		.app-content
		.item-tag
		> ul {
		display: table;
		margin: 0px auto;
	}
	.app-details-section .app-details-content .app-item .btns-group {
		margin-top: 30px;
	}
	.app-details-section .app-details-content .app-item .btns-group > ul > li {
		width: 100%;
		display: table;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 15px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.overall-review-nav {
		width: 100%;
		overflow-x: scroll;
		margin-bottom: 40px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.overall-review-nav
		> ul {
		width: 450px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.overall-review-nav
		> ul
		> li {
		margin-right: 30px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.overall-review-nav
		> ul
		> li:last-child {
		margin-right: 0px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.overall-review-nav
		> ul
		> li
		> a {
		font-size: 18px;
		padding-bottom: 15px;
	}
	.app-details-section .app-details-content .overall-review {
		padding: 30px;
	}
	.app-details-section .app-details-content .overall-review .write-review-btn {
		margin-bottom: 40px;
		text-align: center !important;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.tab-content
		.pane-item-title {
		text-align: center;
	}
	.sidebar-section.app-details-sidebar .widget_search .form-item .search-btn {
		height: 45px;
		font-size: 16px;
		line-height: 45px;
	}
	.app-details-section
		.app-details-content
		.overall-review
		.tab-content
		#review-pane
		.item-ratings
		> ul
		> li
		.single-experties {
		width: 100%;
		margin-right: 0px;
	}
	/* app details 1 pages - end
	================================================== */
	/* about page - start
	================================================== */
	.about-section .about-image .popup-video {
		right: 20px;
		width: 80px;
		height: 80px;
		bottom: 30px;
		line-height: 80px;
	}
	.about-section .about-content .title-text {
		font-size: 24px;
		line-height: 36px;
		margin-bottom: 30px;
	}
	.service-section .section-title .paragraph-text {
		font-size: 18px;
		line-height: 30px;
	}
	.funfact-section .section-title .title-text {
		width: 100%;
		line-height: 42px;
		font-size: 30px;
	}
	.funfact-section .bg-image {
		background-size: 100% 100%;
	}
	.funfact-section .counter-items-list > ul > li {
		margin: 0px;
		width: 100%;
		display: table;
		margin-top: 40px;
		text-align: center;
	}
	.testimonial-section .testimonial-carousel .item.item-style-2 .hero-image {
		width: 100%;
	}
	.testimonial-section .testimonial-carousel .item .hero-image .icon {
		width: 60px;
		height: 60px;
		font-size: 24px;
		line-height: 64px;
	}
	.testimonial-section
		.testimonial-carousel
		.item.item-style-2
		.hero-image
		.shape-image {
		bottom: 0px;
	}
	.testimonial-section .testimonial-carousel .item .testimonial-content {
		width: 100%;
	}
	.testimonial-section
		.testimonial-carousel
		.item
		.testimonial-content
		.paragraph-text {
		font-size: 14px;
		line-height: 26px;
	}
	/* about page - end
	================================================== */
	/* pricing pages - start
	================================================== */
	.pricing-section .bg-image .pricing-plan {
		max-width: 100%;
	}
	.pricing-section .pricing-plan {
		max-width: 100%;
	}
	/* pricing pages - end
	================================================== */
	/* testimonial pages - start
	================================================== */
	.testimonial-section .review-item .reviewer-image {
		width: 130px;
		display: table;
		position: static;
		-webkit-transform: unset;
		-ms-transform: unset;
		transform: unset;
		margin: 0px auto 30px;
	}
	.testimonial-section .review-item .reviewer-content {
		width: 100%;
		padding: 0px;
		text-align: center;
	}
	.testimonial-section .review-item .reviewer-content .reviewer-comment {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 20px;
	}
	.testimonial-section .review-item .reviewer-content .rating-star > ul {
		float: none;
		display: table;
		margin: 0 auto;
	}
	.testimonial-section .review-item .reviewer-content .reviewer-info {
		margin-bottom: 20px;
	}
	/* testimonial pages - end
	================================================== */
	/* team pages - start
	================================================== */
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(2) {
		left: 125px;
	}
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(3) {
		right: 125px;
	}
	/* team pages - end
	================================================== */
	/* faq pages - start
	================================================== */
	.faq-section .faq-tabs-nav > .nav {
		margin-bottom: 50px;
	}
	.faq-section .faq-tabs-nav {
		padding: 30px;
		margin-bottom: 30px;
	}
	.faq-section .faq-tabs-nav > .nav > .nav-item > .nav-link:before {
		left: -30px;
	}
	.faq-section .contact-info > p {
		width: 100%;
	}
	/* faq pages - end
	================================================== */
	/* blog pages - start
	================================================== */
	.blog-section .big-blog-item .blog-content {
		padding: 25px;
	}
	.blog-section .big-blog-item .blog-content .blog-title .title-link {
		font-size: 20px;
		line-height: 30px;
	}
	.blog-section .big-blog-item .blog-content .item-paragraph {
		font-size: 14px;
	}
	.blog-section .big-blog-item .blog-image .post-date {
		left: 25px;
		bottom: 25px;
	}
	.blog-section .big-blog-item .blog-content .item-paragraph {
		margin-bottom: 20px;
	}
	/* blog pages - end
	================================================== */
	/* blog details pages - start
	================================================== */
	.blog-details-page-1 .blog-details-section .blog-details-content {
		margin-top: -90px;
		padding: 40px 20px;
	}
	.blog-details-section .blog-details-content .blog-title {
		font-size: 22px;
		line-height: 30px;
	}
	.blog-details-page-1 .breadcrumb-section .overlay-black {
		height: 215px;
	}
	.blog-details-section .blog-details-content .title-text {
		font-size: 18px;
	}
	.blog-details-section .blog-details-content .blockquote {
		padding: 30px 25px;
		border-left: 3px solid #138afd;
	}
	.blog-details-section .blog-details-content .blockquote > p {
		font-size: 14px;
		line-height: 24px;
	}
	.blog-details-section .blog-details-content .features-list > ul {
		width: 100%;
	}
	.blog-details-section .blog-details-content .post-admin {
		padding: 30px;
	}
	.blog-details-section .blog-details-content .post-admin .admin-content > p {
		font-size: 14px;
		line-height: 24px;
		margin-bottom: 30px;
	}
	.blog-details-section
		.blog-details-content
		.peoples-comments
		.comment-item
		.hero-image {
		float: none;
		margin: 0px;
		display: table;
		margin-bottom: 30px;
	}
	.blog-details-section
		.blog-details-content
		.peoples-comments
		.comment-item
		.comment-content {
		width: 100%;
	}
	/* blog details pages - end
	================================================== */
	/* 404 error pages - start
	================================================== */
	.common-page .brand-logo,
	.common-page .social-links {
		display: none;
	}
	.error-section .common-container .item-content {
		width: 100%;
	}
	.common-page .common-container .shape-1,
	.common-page .common-container .shape-2,
	.common-page .common-container .shape-3,
	.common-page .common-container .shape-4,
	.common-page .common-container .shape-5,
	.common-page .common-container .shape-6,
	.common-page .common-container .shape-7,
	.common-page .common-container .shape-8,
	.common-page .common-container .shape-9 {
		display: none;
	}
	.common-page .common-container .item-content .title-text {
		font-size: 42px;
	}
	.common-page .common-container .item-content > p {
		font-size: 16px;
		line-height: 28px;
	}
	/* 404 error pages - end
	================================================== */
	/* coming soon pages - start
	================================================== */
	.coming-soon-section .common-container .item-content .email-form .form-item {
		width: 100%;
	}
	.common-page .common-container .item-content .title-text {
		font-size: 36px;
	}
	.common-page .common-container .item-content > p {
		font-size: 14px;
		line-height: 26px;
	}
	.coming-soon-section .common-container .item-content .countdown-timer ul li {
		margin-right: 20px;
		padding-right: 20px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li:before {
		top: 5px;
		right: -4px;
		font-size: 30px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li:last-child {
		margin-right: 0px;
		padding-right: 0px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li
		strong {
		font-size: 36px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li
		small {
		letter-spacing: 0px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.email-form
		.form-item
		.submit-btn {
		height: 35px;
		padding: 0px 20px;
		line-height: 35px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.email-form
		.form-item
		input {
		height: 45px;
	}
	.form-item input {
		height: 45px;
	}
	/* coming soon pages - end
	================================================== */
	/* register pages (login) - start
	================================================== */
	.common-page .common-container .item-content .title-text {
		font-size: 30px;
		margin-bottom: 20px;
	}
	.common-page .common-container .item-content > p {
		margin-bottom: 30px;
	}
	.register-section
		.common-container
		.item-content
		.register-form
		.forgot-password {
		margin-bottom: 30px;
	}
	/* register pages (login) - end
	================================================== */
}

/* ====================================================================================================
6 - media screen and (max-width: 480px) - end
==================================================================================================== */
/* ====================================================================================================
7 - media screen and (max-width: 320px) - start
==================================================================================================== */
@media screen and (max-width: 320px) {
	/* global reset - start
	================================================== */
	.section-title .title-text {
		font-size: 22px;
	}
	.post-meta > ul > li > .post-admin .admin-image {
		width: 35px;
		height: 35px;
		margin: -10px 0px;
		margin-right: 5px;
	}
	.post-meta > ul > li {
		margin-right: 20px;
	}
	/* global reset - start
	================================================== */
	/* footer section - start
	================================================== */
	.footer-section .newsletter-section .section-title .title-text {
		font-size: 24px;
		max-width: 100%;
	}
	/* footer section - start
	================================================== */
	/* breadcrumb section - start
	================================================== */
	.breadcrumb-section .section-title .title-text {
		font-size: 20px;
	}
	/* breadcrumb section - start
	================================================== */
	/* breadcrumb section - start
	================================================== */
	.sidebar-section .widget_instagram > ul > li {
		width: 79px;
		height: 79px;
	}
	/* breadcrumb section - start
	================================================== */
	/* home 1 pages - start
	================================================== */
	.banner-section .mobile-app-banner-1 .banner-content > h2 {
		font-size: 23px;
		line-height: 30px;
	}
	.features-section .feature-item .feature-image-1 .phone-image {
		left: 34px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-1 {
		left: 65px;
	}
	.features-section .feature-item .feature-image-1 .comment-image-2 {
		left: 80px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-1 .phone-image {
		top: 0px;
		right: 35px;
	}
	/* home 1 pages - end
	================================================== */
	/* home 2 pages - start
	================================================== */
	.banner-section .mobile-app-banner-2 .mobile-banner-image > img {
		display: table;
		max-width: 270px;
		margin: 0px auto;
	}
	.banner-section .mobile-app-banner-2 .banner-content > h2,
	.banner-section .mobile-app-banner-2 .banner-content > h1 {
		font-size: 23px;
		line-height: 32px;
	}
	.features-section .feature-item .feature-content .feature-item-title {
		font-size: 24px;
		margin-top: 30px;
		margin-bottom: 30px;
	}
	/* home 2 pages - end
	================================================== */
	/* home 3 pages - start
	================================================== */
	.slider-section .mobileapp-main-carousel .item.first-item h2 {
		font-size: 16px;
		line-height: 26px;
	}
	.slider-section
		.mobileapp-main-carousel
		.item.second-item
		.slider-content
		.title-text {
		font-size: 20px;
	}
	.features-section .feature-item .feature-image-3 {
		margin-top: 60px;
		padding-top: 50px;
	}
	.features-section .feature-item .feature-image-3 .bg-image {
		width: 250px;
	}
	.features-section .feature-item .feature-image-3 .phone-image {
		width: 150px;
		top: 0px;
	}
	.features-section .feature-item .feature-image-3 .man-image-1 {
		left: 35px;
		width: 65px;
	}
	.features-section .feature-item .feature-image-3 .man-image-2 {
		right: 15px;
		width: 90px;
	}
	.features-section .feature-item .feature-image-3 .comment-image {
		width: 60px;
		top: 55px;
		left: 115px;
	}
	.features-section .feature-item .feature-image-3 .leaf-image-1 {
		left: 30px;
		top: -50px;
		width: 100px;
	}
	.features-section .feature-item .feature-image-3 .leaf-image-2 {
		top: -30px;
		right: 20px;
		width: 100px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 {
		margin-bottom: 60px;
		padding-top: 50px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .bg-image {
		width: 250px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .phone-image {
		left: 95px;
		top: 55px;
		width: 115px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-1 {
		left: 25px;
		width: 100px;
		bottom: -15px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .man-image-2 {
		right: 10px;
		width: 95px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-1 {
		top: 10px;
		left: 40px;
		width: 120px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-3 .leaf-image-2 {
		top: 20px;
		right: 20px;
		width: 100px;
	}
	/* home 3 pages - end
	================================================== */
	/* home 4 pages - start
	================================================== */
	.banner-section .mobile-app-banner-4 .banner-content > h2 {
		font-size: 20px;
		line-height: 28px;
	}
	.features-section .feature-item .feature-image-4 .phone-image {
		width: 110px;
	}
	.features-section .feature-item .feature-image-4 .floor-image {
		bottom: 24px;
		width: 200px;
		left: 40px;
	}
	.features-section .feature-item .feature-image-4 .man-image-1 {
		bottom: 40px;
		left: 35px;
		width: 60px;
	}
	.features-section .feature-item .feature-image-4 .flower-image-2 {
		width: 25px;
		bottom: 40px;
		right: 60px;
	}
	.features-section .feature-item .feature-image-4 .comment-1-image {
		top: 40px;
		left: 50px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-4 .comment-2-image {
		top: 105px;
		left: 82px;
		width: 120px;
	}
	.features-section .feature-item .feature-image-4 {
		margin-bottom: 40px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-1 {
		top: 30px;
		left: 110px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-2 {
		top: 70px;
		right: 110px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .box-image-3 {
		top: 120px;
		left: 110px;
		width: 30px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-2 {
		bottom: 0px;
		left: 0px;
		width: 150px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .man-image-1 {
		bottom: 0px;
		right: 75px;
		width: 70px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-4 .flower-image {
		width: 40px;
		right: 50px;
		bottom: 35px;
	}
	/* home 4 pages - end
	================================================== */
	/* home 5 pages - start
	================================================== */
	.banner-section .mobile-app-banner-5 .mobile-banner-image > img {
		max-width: 250px;
	}
	/* home 5 pages - end
	================================================== */
	/* home 6 pages - start
	================================================== */
	.banner-section .mobile-app-banner-6 .mobile-banner-image > img {
		max-width: 100%;
	}
	.banner-section .mobile-app-banner-6 .banner-content h2 {
		font-size: 18px;
	}
	.service-section .service-grid-item {
		padding: 40px 20px;
	}
	.service-section .service-grid-item p {
		font-size: 14px;
	}
	/* home 6 pages - end
	================================================== */
	/* home 7 pages - start
	================================================== */
	.banner-section .sass-banner-1 .banner-content .title-text {
		font-size: 20px;
	}
	.banner-section
		.sass-banner-1
		.banner-content
		.newsletter-form
		.form-item
		.submit-btn {
		height: 40px;
		padding: 0px 20px;
		line-height: 40px;
	}
	.banner-section
		.sass-banner-1
		.banner-content
		.newsletter-form
		.form-item
		input {
		height: 50px;
	}
	.banner-section .sass-banner-1 .banner-content .info-list > ul > li {
		width: 100%;
		margin-right: 0px;
		text-align: center;
		margin-bottom: 5px;
	}
	.banner-section
		.sass-banner-1
		.banner-content
		.info-list
		> ul
		> li:last-child {
		margin-bottom: 0px;
	}
	.features-section .feature-item .feature-image-7 .man-image-1 {
		left: 30px;
		width: 80px;
		bottom: 65px;
	}
	.features-section .feature-item .feature-image-7 .man-image-2 {
		left: -30px;
		bottom: 65px;
		width: 100px;
	}
	.features-section .feature-item .feature-image-7 .man-image-3 {
		bottom: 60px;
		width: 80px;
		right: 0px;
	}
	.features-section .feature-item .feature-image-7 .leaf-image {
		width: 40px;
		bottom: 60px;
		right: -20px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-1 {
		top: -17px;
		left: 95px;
		width: 175px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-2 {
		left: 0px;
		bottom: 30px;
		width: 100px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .man-image-3 {
		right: 0px;
		bottom: 30px;
		width: 200px;
	}
	.features-section .feature-item:nth-child(2) .feature-image-7 .leaf-image {
		right: 0px;
		bottom: 30px;
		width: 90px;
		z-index: 1;
	}
	/* home 7 pages - end
	================================================== */
	/* home 8 pages - start
	================================================== */
	.banner-section .sass-banner-2 .banner-content .title-text {
		font-size: 20px;
		line-height: 30px;
	}
	.banner-section .sass-banner-2 {
		padding-top: 160px;
	}
	.features-section .feature-item:nth-child(1) .feature-image-2 {
		margin-bottom: 25px;
	}
	.features-section
		.feature-item
		.feature-content
		.service-list
		> ul
		> li
		.item-icon {
		float: none;
		display: table;
		margin: 0px auto 30px;
	}
	.features-section
		.feature-item
		.feature-content
		.service-list
		> ul
		> li
		.item-content {
		width: 100%;
		display: table;
		text-align: center;
	}
	/* home 8 pages - end
	================================================== */
	/* home 9 pages - start
	================================================== */
	.banner-section .sass-banner-3 .banner-content .title-text {
		font-size: 20px;
		line-height: 30px;
	}
	/* home 9 pages - end
	================================================== */
	/* home 11 pages - start
	================================================== */
	.slider-section .appstore-main-carousel .item .mobile-banner-image > img {
		max-width: 200px;
	}
	/* home 11 pages - end
	================================================== */
	/* home 12 pages - start
	================================================== */
	.search-section .banner-content h1 {
		font-size: 20px;
	}
	/* home 12 pages - end
	================================================== */
	/* about page - start
	================================================== */
	.about-section .about-content .title-text {
		font-size: 20px;
		line-height: 30px;
	}
	.funfact-section .section-title .title-text {
		font-size: 24px;
		line-height: 36px;
	}
	.testimonial-section
		.testimonial-carousel
		.item.item-style-2
		.hero-image
		.shape-image {
		display: none;
	}
	/* about page - end
	================================================== */
	/* team pages - start
	================================================== */
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(2) {
		left: 115px;
	}
	.team-section
		.team-member-grid
		.member-image
		.member-social-links
		> li:nth-child(3) {
		right: 115px;
	}
	/* team pages - end
	================================================== */
	/* contact pages - start
	================================================== */
	.contact-section .contact-info-item {
		width: 100%;
		margin: 30px auto 0px;
	}
	.contact-section .contact-info-item > ul > li {
		font-size: 16px;
		margin-bottom: 5px;
	}
	/* contact pages - end
	================================================== */
	/* blog pages - start
	================================================== */
	.blog-section .big-blog-item .blog-content .blog-title .title-link,
	.blog-section .blog-grid-item .blog-content .blog-title .title-link {
		font-size: 16px;
		line-height: 26px;
	}
	.blog-section .big-blog-item .blog-content .blog-title {
		margin-bottom: 15px;
	}
	.blog-section .big-blog-item .blog-content {
		padding: 20px;
	}
	/* blog pages - end
	================================================== */
	/* blog details pages - start
	================================================== */
	.blog-details-page-1 .blog-details-section .blog-details-content {
		padding: 0px;
		margin-top: 30px;
	}
	.blog-details-page-1 .breadcrumb-section .overlay-black {
		height: 170px;
	}
	/* blog details pages - end
	================================================== */
	/* 404 error pages - start
	================================================== */
	.common-page .common-container .item-content > p {
		font-size: 14px;
		line-height: 26px;
		margin-bottom: 30px;
	}
	/* 404 error pages - end
	================================================== */
	/* coming soon pages - start
	================================================== */
	.coming-soon-section .common-container .item-content .countdown-timer ul li {
		margin-right: 15px;
		padding-right: 15px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li:before {
		top: 3px;
		right: -4px;
		font-size: 30px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li:last-child {
		margin-right: 0px;
		padding-right: 0px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li
		strong {
		font-size: 30px;
	}
	.coming-soon-section
		.common-container
		.item-content
		.countdown-timer
		ul
		li
		small {
		letter-spacing: 0px;
	}
	.common-page .common-container .item-content .title-text {
		font-size: 30px;
	}
	/* coming soon pages - end
	================================================== */
	/* register pages (register) - start
	================================================== */
	.common-page .common-container .item-content .title-text {
		margin-bottom: 0px;
	}
	.common-page .common-container .item-content > p {
		font-size: 12px;
		line-height: 24px;
		margin-bottom: 15px;
	}
	.register-section
		.common-container
		.item-content
		.register-form
		.tearm-condition
		label {
		font-size: 12px;
	}
	.register-section
		.common-container
		.item-content
		.register-form
		.tearm-condition {
		margin-bottom: 20px;
	}
	/* register pages (register) - end
	================================================== */
}

/* ====================================================================================================
7 - media screen and (max-width: 320px) - end
==================================================================================================== */

.modal {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1050;
	display: none;
	width: 100%;
	height: 100%;
	overflow: hidden;
	outline: 0;
}

.modal-dialog {
	position: relative;
	width: auto;
	margin: 0.5rem;
	pointer-events: none;
}
.modal.fade .modal-dialog {
	-webkit-transition: -webkit-transform 0.3s ease-out;
	transition: -webkit-transform 0.3s ease-out;
	-o-transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out;
	transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
	-webkit-transform: translate(0, -50px);
	-ms-transform: translate(0, -50px);
	transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
	.modal.fade .modal-dialog {
		-webkit-transition: none;
		-o-transition: none;
		transition: none;
	}
}
.modal.show .modal-dialog {
	-webkit-transform: none;
	-ms-transform: none;
	transform: none;
}

.modal-dialog-scrollable {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	max-height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
	max-height: calc(100vh - 1rem);
	overflow: hidden;
}
.modal-dialog-scrollable .modal-header,
.modal-dialog-scrollable .modal-footer {
	-ms-flex-negative: 0;
	flex-shrink: 0;
}
.modal-dialog-scrollable .modal-body {
	overflow-y: auto;
}

.modal-dialog-centered {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	min-height: calc(100% - 1rem);
}
.modal-dialog-centered::before {
	display: block;
	height: calc(100vh - 1rem);
	content: '';
}
.modal-dialog-centered.modal-dialog-scrollable {
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	-webkit-box-pack: center;
	-ms-flex-pack: center;
	justify-content: center;
	height: 100%;
}
.modal-dialog-centered.modal-dialog-scrollable .modal-content {
	max-height: none;
}
.modal-dialog-centered.modal-dialog-scrollable::before {
	content: none;
}

.modal-content {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	width: 100%;
	pointer-events: auto;
	background-color: #fff;
	background-clip: padding-box;
	border: 1px solid rgba(0, 0, 0, 0.2);
	border-radius: 0.3rem;
	outline: 0;
}

.modal-backdrop {
	position: fixed;
	top: 0;
	left: 0;
	z-index: 1040;
	width: 100vw;
	height: 100vh;
	background-color: #000;
}
.modal-backdrop.fade {
	opacity: 0;
}
.modal-backdrop.show {
	opacity: 0.5;
}

.modal-header {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: start;
	-ms-flex-align: start;
	align-items: flex-start;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
	padding: 1rem 1rem;
	border-bottom: 1px solid #dee2e6;
	border-top-left-radius: 0.3rem;
	border-top-right-radius: 0.3rem;
}
.modal-header .close {
	padding: 1rem 1rem;
	margin: -1rem -1rem -1rem auto;
}

.modal-title {
	margin-bottom: 0;
	line-height: 1.5;
}

.modal-body {
	position: relative;
	-webkit-box-flex: 1;
	-ms-flex: 1 1 auto;
	flex: 1 1 auto;
	padding: 1rem;
}

.modal-footer {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: end;
	-ms-flex-pack: end;
	justify-content: flex-end;
	padding: 1rem;
	border-top: 1px solid #dee2e6;
	border-bottom-right-radius: 0.3rem;
	border-bottom-left-radius: 0.3rem;
}
.modal-footer > :not(:first-child) {
	margin-left: 0.25rem;
}
.modal-footer > :not(:last-child) {
	margin-right: 0.25rem;
}

.modal-scrollbar-measure {
	position: absolute;
	top: -9999px;
	width: 50px;
	height: 50px;
	overflow: scroll;
}

@media (min-width: 576px) {
	.modal-dialog {
		max-width: 500px;
		margin: 1.75rem auto;
	}
	.modal-dialog-scrollable {
		max-height: calc(100% - 3.5rem);
	}
	.modal-dialog-scrollable .modal-content {
		max-height: calc(100vh - 3.5rem);
	}
	.modal-dialog-centered {
		min-height: calc(100% - 3.5rem);
	}
	.modal-dialog-centered::before {
		height: calc(100vh - 3.5rem);
	}
	.modal-sm {
		max-width: 300px;
	}
}

@media (min-width: 992px) {
	.modal-lg,
	.modal-xl {
		max-width: 800px;
	}
}

@media (min-width: 1200px) {
	.modal-xl {
		max-width: 1140px;
	}
}

.front-page-background {
	/* background-size: cover; */
	/* height: 700px; */
	/* background-image: url("../images/banner/bg-img.png"); */
}

.footer-page-background {
	background-image: url('../images/footer-bg-1.png');
}

.feature-item {
	margin-top: 40px;
}

.app-image-lower {
	width: 292px;
	/* margin-left: 30px; */
}

@media (max-width: 970px) {
	.app-image {
		width: 318px !important;
		height: 438px !important;
	}

	.app-image-lower {
		width: 233px !important;
		height: 472px !important;
	}
}

.footer-link {
	display: flex;
	justify-content: flex-start;
}

.footer-end {
	display: flex;
	justify-content: flex-end;
}

@media (max-width: 767px) {
	.footer-link {
		display: flex !important;
		justify-content: center !important;
	}
	.footer-end {
		display: flex !important;
		justify-content: center !important;
	}
}
