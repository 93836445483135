$alert-width: 500px;
$alert-extra-padding: 50px;

.alert-main {
	animation: fadeIn ease 0.5s;
	width: $alert-width;
	margin: auto;
	padding-top: 20px;
	position: fixed;
	background-color: white;
	min-height: 250px;
	display: flex;
	align-items: center;
	overflow: hidden;
	flex-direction: column;
	z-index: 9999;
	border-radius: 4px;
	top: 20vh;
	left: calc(50vw - #{250px + 10});
	@media (max-width: 550px) {
		width: 90vw;
		left: 5vw;
	}
}

.alert-header {
	font-size: 26px;
	font-weight: 600;
}

.alert-body {
	text-align: center;
	font-size: 17px;
	padding: 20px;
}

.alert-button {
	width: 90px;
	height: 50px;
	display: flex;
	justify-content: center;
	text-decoration: none;
	color: white;
	border-radius: 40px;
	font-size: 17px;
	font-weight: 600;
	border-radius: 30px;
	align-items: center;
	position: relative;
	margin-top: 30px;
	border: none;
	margin-bottom: 20px;
}

.backdrop {
	animation: fadeIn ease 1s;
	background-color: rgba(0, 0, 0, 0.6);
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 300%;
	z-index: 9998;
	overflow: hidden;
	-ms-overflow-style: none; /* IE and Edge */
	scrollbar-width: none; /* Firefox */
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
